import { Link } from 'react-router-dom'
import logo from '../../media/images/TSCDemoLogo.png'
export default function Logo({ pad }) {
    return (
        <div className={`flex items-center gap-16 ${pad ? 'pl-6 lg:pl-0' : ''} `}>
            <Link to='/' className='flex items-center gap-2'>
                <img src={logo} alt="" className='h-10 w-10 object-contain' />
                <div>
                    <h2 className='font-bold text-orange-500 text-lg'>Ishqool</h2>
                    <p className='text-xs text-gray-600'>Make learning Easy</p>
                </div>
            </Link>
        </div>
    )
}

import prev from '../../../../../media/images/home/prev.svg';
function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={`${className} review__arrow`} style={{ ...style }} onClick={onClick} >
            <div className="left-arrow w-[45px] h-[45px] flex items-center justify-center rounded-full bg-white border border-gray-100 shadow-lg pr-1">
                <img src={prev} alt="left arrow" className=' h-7' />
            </div>
        </div>
    );
}

export default PrevArrow;

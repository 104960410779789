import React from "react";

const PlayIcon = ({
  size = "80",
  color = "currentColor",
  className = "",
  ...attributes
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 137 139"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 5">
        <ellipse
          id="Ellipse 1"
          cx="68.0107"
          cy="69.5"
          rx="68.0107"
          ry="69.5"
          fill="white"
          fillOpacity="0.47"
        />
        <ellipse
          id="Ellipse 2"
          cx="68.5"
          cy="69.5"
          rx="45.5"
          ry="46.5"
          fill="white"
        />
        <path
          id="Vector"
          d="M53.3208 87.1801C52.7545 77.9293 53.0837 68.6688 53.2144 59.4228C53.2482 56.7216 53.0546 53.8994 54.1389 51.2176C55.7655 50.7335 57.2177 51.1401 58.6603 51.6291C69.0245 55.1629 79.3403 58.8129 88.4798 65.0624C89.506 65.7643 90.571 66.4226 91.5537 67.1826C92.8656 68.1895 94.1145 69.2787 95.62 70.518C95.0752 71.1419 94.4722 71.7125 93.8192 72.2219C92.7534 72.8663 91.6492 73.445 90.5129 73.955C86.7516 75.717 83.0242 77.5711 79.1854 79.1492C75.2256 80.7854 71.1835 82.2425 67.1124 83.5882C62.9105 85.0017 58.6312 86.149 54.381 87.4222C54.2454 87.4222 54.1147 87.3544 53.3208 87.1801Z"
          fill="#FE8453"
        />
      </g>
    </svg>
  );
};

export default PlayIcon;

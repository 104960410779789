import axios from "axios";
import {
  DELETE_CHAPTER_URL,
  GET_CHAPTER_INFO_URL,
  ACTIVATE_CHAPTER_URL,
  DEACTIVATE_CHAPTER_URL,
  DELETE_CHAPTER_MATERIALS_URL,
  EXAM_URL,
} from "../utils/urls";
import { IeltsCourseCode } from "../pages/ielts/constants";


export const getChapterInfo = (data) => {
  return axios({
    method: "get",
    url: GET_CHAPTER_INFO_URL + "/" + data.chapterId + "/" + data.courseCode,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
  });
};

export const getChapterExams = ({ courseId, chapterId, token }) => {
  return axios({
    method: "get",
    url: EXAM_URL + "/course-all-exam/" + courseId + "/" + chapterId,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  });
};

export const getIeltsDemoChapterInfo = () => {
  return axios({
    method: "get",
    url: GET_CHAPTER_INFO_URL + "/demo/" + IeltsCourseCode,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteChapter = (data) => {
  return axios({
    method: "delete",
    url: DELETE_CHAPTER_URL + `/${data.chapterId}`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
  });
};

export const activateChapter = (data) => {
  return axios({
    method: "patch",
    url: ACTIVATE_CHAPTER_URL + `/${data.chapterId}`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
  });
};

export const deactivateChapter = (data) => {
  return axios({
    method: "patch",
    url: DEACTIVATE_CHAPTER_URL + `/${data.chapterId}`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
  });
};

export const deleteMaterial = (data) => {
  return axios({
    method: "delete",
    url: DELETE_CHAPTER_MATERIALS_URL + `/${data.materialId}`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
  });
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { courseDetailsById } from '../../api/course.api';

export default function PurchaseProductDetails({ courseId, courseImage }) {

  const [course, setCourse] = useState({});

  useEffect(() => {
    courseDetails();
  }, [])

  const courseDetails = () => {
    courseDetailsById({
      courseId
    })
      .then(res => {
        setCourse(res?.data?.data)
      })
      .catch(err => {
        // console.log(err)
      })
  }

  return (
    <div className='bg-white rounded-2xl shadow shadow-blue-100 p-4 '>
      <h2 className=' sm:text-2xl text-xl font-medium mb-5'>Product Details</h2>
      <div className='flex sm:flex-row flex-col gap-10 items-center justify-center sm:justify-normal'>
        <img src={courseImage} alt="book" className='h-40' />
        <div className=' sm:w-[80%]'>

          <h2 className='xl:text-3xl text-2xl font-semibold mb-1'>{course?.course_title}</h2>
          <h4 className='xl:text-2xl text-xl font-semibold mb-1.5'>৳{course?.course_price}</h4>
          <p>Achieve your desired score! Refine your spoken English in one shot. Register now let's speak success together. Don't let this chance slip away!</p>
        </div>
      </div>
    </div>
  )
}

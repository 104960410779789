import Slider from "react-slick";
import revIcon from '../../../media/images/home/reviewIcon.png'
import MediaPlayer from "../../ielts/components/mediaPlayer/player";
import { videoReview1 } from "../../ielts/constants";
import { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PrevArrow from "../../CommonSection/LandingPage/components/slidecourse/PrevArrow";
import NextArrow from "../../CommonSection/LandingPage/components/slidecourse/NextArrow";
import khalid from '../../../media/images/purchase/purchase1.webp'


export default function Reviews() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const settings = {
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        beforeChange: function (oldSlide, newSlide) {
            setCurrentSlide(newSlide);
        },
        customPaging: function (i) {
            return (
                <div className={` gap-2 flex `}>
                    <div className={` transition-all ease-out duration-300  ${currentSlide === i ? 'w-16 h-1.5 rounded-3xl bg-[#90C7FD]' : 'w-6 h-1.5 rounded-3xl bg-[#E3F1FF]'}`}></div>
                </div>
            );
        },
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "0px",
        slidesToShow: 1,
        speed: 500,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,

    };



    return (
        <div className=" xl:pb-52 lg:pb-[28rem] pb-[42rem]">
            <div className=" flex flex-col items-center justify-center gap-2.5 my-14 text-center">
                <h1 className=" lg:text-5xl text-4xl font-bold text-[#001B36]">Students Review </h1>
                <p className=" text-lg font-medium text-[#001B36] px-5">What Our Students Say about Us and see their Learning Journey and Outcomes</p>
            </div>
            <div className=" container 2xl:px-24 px-5 mx-auto">
                <div className=" h-[26.5rem] bg-[#044884] sm:rounded-3xl sm:p-10 homeReview">
                    <div className=" p-8 text-white">
                        <h1 className=" lg:text-5xl sm:text-4xl text-2xl font-bold">Why We are Best</h1>
                        <p className=" sm:text-lg font-medium sm:mt-5 mt2 md:w-[80%] text-gray-300 ">Get insights and tips from experienced professionals who know the ins and outs of IELTS.Our experts will guide you to speak like a pro, helping you gain confidence and improve your score.</p>
                    </div>

                    <div className="Homereview_slider xl:px-20 sm:px-5">
                        <Slider {...settings}>
                            {/* {reviewData.map(d => */}
                            <div className=" sm:px-16 px-0 pb-5">
                                <div className="bg-white  rounded-3xl shadow-lg flex lg:flex-row flex-col-reverse items-center gap-10 sm:py-5 sm:p-10 p-3">
                                    <div className=" flex-1 ">
                                        <img src={revIcon} alt="revIcon" className=" w-10 xl:w-auto" />
                                        <p className=" text-xl text-[#001B36] mb-6">"Ishgool IELTS Online Coaching has played a vital role in enhancing my language skills. With the guidance of Fathima Azeeza, have significantly improved my reading and writing abilities, gaining valuable insights and guidance. [Highly recommend Ishqool to anyone seeking] comprehensive and effective IELTS training to achieve their desired score.”</p>
                                        <div className="flex gap-4 mb-2">
                                            <img src={khalid} alt="user" className=" w-12 h-12 rounded-full " />
                                            <div>
                                                <h3 className=" text-xl font-medium text-[#001B36]">KHALID</h3>
                                                <p className=" text-[#001B36]">Student</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" lg:w-[40%] w-full mt-8 lg:mt-0 rounded-2xl overflow-hidden">
                                        <MediaPlayer url={videoReview1} />
                                    </div>
                                </div>
                            </div>
                            {/* // )} */}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    )
}

// src/TrackPageView.js
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';
import { pushToDataLayer } from './gtm';

const TrackPageView = () => {
  const location = useLocation();

  console.log(location);

  useEffect(() => {
    let dataLayerArgs = null;

    if (location.pathname === '/') {
      dataLayerArgs = {
        event: 'Home Page View',
        time: new Date(),
        title: 'Home Page',
        path: location.pathname,
        url: window.location.href
      };
    } else if (location.pathname === '/auth/login') {
      dataLayerArgs = {
        event: 'Login Page View',
        time: new Date(),
        title: 'Login Page',
        path: location.pathname,
        url: window.location.href
      };
    } else if (location.pathname === '/auth/registration') {
      dataLayerArgs = {
        event: 'Register Page View',
        time: new Date(),
        title: 'Register Page',
        path: location.pathname,
        url: window.location.href
      };
    } else if (location.pathname === '/ielts-writing-intensive') {
      dataLayerArgs = {
        event: 'Ielts Writing Intensive Branding Page View',
        time: new Date(),
        title: 'Ielts Writing Intensive Page',
        path: location.pathname,
        url: window.location.href
      };
    } else if (location.pathname === '/ielts-writing-intensive/ieltsWritingIntensivePurchase') {
      dataLayerArgs = {
        event: 'Ielts Writing Intensive Purchase Page View',
        time: new Date(),
        title: 'Ielts Writing Intensive Purchase Page',
        path: location.pathname,
        url: window.location.href
      };
    }

    if (dataLayerArgs) {
      pushToDataLayer(dataLayerArgs)
    }
  }, [location]);

  return null;
};

export default TrackPageView;

import React from 'react'
import Reviews from '../CourseBranding/components/Reviews'
import Success from '../CourseBranding/components/Success/Success'
import Value from '../CourseBranding/components/Value'
import CashBack from '../CourseBranding/components/CashBack/CashBack'
import BrandMentors from '../CourseBranding/components/BrandMentors'
import Kit from '../CourseBranding/components/Kit/Kit'
import Footer from '../QuizPages/Components/Footer'
import CrashHero from './components/CrashHero'
import CrashUpcommingCourse from './components/CrashUpcommingCourse'

export default function CrashCourse() {
    return (
        <div className=" bg-white text-[#001B36]">
            <CrashHero />
            <CrashUpcommingCourse />
            <Reviews />
            <Success path={'/speakingCourse/speakingPurchase'} />
            <Value />
            <CashBack text='After the end of the 2 classes just contact us via email or Facebook page about it, and you will get 100% refunded if you are not satisfied.' path={'/speakingCourse/speakingPurchase'} />
            <BrandMentors />
            <Kit path={'/speakingCourse/speakingPurchase'} />
            <Footer />
        </div>
    )
}

import React from 'react'
import { IELTS_FREE_COURSE_ID } from '../../utils/urls'
import PurchaseLeft from '../CommonSection/PurchasePage/components/PurchaseLeft'
import FreeIeltsPurchaseRight from './FreeIeltsPurchaseRight'
import courseImg3 from '../../media/images/course/website course imagex 3 1.jpg'
import PurchaseProductDetails from '../../components/PurchaseProductDetails/PurchaseProductDetails'

export default function FreeIeltsPurchaseBody() {
  return (
    <div className="bg-white py-10">
      <div className="container mx-auto 2xl:px-20 flex flex-col-reverse lg:flex-row gap-10 ">
        <div className="sm:bg-[#F9FAFB] sm:px-5 py-8 lg:w-[60%] rounded-xl">
          <PurchaseLeft courseId={IELTS_FREE_COURSE_ID} courseImage={courseImg3} />
        </div>
        <div className="bg-[#F9FAFB] sm:px-5 py-8 lg:w-[40%] rounded-xl h-fit">
          <FreeIeltsPurchaseRight />
        </div>
        <div className='lg:hidden'>
          <PurchaseProductDetails courseId={IELTS_FREE_COURSE_ID} courseImage={courseImg3} />
        </div>
      </div>
    </div>
  )
}

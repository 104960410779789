import React from 'react'
import PurchaseLeft from '../CommonSection/PurchasePage/components/PurchaseLeft'
import AdvancePurchaseRight from './AdvancePurchaseRight'
import { ADVANCE_COURSE_ID } from '../../utils/urls'
import couseImg3 from '../../media/images/course/website course imagex 3 2.jpg'
import PurchaseProductDetails from '../../components/PurchaseProductDetails/PurchaseProductDetails'

export default function AdvancePurchaseBody() {
    return (
        <div className="bg-white py-10">
            <div className="container mx-auto 2xl:px-20 flex flex-col-reverse lg:flex-row gap-10 ">
                <div className="sm:bg-[#F9FAFB] sm:px-5  py-8 lg:w-[60%] rounded-xl">
                    <PurchaseLeft courseId={ADVANCE_COURSE_ID} courseImage={couseImg3} />
                </div>
                <div className="bg-[#F9FAFB] sm:px-5 py-8 lg:w-[40%] rounded-xl h-fit">
                    <AdvancePurchaseRight />
                </div>
                <div className='lg:hidden'>
                    <PurchaseProductDetails courseId={ADVANCE_COURSE_ID} courseImage={couseImg3} />
                </div>
            </div>
        </div>
    )
}

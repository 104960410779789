import ReactPlayer from "react-player";
import { PlayIcon } from "../../../../components/common/icons";

const MediaPlayer = ({ url, type }) => {
  if (type === "lecture")
    return (
      <div className="rounded-xl h-48 w-64 sm:w-[300px] md:w-[345px] md:h-52 z-[10] relative ">
        <ReactPlayer
          height={200}
          width="100%"
          url={url}
          light
          playIcon={<PlayIcon />}
          controls={true}
        />
      </div>
    );
  return (
    <div className="relative pb-[56.25%] z-[10]">
      <ReactPlayer
        width="100%"
        height="100%"
        className="absolute top-0 left-0"
        url={url}
        light
        playIcon={<PlayIcon />}
        controls={true}
      />
    </div>
  );
};
export default MediaPlayer;

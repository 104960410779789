/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import quizImg1 from '../../../media/images/quiz/new poster 1.jpg'
import { IoCalendarClearOutline } from 'react-icons/io5'
import axios from 'axios'
import { BASE_URL } from '../../../utils/urls'
import { ToastContainer, toast } from 'react-toastify'
import QuizBookModal from './QuizBookModal'
import { useNavigate } from 'react-router'
import { loadStorage } from '../../../utils/persistLocalStorage'
import { hscCourseEnrollmentStatus } from '../../../api/course.api'
import { HSC_Course_Id } from '../../../utils/CourseCodeAndId'

export default function QuizHero() {

  const user = loadStorage('user')
  const navigate = useNavigate();
  const [modal, setModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [thankYou, setThankYou] = useState(false);


  const [enrollStatus, setEnrollStatus] = useState(false);
  const [refetch, setRefetch] = useState(false);

  // Check user registration this course or not
  useEffect(() => {
    userCoursePurchaseList();
  }, [user, refetch])

  const userCoursePurchaseList = () => {
    if (user?.token) {
      hscCourseEnrollmentStatus({
        token: user?.token
      })
        .then((res) => {
          console.log(res?.data?.data)
          setEnrollStatus(res?.data?.data?.status);
        })
        .catch((err) => {
          // console.log(err.response);
        });
    }
  }

  // booking webminer start & function
  const initialData = {
    userData: {
      name: "",
      email: "",
      phone: ""
    },
    fileName: 'hscPreBookingList'
  }
  const [formData, setFormData] = useState(initialData)

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true)

    axios.post(`${BASE_URL}/webminer/hscQuizeRegistration`, formData)
      .then(async (response) => {

        // user enroll hsc course
        const result = await axios.post(`${BASE_URL}/course/hscQuizeCourseEnrollment/${HSC_Course_Id}`, {}, { headers: { "Content-Type": "application/json", "x-auth-token": user?.token }, });
        if (result?.data?.data?.enrollStatus === "approve") {
          setRefetch(!refetch)
        }

        toast.success("Successfully Done", {
          position: "bottom-right",
          autoClose: 3000,
        });
        setFormData(initialData)
        setIsLoading(false)
        setThankYou(true);
      })
      .catch((error) => {
        toast.error("Something Went wrong, please try again.");
      });
  }

  const handleScrollDown = () => {
    window.scrollBy({
      top: 1300,
      behavior: 'smooth',
      tasition: '5s ease '
    });
  };

  const handleRegisterClick = () => {
    if (user) {
      navigate('/studentDash/quizexam');
    } else {
      navigate('/auth/login', { state: { from: window.location.pathname } });
    }
  }

  return (
    <div className='lg:flex py-20 container 2xl:px-20 xl:px-10 sm:px-5 px-2 mx-auto mt-[3.8rem]'>
      <div className=' lg:w-1/2 xl:pt-24 lg:pt-10 mb-10 lg:mb-0 text-center lg:text-left'>
        <h1 className=' sm:text-6xl text-4xl font-semibold text-[#044884] sm:mb-8 mb-4'>Smart Cut <span className='text-[#FE8553]'>HSC'24</span></h1>
        <p className=' sm:text-xl text-lg  lg:mb-[6rem] sm:mb-10 mb-5 font-medium text-gray-800 lg:pr-12'>কোর্সে এনরোল করে আমাদের সাথে পুরো কোর্স শেষ করার পর যদি HSC'24 এক্সামে তোমার উপকার না হয়,তবে ১০০% মানিব্যাক গ্যারান্টি থাকবে।!</p>
        <button onClick={() => handleScrollDown()} className=' py-5 text-white rounded-md text-xl border border-[#044884] hover:bg-transparent hover:text-[#044884] transition-all ease-linear duration-200 px-8 bg-[#044884]' >বিস্তারিত জানুন</button>
      </div>
      <div className=' lg:w-1/2'>
        <div className=' rounded-2xl bg-[#FFF8F5]'>
          <img src={quizImg1} alt="quizImg" className='rounded-t-2xl 2xl:h-80 mx-auto ' />

          {
            enrollStatus ?
              <div className='pt-16 p-6 rounded-b-2xl pb-10'>
                <button onClick={() => navigate('/studentDash/quizexam')} className='w-full py-4 rounded-2xl bg-[#AC101D] border border-[#AC101D] hover:bg-transparent hover:text-[#AC101D] transition-all ease-linear duration-200 text-[26px] font-semibold text-white'>Veiw Dashboard</button>
              </div>
              :
              <div className=' bg-[#FFF8F5] p-6 rounded-b-2xl pb-10'>
                <div className=' sm:flex justify-between w-full items-center sm:mb-12 mb-6'>
                  <div className=' mb-3 sm:mb-0'>
                    <div className='flex items-center gap-2 text-xl font-medium mb-3'>
                      <IoCalendarClearOutline className='-mt-1' />
                      <p>ফ্রি রিভিউ ক্লাসের সময়</p>
                    </div>
                    <h2 className=' text-[26px] font-bold'>২৩ মার্চ, দুপুর ৩ টা </h2>
                  </div>
                  {/* <h3 className=' sm:text-6xl text-4xl font-semibold flex '><FaBangladeshiTakaSign className='text-3xl' /> ২৫০ <span className=' text-[#F8CEBD] ml-3  relative font-bold'>১০০০ <div className='h-[2px] w-full bg-[#F8CEBD] absolute top-[45%] left-0'></div></span> </h3> */}
                </div>
                <button onClick={() => handleRegisterClick()} className=' w-full py-4 rounded-2xl bg-[#AC101D] border border-[#AC101D] hover:bg-transparent hover:text-[#AC101D] transition-all ease-linear duration-200 text-[26px] font-semibold text-white'>রেজিস্ট্রেশন করুন</button>
              </div>
          }

        </div>
      </div>
      <QuizBookModal isLoading={isLoading} formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} modal={modal} setModal={setModal} thankYou={thankYou} />
      <ToastContainer />
    </div>
  )
}

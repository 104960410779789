import { MdKeyboardArrowDown } from "react-icons/md";

export default function SeeMoreBtn() {
    return (
        <div className="flex justify-end pr-20">
            <button className="flex items-center gap-2 py-2 px-5 bg-orange-100 rounded font-medium hover:bg-orange-200">
                আরো দেখুন <MdKeyboardArrowDown className=" text-xl" />
            </button>
        </div>
    )
}

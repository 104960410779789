import React from 'react'
import BrandMentors from "./components/BrandMentors";
import CashBack from "./components/CashBack/CashBack";
import Hero from "./components/Hero/Hero";
import Kit from "./components/Kit/Kit";
import Success from "./components/Success/Success";
import UpcommingCourse from "./components/UpcommingCourse/UpcommingCourse";
import Value from "./components/Value";
import Footer from './components/Footer';

export default function PhysicsSmartHSC24() {
  return (
    <div className=" bg-white text-[#001B36]">
      <Hero />
      <UpcommingCourse />
      <Success path='/hsc24-physics-suggestion-p1/hsc24Physics1stPurchase' />
      <Value />
      <CashBack text='সাজেশন ক্লাস শেষে আপনাদের যদি এক্সামে কোন উপকার না আসে তবে ১০০% রিফান্ড পাবেন আমাদের ফেসবুক পেজে মেসেজ বা ইমেল করলে।' path='/hsc24-physics-suggestion-p1/hsc24Physics1stPurchase' />
      <BrandMentors />
      <Kit path='/hsc24-physics-suggestion-p1/hsc24Physics1stPurchase' />
      <Footer />
    </div>
  )
}

import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";


export default function Breadcrumb({ name, link, icon }) {
    return (
        <div className="flex items-center gap-2 text-[#9BA2A7]">
            <Link to={link} className="hover:text-black">
                {name}
            </Link>
            {
                icon && <MdOutlineKeyboardArrowRight />
            }
        </div>
    );
}

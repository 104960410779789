/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Liveimg from '../../../../../media/images/quiz/HSCLogo.png'
import bookIcon from '../../../../../media/images/quiz/bookicon.png'
import { useNavigate } from 'react-router-dom'
import { LiaEdit } from 'react-icons/lia'
import { FaHourglassEnd } from "react-icons/fa";
import { loadStorage } from '../../../../../utils/persistLocalStorage'
import { getQuizPreviewByUUID } from '../../../../../api/exam.api'

export default function SingleLiveExam({ live }) {

  const user = loadStorage("user");
  const navigate = useNavigate()
  const [examQuestion, setExamQuestion] = useState([]);

  useEffect(() => {
    fetchQuizPreviewByUUID()
  }, [])

  const fetchQuizPreviewByUUID = async () => {

    await getQuizPreviewByUUID({
      uuid: live?.uuid,
      token: user?.token,
    })
      .then((res) => {
        setExamQuestion(res?.data?.data?.exam?.questions);
      })
      .catch((err) => {
        // console.log(err?.response?.data?.error);
      });
  };

  return (
    <div className=' bg-white shadow p-3 rounded-md flex items-center justify-between lg:pr-10 min-w-[900px] '>
      <img className=' lg:w-52 w-40' src={Liveimg} alt="Liveimg" />
      <div className='flex-1 pl-[5%] '>
        <h1 className=' text-2xl font-bold text-[#001B36] mb-2'>{live?.exam?.title}</h1>
        <div className=' flex items-start gap-3'>
          <img src={bookIcon} alt="bookIcon" />
          <h2 className=' text-medium text-lg'>{live?.exam?.description}</h2>
        </div>
      </div>
      {
        examQuestion?.length !== 0 ?
          <button onClick={() => navigate(`/studentDash/exam/${live?.uuid}`)} className="flex items-center justify-center gap-2 bg-[#044884] border border-[#044884] hover:text-[#044884] hover:bg-transparent px-4 py-2 rounded-md text-white transition-all duration-200 ease-linear"><LiaEdit className=" text-xl -mt-1" />Start Exam</button>
          :
          <button className="flex items-center justify-center gap-1.5 bg-yellow-500 border border-yellow-500 hover:text-yellow-500 hover:bg-transparent px-4 py-2 rounded-md text-white transition-all duration-200 ease-linear"><FaHourglassEnd className="text-sm" />Submitted</button>
      }
    </div>
  )
}

import { useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../utils/urls";
import { loadStorage } from "../../../utils/persistLocalStorage";

const CheckCourseAccess = ({ children }) => {

  const { courseId } = useParams();
  const user = loadStorage("user");
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserCourseAccess = async () => {
      if (user?.token) {
        try {
          const response = await axios.get(`${BASE_URL}/userCourse/validate-course-access/${courseId}`, { headers: { "Content-Type": "application/json", "x-auth-token": user?.token }, });
          console.log(response)
          if (response?.data?.data?.access !== true) {
            return navigate("/");
          }
        } catch (error) {
          return navigate("/");
        }
      } else {
        navigate('/');
      }
    };

    checkUserCourseAccess();
  }, [user, courseId, navigate]);

  return children;
};

export default CheckCourseAccess;

/* eslint-disable react-hooks/exhaustive-deps */
import { MdOutlineArrowBackIos } from "react-icons/md";
import Breadcrumb from "../components/Breadcrumb";
import OrderTable from "./OrderTable/OrderTable";
import TableCSVHeader from "./OrderTable/TableCSVHeader";
import FilterHeader from "./FilterHeader";
import NewPagination from "../components/NewPagination";
import { useEffect, useState } from "react";
import { bulkOrdresUpdate, getOrdersList } from "../../../api/order.api";
import { loadStorage } from "../../../utils/persistLocalStorage";

export default function Orders() {

    const user = loadStorage("user");
    const [searchInput, setSearchInput] = useState('');
    const [searchBy, setSearchBy] = useState('');
    const [filterBy, setFilterBy] = useState('');
    const [searchObj, setSearchObj] = useState({});
    const [orderCount, setOrderCount] = useState(0);
    const [refetchOrderList, setRefetechOrderList] = useState(true);

    // page and limit
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [orderData, setOrderData] = useState([]);

    useEffect(() => {
        fetchDashboardStates();
    }, [page, filterBy, searchObj, refetchOrderList])

    const handleSearch = () => {
        if (searchInput && searchBy) {
            setSearchObj({ [searchBy]: searchInput })
        } else {
            setSearchObj({})
        }
    };

    const fetchDashboardStates = async () => {

        await getOrdersList({
            page,
            limit,
            searchBy: searchObj,
            token: user.token,
            status: filterBy
        })
            .then((res) => {
                setOrderData(res?.data?.data?.orders)
                setOrderCount(res?.data?.data?.ordersCount);
            })
            .catch((err) => {
                // console.log(err)
            });
    };

    // bulk update states
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [bulkAction, setBulkAction] = useState('');

    const handleBulkUpdate = async () => {

        const confirmation = window.confirm('Are you sure you want to update selected order status');
        if (!confirmation) return;

        if (bulkAction && selectedRowIds?.length !== 0) {
            await bulkOrdresUpdate({
                token: user.token,
                data: {
                    ids: selectedRowIds,
                    status: bulkAction
                }
            })
                .then((res) => {
                    // console.log(res?.data?.data)
                    setRefetechOrderList(!refetchOrderList);
                    setSelectedRowIds([]);
                    setBulkAction('');
                })
                .catch((err) => {
                    // console.log(err)
                });
        }
    }

    return (
        <div className="min-h-screen p-5">
            <div className="flex items-center gap-5">
                <div className="bg-[#E8EAF5] h-8 w-8 rounded flex items-center justify-center text-black"><MdOutlineArrowBackIos /></div>
                <div className="flex items-center gap-2 text-[#9BA2A7]">
                    <Breadcrumb name="Home" link="/admin" icon={true} />
                    <Breadcrumb name="Orders" link="/admin/orders" />
                </div>
            </div>
            <TableCSVHeader data={{ bulkAction, setBulkAction, handleBulkUpdate }} />
            <FilterHeader data={{ searchInput, setSearchInput, searchBy, setSearchBy, filterBy, setFilterBy, handleSearch, searchObj, setSearchObj }} />
            <OrderTable data={{ orderData, selectedRowIds, setSelectedRowIds }} />
            <div className="sm:flex-row flex-col flex items-center justify-between mt-14 mb-4 gap-3 sm:gap-0">
                <div className="text-lg">
                    <h2>Showing <span className="font-medium">{`${(page * limit) - limit + 1}-${page * limit}`}</span> of <span className="font-medium">{orderCount}</span> Entries</h2>
                </div>
                <div className=" flex-1 flex items-center justify-end">
                    <NewPagination setPage={setPage} totalCount={orderCount} limit={limit} />
                </div>
            </div>
        </div>
    );
}

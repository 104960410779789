import React from 'react'
import PurchaseLeft from '../CommonSection/PurchasePage/components/PurchaseLeft'
import IELTSWritingIntensivePurchaseRight from './IELTSWritingIntensivePurchaseRight'
import { IELTS_WRITING_INTENSIVE_COURSE_ID } from '../../utils/urls'
import couseImg1 from '../../media/images/course/ielts-writing-intensive.jpg'
import PurchaseProductDetails from '../../components/PurchaseProductDetails/PurchaseProductDetails'

export default function IELTSWritingIntensivePurchaseBody() {

    return (
        <div className="bg-white py-10">
            <div className="container mx-auto 2xl:px-20 flex flex-col-reverse lg:flex-row gap-10 ">
                <div className="sm:bg-[#F9FAFB] sm:px-5 py-8 lg:w-[60%] rounded-xl">
                    <PurchaseLeft courseId={IELTS_WRITING_INTENSIVE_COURSE_ID} courseImage={couseImg1} />
                </div>
                <div className="bg-[#F9FAFB] sm:px-5 py-8 lg:w-[40%] rounded-xl h-fit">
                    <IELTSWritingIntensivePurchaseRight />
                </div>
                <div className='lg:hidden'>
                    <PurchaseProductDetails courseId={IELTS_WRITING_INTENSIVE_COURSE_ID} courseImage={couseImg1} />
                </div>
            </div>
        </div>
    )
}

/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { BsShieldFillCheck } from "react-icons/bs";
import { toast } from 'react-toastify';
import { loadStorage } from '../../utils/persistLocalStorage';
import { paymentRequest } from '../../api/payment.api';
import { IELTS_WRITING_INTENSIVE_COURSE_ID } from '../../utils/urls';
import { pushToDataLayer } from '../../utils/gtm';


export default function IELTSWritingIntensivePurchaseRight() {

	const user = loadStorage("user");
	const [formData, setFormData] = useState({
		name: '',
		number: '',
		email: '',
		address: '',
		city: '',
		country: 'Bangladesh',
		userStatus: 'Student',
		orderNote: '',
		termsAndConditions: false,
	});

	useEffect(() => {
		if (user) {
			setFormData({
				name: `${user.first_name || ''} ${user.last_name || ''}`,
				number: user.phone || '',
				email: user.email || '',
				address: user.address || '',
				city: user.city || '',
				country: user.country || 'Bangladesh',
				userStatus: 'Student',
				orderNote: '',
				termsAndConditions: false,
			});
		}
	}, []);

	const handleChange = (e) => {
		const { id, value } = e.target;

		// If the changed field is 'countries', update the 'country' accordingly
		const updatedCountry = id === 'countries' ? value : formData.country;

		setFormData((prevData) => ({
			...prevData,
			[id]: value,
			country: updatedCountry,
		}));
	};

	const handleCheckboxChange = () => {
		setFormData((prevData) => ({
			...prevData,
			defaultCheckbox: !prevData.defaultCheckbox,
			country: !prevData.defaultCheckbox ? 'Bangladesh' : prevData.countries,
		}));
	};


	// payment procede
	const handleSubmit = async (e) => {
		e.preventDefault();

		// Check if required fields are empty
		if (formData.name === '' || formData.email === '' || formData.number === '' || formData.address === '') {
			toast.error('Please fill in all required fields.', {
				position: 'bottom-right', // Set toast position to bottom-right
			});
			return;
		}

		pushToDataLayer({
			event: 'Proceed to Checkout',
			course_name: 'IELTS Intensive Writing',
			course_price: '999 tk',
			course_description: 'Enhance your IELTS writing in a single go. Book today, Dont let it pass you by.',
			action: 'Click',
			time: new Date()
		})

		await paymentRequest({ data: { ...formData, course_id: IELTS_WRITING_INTENSIVE_COURSE_ID } })
			.then(res => {
				const paymentUrl = res?.data?.data?.paymentUrl;
				if (paymentUrl) {
					window.open(paymentUrl, '_blank');
				}
			})
			.catch(err => {
				// console.log(err)
			})
	};

	const isCheckoutDisabled = !formData.termsAndConditions;

	return (
		<div className="bg-white p-5 rounded-lg text-[#001B36]">
			<h1 className="sm:text-2xl text-xl font-medium mb-4">Customer Details</h1>
			<form onSubmit={handleSubmit}>
				{/* ... Other input fields */}
				<div className="flex flex-col mb-3">
					<label htmlFor="name" className="font-medium mb-1">
						Name
					</label>
					<input
						type="text"
						id="name"
						value={formData.name}
						onChange={handleChange}
						className="p-3 rounded-md focus:outline-none border focus:border-gray-400"
						placeholder="Your Name"
						disabled={user?.first_name}
						required
					/>
				</div>

				<div className=" flex flex-col mb-3">
					<label htmlFor="number" className=" font-medium mb-1">Mobile Number</label>
					<input
						type="number"
						id="number"
						value={formData.number}
						onChange={handleChange}
						className=" p-3 rounded-md focus:outline-none border focus:border-gray-400"
						placeholder="Your Mobile Number"
						required
						disabled={user?.phone}
					/>
				</div>
				<div className=" flex flex-col mb-3">
					<label htmlFor="email" className=" font-medium mb-1">Email</label>
					<input
						type="email"
						id="email"
						value={formData.email}
						onChange={handleChange}
						className=" p-3 rounded-md focus:outline-none border focus:border-gray-400"
						placeholder="Your email Address"
						required
						disabled={user?.email}
					/>
				</div>
				<div className=" flex flex-col mb-3">
					<label htmlFor="address" className=" font-medium mb-1">Address</label>
					<input
						type="text"
						id="address"
						value={formData.address}
						onChange={handleChange}
						className=" p-3 rounded-md focus:outline-none border focus:border-gray-400"
						placeholder="Write Your Address here"
						disabled={user?.address}
						required
					/>
				</div>
				<div className="xl:flex gap-10">
					<div className="flex flex-col mb-3">
						<label htmlFor="city" className="font-medium mb-1">
							City
						</label>
						<input
							type="text"
							id="city"
							value={formData.city}
							onChange={handleChange}
							className="p-3 rounded-md focus:outline-none border focus:border-gray-400"
							placeholder="Your City"
							required
							disabled={user?.city}
						/>
					</div>
					<div className="flex xl:flex-col flex-row mb-3 justify-between xl:justify-normal items-end xl:items-start">
						<div>
							<label htmlFor="country" className="font-medium mb-1 ml-2">
								Country
							</label>
							<div className="flex items-center px-2 gap-4">
								<label htmlFor="default-checkbox" className="font-medium text-gray-900 ">
									Bangladesh
								</label>
								<input
									id="default-checkbox"
									type="checkbox"
									checked={formData.country === 'Bangladesh'}
									onChange={handleCheckboxChange}
									className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded cursor-pointer"
									disabled={user?.country}
								/>
							</div>
						</div>
						<div>
							<select
								id="countries"
								value={formData.country}
								onChange={handleChange}
								className="bg-gray-50 px-1.5 text-gray-900 font-medium rounded-lg focus:outline-none"
							>
								<option >orther country</option>
								<option value="US">United States</option>
								<option value="CA">Canada</option>
								<option value="FR">France</option>
								<option value="DE">Germany</option>
							</select>
						</div>
					</div>
				</div>
				<div>
					<label htmlFor="userStatus" className="font-medium mb-1">
						Are you Currently A?
					</label>
					<div className="flex items-center gap-5 mt-2">
						<div className="flex items-center">
							<label htmlFor="student" className="me-2 font-medium text-gray-900">
								Student
							</label>
							<input
								id="userStatus"
								type="radio"
								value="Student"
								name="userStatus"
								checked={formData.userStatus === 'Student'}
								onChange={handleChange}
								className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
							/>
						</div>
						<p>or</p>
						<div className="flex items-center">
							<label htmlFor="jobHolder" className="me-2 font-medium text-gray-900">
								Job Holder
							</label>
							<input
								id="userStatus"
								type="radio"
								value="Job Holder"
								name="userStatus"
								checked={formData.userStatus === 'Job Holder'}
								onChange={handleChange}
								className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
							/>
						</div>
					</div>
				</div>
				<div className="flex flex-col mb-3 mt-4">
					<label htmlFor="orderNote" className="font-medium mb-1">
						Order Note
					</label>
					<textarea
						id="orderNote"
						value={formData.orderNote}
						onChange={handleChange}
						className="p-3 rounded-md focus:outline-none border focus:border-gray-400"
						placeholder="Write Your notes here"
					/>
				</div>
				<div className="mt-8">
					<h1 className="text-2xl font-medium mb-4">Payment Details</h1>
				</div>
				<div className="my-6">
					<div className="flex items-center gap-4">
						<input
							id="termsAndConditions"
							type="checkbox"
							checked={formData.termsAndConditions}
							onChange={handleChange}
							className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded cursor-pointer"
						/>
						<label htmlFor="termsAndConditions" className="font-medium text-gray-900">
							I agree with terms and Conditions
						</label>
					</div>
					<div className="flex items-center gap-4 mb-2.5 font-medium mt-5">
						<BsShieldFillCheck className="text-green-600" />
						<h2 className="font-medium">100 % Secured Payment</h2>
					</div>
				</div>
				<button
					type="submit"
					disabled={isCheckoutDisabled}
					className={`py-2.5 w-full ${isCheckoutDisabled
						? 'bg-gray-300 cursor-not-allowed'
						: 'bg-[#044884] hover:bg-transparent hover:text-[#044884] border border-[#044884]'
						} font-medium text-white rounded-md`}
				>
					Proceed to Checkout
				</button>
			</form>
		</div>
	);
}


/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FaRegPenToSquare } from 'react-icons/fa6';
import { HiOutlineMail } from 'react-icons/hi';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { MdOutlineQuiz } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import Logo from './Logo';
import Button from '../dev/button/Button';
import { FiLogIn } from 'react-icons/fi';
import { ADVANCE_COURSE_ID, HSC_PHYSICS_1st_SMART_SUGGESTION_2024, IELTS_FREE_COURSE_ID, IELTS_WRITING_INTENSIVE_COURSE_ID, SPEARING_COURSE_ID, SPEARING_WORKSHOP_COURSE_ID } from '../../utils/urls';
import couseImg from '../../media/images/course/smart cut logo.jpg'
import couseImg1 from '../../media/images/course/website course imagex 3 1.jpg'
import couseImg2 from '../../media/images/course/website course imagex 3 2.jpg'
import couseImg3 from '../../media/images/course/website course imagex 3 3.jpeg'
import couseImg4 from '../../media/images/courseBranding/funnel image 1.jpg'
import { getUserCoursePurchaseList } from '../../api/order.api';
import { loadStorage } from '../../utils/persistLocalStorage';
import { CiEdit } from "react-icons/ci";

export default function Menu({ menuOpen, setMenuOpen }) {

	const courses = [
		{
			name: "IELTS Speaking Workshop",
			dec: ' Enhance your IELTS speaking in a single go. Book today, Dont let it pass you by.',
			img: "https://bftraining.ae/wp-content/uploads/2021/01/ielts.jpg",
			video: 5,
			lecture: 10,
			duration: "3 months",
			path: '/courseBranding',
			courseImg: couseImg1,
			courseId: SPEARING_WORKSHOP_COURSE_ID
		},
		{
			name: "IELTS Speaking Course",
			dec: 'Achieve your desired score! Refine your IELTS Speaking Module in one shot. Register Now.',
			img: "https://media.proprofs.com/images/QM/user_images/2503852/New%20Project%20(87)(104).jpg",
			video: 5,
			lecture: 10,
			duration: "3 months",
			path: '/speakingCourse',
			courseImg: couseImg3,
			courseId: SPEARING_COURSE_ID
		},
		{
			name: "IELTS Advance Course",
			dec: ' Secure your target score! Elevate your IELTS Journey in just one session. Enroll Now.',
			img: "https://www.admitedge.com/sites/default/files/styles/blog_360x195/public/gre_test_centres.png",
			video: 22,
			lecture: 12,
			duration: "3 months",
			path: '/advanceCourse',
			courseImg: couseImg2,
			courseId: ADVANCE_COURSE_ID
		},
		{
			name: "IELTS Free Course 2024",
			dec: ' Secure your target score! Elevate your IELTS Journey in just one session. Enroll Now.',
			img: "https://media.proprofs.com/images/QM/user_images/2503852/New%20Project%20(87)(104).jpg",
			video: 22,
			lecture: 12,
			duration: "3 months",
			path: '/',
			courseImg: couseImg2,
			courseId: IELTS_FREE_COURSE_ID
		},
		{
			name: "Smart Cut HSC'24 Preparation",
			dec: 'দেশের একমাত্র পূর্ণাঙ্গ অনলাইন মডেল টেস্ট ও সলভ প্রোগ্রাম। ঘরে বসেই HSC পরীক্ষার A+ নিশ্চিতে এই একটি কোর্সেই যথেষ্ট!',
			img: "https://talenthutbd.com/wp-content/uploads/2022/10/talenthut-blog-3.png",
			video: 5,
			lecture: 10,
			duration: "3 months",
			path: '/hscmodeltest',
			courseImg: couseImg,
			courseId: 5000
		},
		{
			name: "HSC Physics 1st SMART Suggestions Class for 2024",
			dec: 'দেশের একমাত্র পূর্ণাঙ্গ অনলাইন সলভ প্রোগ্রাম।',
			img: "https://talenthutbd.com/wp-content/uploads/2022/10/talenthut-blog-3.png",
			video: 5,
			lecture: 10,
			duration: "3 months",
			path: '/hsc24-physics-suggestion-p1',
			courseImg: couseImg4,
			courseId: HSC_PHYSICS_1st_SMART_SUGGESTION_2024
		},
		{
			name: "IELTS Writing Course",
			dec: 'Enhance your IELTS writing in a single go. Book today, Dont let it pass you by.',
			img: "https://talenthutbd.com/wp-content/uploads/2022/10/talenthut-blog-3.png",
			video: 5,
			lecture: 10,
			duration: "3 months",
			path: '/courseBranding',
			courseImg: couseImg4,
			courseId: IELTS_WRITING_INTENSIVE_COURSE_ID
		}
	]

	var user = loadStorage("user");
	const location = useLocation();
	const [userPurchaseCourse, setUserPurchaseCourse] = useState([]);

	useEffect(() => {
		userCoursePurchaseList();
	}, [])

	const userCoursePurchaseList = () => {
		getUserCoursePurchaseList({
			userId: user?.id
		})
			.then((res) => {
				const purchaseCourse = [];
				res?.data?.data?.forEach(singlePurchaseCourse => {
					const course = courses?.find(c => c.courseId === singlePurchaseCourse.course_id)
					purchaseCourse.push({ ...course, whatsAppLink: singlePurchaseCourse?.course?.whats_app_link });
				});
				setUserPurchaseCourse(purchaseCourse);
			})
			.catch((err) => {
				// console.log(err.response);
			});
	}

	const menus = [
		// {
		//     id: 1,
		//     name: 'Courses',
		//     icon: <MdOutlineLibraryBooks />,
		//     path: '/courses',
		// },
		{
			id: 1,
			name: (userPurchaseCourse.length > 0 ? 'My Course' : 'IELTS'),
			icon: <FaRegPenToSquare />,
			path: '/ielts',
		},
		{
			id: 2,
			name:  'IELTS Writing',
			icon: <CiEdit  />,
			path: '/ielts-writing-intensive',
		},
		{
			id: 5,
			name: 'HSC Model Test',
			icon: <MdOutlineQuiz />,
			path: '/hscmodeltest',
		},
		{
			id: 3,
			name: 'About',
			icon: <IoDocumentTextOutline />,
			path: '/about',
		},
		{
			id: 5,
			name: 'Contact',
			icon: <HiOutlineMail />,
			path: '/contact',
		}
	];

	return (
		<>
			{/* For website */}
			<div className='gap-6 text-gray-700 font-medium hidden lg:flex'>
				{menus?.map((menu) => (
					<Link
						key={menu.id}
						to={menu?.path}
						className={`lg:flex items-center gap-2 hover:text-orange-600 transition duration-300 ${location.pathname === menu.path ? 'text-orange-600' : ''
							} hidden`}
					>
						{menu?.icon}
						{menu?.name}
					</Link>
				))}
			</div>

			{/* For mobile */}
			<div
				onClick={() => setMenuOpen(false)}
				className={`fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-40 z-[14] transition-all ease-linear duration-200 lg:hidden ${menuOpen ? 'visible' : ' invisible'
					}`}
			></div>
			<div
				className={`h-screen fixed top-0 z-[15] xs:w-1/2 4/5 bg-white transition-all ease-linear duration-200 lg:hidden p-7 flex flex-col justify-between ${menuOpen ? 'left-0 visible' : '-left-[38rem] invisible'
					}`}
			>
				<div className='flex justify-center mb-7'>
					<Logo />
				</div>
				<div className='gap-y-7 flex-1 pt-12 flex flex-col text-gray-700 font-medium'>
					{menus?.map((menu) => (
						<Link
							key={menu.id}
							to={menu?.path}
							className={`flex items-center gap-2 hover:text-orange-600 transition duration-300 ${location.pathname === menu.path ? 'text-orange-600' : ''
								}`}
						>
							{menu?.icon}
							{menu?.name}
						</Link>
					))}
				</div>
				<div className='relative'>
					<Button type='button' text='Login' isFill={true} icon={<FiLogIn />} />
				</div>
			</div>
		</>
	);
}

import { FaCircle } from "react-icons/fa6";
import { PiBookOpenTextFill } from "react-icons/pi";
import Button from "../../components/dev/button/Button";
import courseVector from '../../media/images/home/user.jpg'
import courseImg from '../../media/images/home/course2.png'
import { useNavigate } from "react-router-dom";

export default function CourseCard({ ca }) {
    const navigate = useNavigate()
    return (
        <div className='  w-full bg-gray-300 rounded-lg p-[1px] hover:shadow-md'>
            <img src={courseImg} alt="courseImg" className='rounded-t-lg' />
            <div className=' px-4 py-5 bg-white rounded-b-lg'>
                <div className=' font-medium  items-center gap-2 py-1 px-4 rounded text-orange-700 bg-orange-100 inline-flex text-sm'><FaCircle className=' text-[5px]' />Biology Part 1</div>
                <p className='mt-4 font-medium sm:text-[22px] text-xl leading-[28px]'>The Title of biolody is here Lorem ipsum</p>
                <div className=' mt-3 flex items-center  sm:gap-3 gap-6 leading-4'>
                    <img src={courseVector} alt="courseVector" className=' h-9 w-9 rounded-full border border-orange-500' />
                    <p>By <span className='font-medium'>Md. Sahataj Rahman</span></p>
                </div>
                <div className=' mt-4 flex items-center gap-10 justify-center'>
                    <Button onClick={() => navigate(`/course/${ca}`)} type='button' text='See Details' isFill={true} icon={<PiBookOpenTextFill />} />
                </div>
            </div>
        </div>
    )
}

import React from 'react'

export default function ProgressBar({ percentage }) {
  const progressStyle = {
    width: `${percentage}%`,
    height: '14px',
    backgroundColor: '#fe8553',
    transition: 'width 0.3s ease-in-out',
    borderRadius: '5px',
  };

  return (
    <div style={{ width: '100%', backgroundColor: '#ccc', marginTop: '25px', borderRadius: '5px' }}>
      <div style={progressStyle}></div>
    </div>
  );
}

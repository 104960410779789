import next from '../../../../../media/images/home/next.svg';

function NextArrow(props) {
    const { className, style, onClick } = props;

    return (
        <div
            className={`${className} review__arrow`}
            style={{ ...style }}
            onClick={onClick}
        >
            <div className="right-arrow w-[45px] h-[45px] flex items-center justify-center rounded-full bg-white border border-gray-100 shadow-lg ">
                <img src={next} alt="right arrow" className=' h-7' />
            </div>
        </div>
    );
}

export default NextArrow;

import React from 'react'
import QuizTitle from '../../QuizPages/Components/QuizTitle';
import mentorImg from '../../../media/images/quiz/teacher1.png'
import mentorImg2 from '../../../media/images/course/teacher png FA.png'
import { AiOutlineTrophy } from "react-icons/ai";
import { PiSuitcaseSimple } from "react-icons/pi";
import { MdLibraryAddCheck } from 'react-icons/md';

export default function BrandMentors() {

	const mentorsData = [
		// {
		// 	id: 1,
		// 	imgSrc: mentorImg,
		// 	name: 'Aditya Farhan Hossain',
		// 	expertise: 'Instructor at IshQool',
		// 	experience: 'IELTS Score- 7.5',
		// },
		{
			id: 2,
			imgSrc: mentorImg2,
			name: 'FATHIMA AZEEZA',
			expertise: 'IELTS INSTRUCTOR',
			experience: 'Writing Band Score : 8.5',
		}
	];

	return (
		<div className=' py-5 bg-[#FFFBF8] '>
			<QuizTitle title='Our Expert Mentor' text='Meet our incredible IELTS mentor with an impressive band score 8.5' isLineNone={true} />
			<div className=' xl:w-[45%] lg:w-[60%] sm:w-[90%] mx-auto my-5 cotainer px-5'>
				<div className=' flex items-start gap-2 text-[#001B36]'>
					<MdLibraryAddCheck className=' text-[#FE8553] w-6 mt-0.5' />
					<p>IELTS expert and trainer Has a high success rate of helping students achieve 8+ bands in the IELTS exam.</p>
				</div>
				<div className=' flex items-start gap-2 mt-1 text-[#001B36]'  >
					<MdLibraryAddCheck className=' text-[#FE8553] w-6 mt-0.5' />
					<p>She has a thorough understanding of the IELTS modules and the expectations of the examiners.</p>
				</div>
			</div>
			<div className="container 2xl:px-20 xl:px-10 sm:px-5 px-2 mx-auto py-6 flex flex-wrap justify-center gap-10">
				{
					mentorsData?.map(mentor => <div key={mentor?.id} className=" bg-[#fefefe] rounded-xl py-6 px-4 mb-7 sm:mb-0">
						<div className=" h-48 w-52 ">
							<img className="mx-auto" src={mentor?.imgSrc} alt="mentorImg" />
						</div>
						<h1 className="text-2xl font-semibold mt-5 text-center sm:text-left">{mentor?.name}</h1>
						{/* <p className=" text-lg font-bold text-center sm:text-left">{mentor?.batch}</p> */}
						<div className=" my-5">
							<div className=" flex gap-2 text-[#001B36] font-medium">
								<PiSuitcaseSimple className=" mt-1" />
								<p>{mentor?.expertise}</p>
							</div>
							<div className=" flex gap-2 text-[#001B36] font-medium">
								<AiOutlineTrophy className=" mt-1" />
								<p>{mentor?.experience}</p>
							</div>
						</div>
						{/* <button className=" w-full bg-[#001B36] text-md font-medium text-white py-1.5 rounded-md border border-[#001B36] hover:bg-transparent hover:text-[#001B36]">বিস্তারিত জানুন</button> */}
					</div>)
				}
			</div>
		</div>
	)
}

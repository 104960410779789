export const IeltsCoureContentPerPage = 5;
export const IeltsCoureTitleMaxLength = 40;
export const IeltsCoureCollapsibleTransitionTime = 200;
export const IeltsHeroBanner =
  "https://i.ibb.co/kV7QVt0/24070816-bwink-edu-02-single-02.jpg";
export const IeltsCarousalAutoPlaySpeed = 5000;
let IeltsCourseCode;
let IeltsCourseId;
if (process.env.NODE_ENV === "development") {
  IeltsCourseCode = "DFH3r"; //DFH3r //uzrmm
  IeltsCourseId = "455";
} else if (process.env.NODE_ENV === "production") {
  IeltsCourseCode = "uzrmm"; //DFH3r //uzrmm
  IeltsCourseId = "463";
}
export { IeltsCourseCode, IeltsCourseId };
export const IeltsPromoVideoURL = "https://www.youtube.com/watch?v=zLYVI6cdq-E";
export const IeltsHowToVideoURL = "https://www.youtube.com/watch?v=GregIOIg5uA";
export const IeltsHeroVideo = "https://www.youtube.com/watch?v=L9N4dLGVg_Q";

export const videoReview1 = "https://www.youtube.com/watch?v=adTo6H2kcFs";
export const videoReview2 = "https://www.youtube.com/watch?v=AU0OPdoHGIE";
export const videoReview3 = "https://www.youtube.com/watch?v=8-rdvam6U18";

export const IeltsBannerImages = [
  "https://i.ibb.co/sjxH4yw/website-carosoul-1.jpg",
  "https://i.ibb.co/nwVzcbS/website-carosoul-2.jpg",
  "https://i.ibb.co/8PKCcR3/website-carosoul-3.jpg",
];

import logo from '../../../media/images/quiz/HSCLogo.png'
import socialF from '../../../media/images/quiz/facebbok.png'
import socialI from '../../../media/images/quiz/instagram.png'
import socialT from '../../../media/images/quiz/twiter.png'
import socialY from '../../../media/images/quiz/youtube.png'
import { Link, useLocation } from 'react-router-dom'
import { PiPhoneThin } from 'react-icons/pi'
import { CiMail } from 'react-icons/ci'
import Logo from '../../../components/Navbar/Logo'

export default function Footer() {

  const location = useLocation();
  const pathname = location.pathname;

  return (
    <div className=' bg-[#FFF5EC] pt-20 pb-24'>
      <div className=" container 2xl:px-20 xl:px-10 sm:px-5 px-2 mx-auto flex flex-wrap gap-10 justify-between">
        <div className=' lg:w-[28%] sm:w-[26%] w-full'>
          <div>
            {pathname === '/hscmodeltest' ? <img src={logo} alt="logo" className='lg:w-[70%] w-[90%]' /> : <Logo />}
          </div>
          <p className=' font-medium mt-4 text-[001B36]'>দেশের সেরা স্কিল ডেভেলপমেন্ট প্লাটফর্ম</p>
          <div className=' mt-6'>
            <h2 className=' font-semibold mb-3 text-[001B36]'>সোশ্যাল কম্যুনিটি তে কানেক্টেড থাকতে</h2>
            <div className=' flex items-center gap-5'>
              <a href="https://www.facebook.com/ishqool.org" target="_blank" rel="noopener noreferrer">
                <img src={socialF} alt="socialF" />
              </a>
              <a href="https://chat.whatsapp.com/GJyQEpoHGvuF8Z9XBxtlkv?fbclid=IwAR3rMRwlUFz7h06QQw9Hi2XWPrYrYHvZEUFIMchHtr84pAtTVqbX1vQO6pE" target="_blank" rel="noopener noreferrer">
                <img src={socialI} alt="socialI" />
              </a>
              <img src={socialT} alt="socialT" />
              <img src={socialY} alt="socialY" />
            </div>
          </div>
        </div>
        <div className=''>
          <div>
            <h2 className=' text-2xl font-semibold text-[001B36] mb-2.5'>কুইক লিংক</h2>
            <div className=' h-[4px] w-14 bg-[#7FAD5B] rounded-3xl mb-6'></div>
          </div>

          <div className=' flex flex-col gap-5 text-[#001B36] font-medium'>
            <Link to=''>ফ্রি কোর্স সমূহ</Link>
            <Link to=''>আপকামিং লাইভ ব্যাচ</Link>
            <Link to=''>লাইভ ওয়ার্কশপ</Link>
            <Link to=''></Link>
          </div>
        </div>
        <div>
          <div>
            <h2 className=' text-2xl font-semibold text-[001B36] mb-2.5'>কোম্পানি তথ্যাদি</h2>
            <div className=' h-[4px] w-14 bg-[#7FAD5B] rounded-3xl mb-6'></div>
          </div>

          <div className=' flex flex-col gap-5 text-[#001B36] font-medium'>
            <Link to=''>আমাদের সম্পর্কে</Link>
            <Link to=''>রিফান্ড পলিসি</Link>
            <Link to=''>প্রাইভেসি পলিসি</Link>
            <Link to=''>শর্তাবলী</Link>
          </div>
        </div>
        <div>
          <div>
            <h2 className=' text-2xl font-semibold text-[001B36] mb-2.5'>যোগাযোগ</h2>
            <div className=' h-[4px] w-14 bg-[#7FAD5B] rounded-3xl mb-6'></div>
          </div>

          <div>
            <div className=' flex items-center gap-4 mb-3'>
              <div className=' h-9 w-9 rounded-md bg-[#FCD1BF] text-[001B36] flex items-center justify-center text-2xl'>
                <PiPhoneThin />
              </div>
              <p className=' text-xl font-medium text-[#001B36]'>01521520463</p>
            </div>
            <div className=' flex items-center gap-4'>
              <div className=' h-9 w-9 rounded-md bg-[#FCD1BF] text-[001B36] flex items-center justify-center text-2xl'>

                <CiMail />
              </div>
              <p className=' text-xl font-medium text-[#001B36]'>joinishqool@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

/* eslint-disable no-sequences */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react'

export default function OrderHeader({ data }) {

    const [timePeriod, setTimePeriod] = useState(1);
    const [isDateValid, setIsDateValid] = useState(true);

    const { setDateRange, startDate, setStartDate, endDate, setEndDate } = data;

    const timePeriods = [
        { id: 1, label: "TODAY" },
        { id: 2, label: "WEEKLY" },
        { id: 3, label: "MONTHLY" },
        { id: 4, label: "CUSTOM" },
    ];

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
        if (endDate) {
            const newStartDateObj = new Date(event.target.value);
            const existingEndDateObj = new Date(endDate);
            setIsDateValid(newStartDateObj < existingEndDateObj);
        } else {
            setIsDateValid(true);
        }
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
        if (startDate) {
            const existingStartDateObj = new Date(startDate);
            const newEndDateObj = new Date(event.target.value);
            setIsDateValid(newEndDateObj > existingStartDateObj);
        } else {
            setIsDateValid(true);
        }
    };

    return (
        <div className="flex items-center md:justify-end justify-start py-5">

            <div className="md:flex xl:gap-7 gap-5 pr-3">
                <form className="flex items-center adminDate gap-4 relative mb-5 lg:mb-0">
                    <label htmlFor="date" className="font-medium">
                        Date
                    </label>
                    <div className="flex items-center gap-4">
                        <input
                            class="focus:outline-none cursor-pointer invalid-date"
                            type="date"
                            name="date"
                            placeholder="From"
                            value={startDate}
                            onChange={handleStartDateChange}
                            required
                        />
                        <p className="font-medium">to</p>
                        <input
                            class="focus:outline-none cursor-pointer invalid-date"
                            type="date"
                            name="date"
                            placeholder="To"
                            value={endDate}
                            onChange={handleEndDateChange}
                            required
                        />
                    </div>
                    <div className=" absolute top-10 -left-2">
                        {!isDateValid && (
                            <span className="text-red-500 text-sm ml-2">
                                Invalid dates: Start date must be earlier than end date.
                            </span>
                        )}
                    </div>
                </form>

                <div className="flex  mb-5 lg:mb-0">
                    {timePeriods.map((period) => (
                        <div
                            key={period.id}
                            onClick={() => { setTimePeriod(period.id), setDateRange(period.label.toLocaleLowerCase()) }}
                            className={`text-sm font-medium py-2 px-4 h-fit cursor-pointer ${timePeriod === period.id ? "bg-black text-white" : "bg-[#F0F3F8] text-[#001B36]"}`}
                        >
                            <p className="mt-0.5">{period.label}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

import { MdOutlineArrowBackIos } from "react-icons/md";
import Breadcrumb from "../components/Breadcrumb";
import OrderCard from "../components/OrderCard";
import OrderHeader from "../components/OrderHeader";
import Orders from "../Orders/Orders";
import { useState } from "react";


export default function Dashboard() {

    const [dateRange, setDateRange] = useState("today");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    return (
        <div className="min-h-screen p-5">
            <div className="flex items-center gap-5">
                <div className="bg-[#E8EAF5] h-8 w-8 rounded flex items-center justify-center text-black"><MdOutlineArrowBackIos /></div>
                <div className="flex items-center gap-2 text-[#9BA2A7]">
                    <Breadcrumb name="Home" link="/admin" icon={true} />
                    <Breadcrumb name="Dashboard" link="/admin" />
                </div>
            </div>
            <OrderHeader data={{ setDateRange, startDate, setStartDate, endDate, setEndDate }} />
            <OrderCard data={{ dateRange, startDate, endDate }} />
            <Orders />
        </div>
    )
}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
/* eslint-disable no-unused-expressions */
import { useState } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { downloadOrdersCSV } from "../../../../api/order.api";
import { loadStorage } from "../../../../utils/persistLocalStorage";
import { saveAs } from 'file-saver';

export default function TableCSVHeader({ data }) {

	const user = loadStorage("user");
	const [timePeriod, setTimePeriod] = useState(1);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [isDateValid, setIsDateValid] = useState(true);
	const [customDate, setCustomDate] = useState(false);

	const { bulkAction, setBulkAction, handleBulkUpdate } = data;

	// api states
	const [dateRange, setDateRange] = useState("today");

	const timePeriods = [
		{ id: 1, label: "TODAY" },
		{ id: 2, label: "WEEKLY" },
		{ id: 3, label: "MONTHLY" },
		{ id: 4, label: "CUSTOM" },
	];

	const handleStartDateChange = (event) => {
		setStartDate(event.target.value);
		if (endDate) {
			const newStartDateObj = new Date(event.target.value);
			const existingEndDateObj = new Date(endDate);
			setIsDateValid(newStartDateObj < existingEndDateObj);
			setCustomDate(false);
		} else {
			setIsDateValid(true);
		}
	};

	const handleEndDateChange = (event) => {
		setEndDate(event.target.value);
		if (startDate) {
			const existingStartDateObj = new Date(startDate);
			const newEndDateObj = new Date(event.target.value);
			setIsDateValid(newEndDateObj >= existingStartDateObj);
			setCustomDate(false);
		} else {
			setIsDateValid(true);
		}
	};

	const handleDownloadOrdersCSV = async () => {

		if (dateRange === 'custom' && (!startDate || !endDate)) {
			return setCustomDate(true);
		}

		const confirmation = window.confirm('Are you sure you want to download orders CSV file');
		if (!confirmation) return;

		await downloadOrdersCSV({
			dateRange,
			token: user.token,
			startDate: new Date(startDate),
			endDate: new Date(endDate)
		})
			.then((response) => {

				// Extract the filename from the content disposition header
				const contentDisposition = response.headers['content-disposition'];
				const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+?)"/);
				const filename = filenameMatch ? filenameMatch[1] : 'orders_data.csv';

				// Save the blob as a file using FileSaver
				saveAs(response.data, filename);

				console.log('File downloaded successfully');
			})
			.catch((error) => {
				// console.log('Error downloading file:', error);
			});
	};

	return (
		<div className="xl:flex items-center justify-between py-5 mt-5">
			<div className="flex items-center gap-5 xl:mb-0 mb-5">
				<select
					id="countries"
					className="border border-gray-300 text-gray-900 py-1.5 px-4 rounded font-medium focus:outline-none"
					value={bulkAction}
					onChange={(e) => setBulkAction(e.target.value)}
				>
					<option value="">Bulk Action</option>
					<option value="completed">Completed</option>
					<option value="pending">Pending</option>
					<option value="processing">Processing</option>
					<option value="cancelled">Cancelled</option>
				</select>
				<button onClick={handleBulkUpdate} className="py-2 px-4 font-medium  bg-[#F0F3F8] text-[#001B36] border border-[#F0F3F8] hover:bg-transparent rounded" >Apply</button>
			</div>
			<div className="lg:flex xl:gap-7 gap-5 pr-3">
				<form className="flex items-center adminDate gap-4 relative mb-5 lg:mb-0">
					<label htmlFor="date" className="font-medium">
						Date
					</label>
					<div className="flex items-center gap-4">
						<input
							class="focus:outline-none cursor-pointer invalid-date"
							type="date"
							name="date"
							placeholder="From"
							value={startDate}
							onChange={handleStartDateChange}
							required
						/>
						<p className="font-medium">to</p>
						<input
							class="focus:outline-none cursor-pointer invalid-date"
							type="date"
							name="date"
							placeholder="To"
							value={endDate}
							onChange={handleEndDateChange}
							required
						/>
					</div>
					<div className=" absolute top-10 -left-2">
						{!isDateValid && (
							<span className="text-red-500 text-sm ml-2">
								Invalid dates: Start date must be earlier than end date.
							</span>
						)}
						{customDate && (
							<span className="text-red-500 text-sm ml-2">
								Invalid dates: Start date and end date must be provide.
							</span>
						)}
					</div>
				</form>

				<div className="flex  mb-5 lg:mb-0">
					{timePeriods.map((period) => (
						<div
							key={period.id}
							onClick={() => { setTimePeriod(period.id), setDateRange(period.label.toLowerCase()) }}
							className={`text-sm font-medium py-2 px-4 h-fit cursor-pointer ${timePeriod === period.id ? "bg-black text-white" : "bg-[#F0F3F8] text-[#001B36]"}`}
						>
							<p className="mt-0.5">{period.label}</p>
						</div>
					))}
				</div>
				<button onClick={() => handleDownloadOrdersCSV()} className="bg-black border border-black hover:bg-transparent hover:text-black flex items-center gap-2 py-2 px-3 text-white font-medium text-sm "><MdOutlineFileDownload className="text-lg" /> DOWNLOAD CSV</button>
			</div>
		</div>
	)
}

import valueimg1 from '../../../media/images/hsc24PhysicsSmartPreparation/ভালো রেসাল্ট .jpg'
import valueimg2 from '../../../media/images/hsc24PhysicsSmartPreparation/এক্সাম রেডি.jpg'
import valueimg3 from '../../../media/images/hsc24PhysicsSmartPreparation/এক্সপার্ট দিক নির্দেশনা.jpg'

export default function Value() {

	const valueData = [
		{
			id: 1,
			name: 'ভাল রেসাল্ট',
			dec: 'পদার্থবিজ্ঞান ১ম পত্রে বোর্ডে সর্বোচ্চ নম্বর নিশ্চিত করতে পারা।',
			img: valueimg1
		},
		{
			id: 2,
			name: 'এক্সাম রেডি',
			dec: 'পরীক্ষার ৭ দিন আগে সাজেশন পেয়ে টপিক কভার করার থেকে হাতে সময় নিয়ে সঠিক নির্দেশনায় একদম বেসিক থেকে A+ পাওয়া।',
			img: valueimg2
		},
		{
			id: 3,
			name: 'এক্সপার্ট দিকনির্দেশনা ',
			dec: 'অভিজ্ঞ ইন্সট্রাক্টরদের দীর্ঘদিনের অভিজ্ঞতা ও কিভাবে এইচ এস সিতে ভাল করা যায় তার দিকনির্দেশনা।',
			img: valueimg3
		},
	]

	return (
		<div className=' py-10 container 2xl:px-20 px-5 mx-auto xl:mt-14 lg:mt-24 sm:mt-14 mt-10'>
			<h1 className=' sm:text-5xl text-4xl font-bold text-center mb-14'>এই কোর্স আপনার এইস এস সি জার্নিতে যেভাবে সাহায্য করবে </h1>
			<div className=' grid lg:grid-cols-3 sm:grid-cols-2 gap-10'>
				{valueData?.map(data => <div className=' border rounded-2xl p-6 flex flex-col items-center py-8'>
					<img className='w-40 h-40 object-contain' src={data?.img} alt="value" />
					<h1 className=' sm:text-2xl text-xl font-semibold mb-4 '>{data?.name}</h1>
					<p>{data?.dec}</p>
				</div>)}
			</div>
		</div>
	)
}

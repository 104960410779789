import "./Kit.css"
import { TiArrowRight } from "react-icons/ti";
import bookimg from '../../../../media/images/courseBranding/Box product.png'
import { useNavigate } from "react-router-dom";

export default function Kit({ path }) {
    const navigate = useNavigate()
    return (
        <div className=" container mx-auto 2xl:px-40 px-5 py-20">
            <div className=" text-center">
                <h2 className=" sm:text-4xl text-2xl font-bold text-[#001B36] mb-3">তাহলে দেরি কেন?</h2>
                <p className=" text-[#FE8553] sm:text-3xl font-medium">A+ আর তোমার মধ্যে দুরুত্ব মাত্র ৩ ঘণ্টা এখনি জয়েন কর আমাদের স্মার্ট সাজেশন ক্লাসে।</p>
            </div>

            <div className=" flex sm:flex-row flex-col-reverse items-center pt-10">
                <div className=" sm:w-1/2">
                    <h1 className=" lg:text-6xl md:text-5xl sm:text-4xl text-2xl font-bold 2xl:w-3/4 text-center sm:text-left sm:mb-10 mb-3">পদার্থবিজ্ঞান ১ম পত্র SMART Suggestions Class for Hsc'24</h1>
                    <p className=" text-xl font-medium sm:mb-12 mb-7 text-center sm:text-left">কোর্সটির মূল্য শুধুমাত্র ৳199 </p>
                    <div className="wiggle !flex !justify-center  sm:!justify-normal">
                        <button onClick={() => navigate(path)} className='flex items-center gap-2 px-5 py-1.5 rounded-md bg-[#FE8553] border border-[#FE8553] hover:text-[#FE8553] hover:bg-transparent transition-all duration-200 ease-linear font-medium text-white '>Enroll Now <TiArrowRight className='text-xl' /></button>
                    </div>
                </div>
                <div className=" w-[2px] bg-[#D9D9D9] h-80 sm:block hidden"></div>
                <div className=" sm:w-1/2 sm:flex sm:justify-end justify-center mb-5 sm:mb-0 ">
                    <img src={bookimg} alt="bookimg" className=" sm:h-[30rem] h-60 " />
                </div>
            </div>
        </div>
    )
}

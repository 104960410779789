/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
import { FiSearch } from 'react-icons/fi';

export default function FilterHeader({ data }) {

	const { searchInput, setSearchInput, searchBy, setSearchBy, filterBy, setFilterBy, handleSearch, setSearchObj } = data;

	const handleFilterBy = (e) => {
		setFilterBy(e.target.value)
		if (searchInput && searchBy) {
			setSearchObj({ [searchBy]: searchInput })
		} else {
			setSearchObj({})
		}
	}

	return (
		<div className="md:flex items-center justify-between mb-5 mt-3 pr-3">
			<div className="sm:flex items-center gap-3 md:mb-0 mb-5">
				<div className="relative sm:mb-0 mb-5">
					<input
						type="text"
						value={searchInput}
						onChange={(e) => setSearchInput(e.target.value)}
						placeholder="Search order"
						className="w-72 bg-[#F9FAFB] focus:outline-none pl-8 py-3 pr-2 placeholder:text-sm text-sm rounded"
					/>
					<FiSearch className="absolute top-3 left-2 text-gray-600" />
				</div>
				<select
					id="searchBy"
					value={searchBy}
					onChange={(e) => setSearchBy(e.target.value)}
					className="border border-gray-300 text-gray-900 py-1.5 px-4 rounded font-medium focus:outline-none"
				>
					<option value="">Search By</option>
					<option value="order_id">Order ID</option>
					<option value="user_id">Customer ID</option>
					<option value="email">Customer Email</option>
					<option value="course_name">Product</option>
				</select>

				<button
					onClick={handleSearch}
					className="py-2 px-4 font-medium bg-[#F0F3F8] text-[#001B36] border border-[#F0F3F8] hover:bg-transparent rounded xl:block hidden"
				>
					Search
				</button>
			</div>

			<select
				id="filterBy"
				value={filterBy}
				onChange={(e) => handleFilterBy(e)}
				className="border border-gray-300 text-gray-900 py-1.5 px-4 rounded font-medium focus:outline-none"
			>
				<option value="" >Filter By</option>
				<option value="completed">Completed</option>
				<option value="pending">Pending</option>
				<option value="cancelled">Cancelled</option>
				<option value="refunded">Refunded</option>
			</select>
		</div>
	);
}

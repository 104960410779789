
import routingImg from '../../../media/images/quiz/quitingIMg.png'
import logo from '../../../media/images/quiz/HSCLogo.png'
import routing from '../../../media/images/quiz/Routine new.jpg'
import routingdownload from '../../../media/images/quiz/Routine new.jpg'
import QuizTitle from './QuizTitle'
import QuizButton from './QuizButton'
export default function QuizRoutine() {
    const handleDownloadRoutine = async () => {
        const imageUrl = routingdownload;
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = 'routine_image.jpg';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } catch (error) {
            console.error('Error downloading the image:', error);
        }
    };

    return (
        <div className=' pb-5'>
            <QuizTitle title='MCQ এক্সাম কোর্স রুটিন' text='সুবিধা ও পছন্দ অনুযায়ী যেকোনো ব্যাচে এক্সাম দেয়ার সুযোগ' />
            <div className=" bg-[#FAFBFF]  relative mb-12">
                <div className="container 2xl:px-20 xl:px-10 sm:px-5 px-2 mx-auto py-16 md:flex lg:gap-10 gap-5">
                    <div className=' xl:w-1/5 md:w-[26%] xl:pr-5 sm:mb-10 mb-5 md:mb-0 z-[10] relative'>
                        <div className=' mb-5 bg-white py-3 rounded-lg flex items-center justify-center'>
                            <img src={logo} className='lg:w-[11rem] w-[9rem]' alt="logo" />
                        </div>
                        <div className=' text-center bg-white rounded-lg py-4 '>
                            <h1 className=' lg:text-[38px] text-[33px] text-[#FE8553] font-bold lg:mb-5'>HSC’24 </h1>
                            <p className='text-[#044884] lg:text-[38px] text-[33px] font-bold leading-[3rem] '>MCQ</p>
                            <p className='text-[#044884] lg:text-[38px] text-[33px] font-bold '>এক্সাম কোর্স</p>
                        </div>
                    </div>
                    <div className=' md:w-[50%]'><img src={routing} alt="routing" /></div>
                    <div className=' w-1/5 hidden xl:block'></div>
                </div>
                <img src={routingImg} alt="routingImg" className='absolute  md:h-full h-80 xl:right-0 md:-right-32 sm:-right-20 -right-32 top-0' />
            </div>
            <QuizButton text='রুটিন ডাউনলোড করুন' onClick={handleDownloadRoutine} />
        </div>
    )
}

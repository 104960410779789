/* eslint-disable react-hooks/exhaustive-deps */
import Navbar from "../../../../components/Navbar/Navbar";
import NewPagination from "../../../Admin/components/NewPagination";
import { useEffect, useState } from "react";
import { getUserOrderList } from "../../../../api/order.api";
import { loadStorage } from "../../../../utils/persistLocalStorage";
import { useNavigate } from "react-router-dom";


export default function OrderHistory() {

	const user = loadStorage('user');
	const navigate = useNavigate();
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [userOrdersCount, setUserOrdersCount] = useState(0);
	const [userOrders, setUserOrders] = useState([])


	useEffect(() => {
		userOrderHistory();
	}, [page])

	const userOrderHistory = async () => {
		await getUserOrderList({
			token: user?.token,
			userId: user?.id,
			page,
			limit,
		})
			.then(res => {
				setUserOrders(res?.data?.data?.data)
				setUserOrdersCount(res?.data?.data?.userTotalOrders);
			})
			.catch(err => {
				// console.log(err)
			})
	}

	return (
		<div>
			<Navbar />

			<div className="container 2xl:px-20 px-5 mx-auto pt-16">
				<h1 className="text-xl font-medium my-5 ">Order History</h1>
				<div class=" overflow-x-auto shadow-md rounded-lg no-scrollbar mt-5">
					<table class="w-full min-w-[500px] text-left rtl:text-right">
						<thead class="text-lg   bg-white">
							<tr className="flex  w-full gap-2 justify-between">
								<th scope="col" class="px-6 py-3 font-medium  w-16">
									#
								</th>
								<th scope="col" class="px-6 py-3 font-medium  w-72 ">
									Course Name
								</th>
								<th scope="col" class="px-6 py-3 font-medium  w-40">
									Order Status
								</th>
								<th scope="col" class="px-6 py-3 font-medium w-32">
									Amount
								</th>
								<th scope="col" class="px-6 py-3 font-medium w-32">
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							{userOrders?.map(({ id, status, course, payment }, i) =>
								<tr key={id} class="bg-white border-b  hover:bg-gray-50 flex gap-2 justify-between">

									<td class="px-6 py-3 font-medium text-gray-900  w-16">
										{(i + 1) < 10 ? `0${i + 1}` : i + 1}
									</td>
									<td class="px-6 flex py-3 w-72">
										{course?.course_title}
									</td>
									<td class="px-6 py-3 w-40">
										<button
											className={`w-[6.7rem] ${status === 'Failed'
												? 'bg-[#EC1C24] border-[#EC1C24] hover:text-[#EC1C24]'
												: status === 'Pay'
													? 'bg-[#FFB800] border-[#FFB800] hover:text-[#FFB800]'
													: status === 'Paid'
														? 'bg-[#25C195] border-[#25C195] hover:text-[#25C195]'
														: 'bg-[#56C2FF] border-[#56C2FF] hover:text-[#56C2FF]'
												} text-white py-1.5 rounded-md border hover:bg-transparent`}
										>
											{status}
										</button>
									</td>

									<td class="px-6 py-3 font-medium w-32">
										{payment?.amount}
									</td>
									<td class="px-6 py-3 w-40">
										{status === 'completed' &&
											<button
												onClick={() => navigate(`/thankyou/${payment?.transaction_id}`)}
												className="w-[7.75rem] bg-[#25C195] border-[#25C195] hover:text-[#25C195] text-white py-1.5 rounded-md border hover:bg-transparent">
												Course Access
											</button>}
										{status === 'pending' &&
											<button
												onClick={() => navigate(`/purchase`)}
												className="w-[6rem] bg-[#FFB800] border-[#FFB800] hover:text-[#FFB800] text-white py-1.5 rounded-md border hover:bg-transparent">
												Pay
											</button>}
										{status === 'processing' &&
											<button
												className="w-[6rem] bg-[#56C2FF] border-[#56C2FF] hover:text-[#56C2FF] text-white py-1.5 rounded-md border hover:bg-transparent">
												Wait
											</button>}
									</td>
								</tr>)}
						</tbody>
					</table>
				</div>
				<div className="sm:flex-row flex-col flex items-center justify-between mt-14 mb-4 gap-3 sm:gap-0">
					<div className="text-lg">
						<h2>Showing <span className="font-medium">{(page * limit) - limit + 1}-{page * limit}</span> of <span className="font-medium">{userOrdersCount}</span> Entries</h2>
					</div>
					<div className=" flex-1 flex items-center justify-end">
						<NewPagination setPage={setPage} totalCount={userOrdersCount} limit={limit} />
					</div>
				</div>
			</div>

		</div>
	)
}

import React from 'react'
import Reviews from '../CourseBranding/components/Reviews'
import Success from '../CourseBranding/components/Success/Success'
import Value from '../CourseBranding/components/Value'
import CashBack from '../CourseBranding/components/CashBack/CashBack'
import BrandMentors from '../CourseBranding/components/BrandMentors'
import Kit from '../CourseBranding/components/Kit/Kit'
import Footer from '../QuizPages/Components/Footer'
import AdvanceHero from './components/AdvanceHero'
import AdvanceUpcommingCourse from './components/AdvanceUpcommingCourse'

export default function AdvanceCourse() {
    return (
        <div className=" bg-white text-[#001B36]">
            <AdvanceHero />
            <AdvanceUpcommingCourse />
            <Reviews />
            <Success path='/advanceCourse/advancePurchase' />
            <Value />
            <CashBack text='If you complete our advance course and receive band score of less than 6.00 in the IELTS main Exam, you will get 100% refund.' path='/advanceCourse/advancePurchase' />
            <BrandMentors />
            <Kit path='/advanceCourse/advancePurchase' />
            <Footer />
        </div>
    )
}

// import "./Kit.css"
import { TiArrowRight } from "react-icons/ti";
import bookimg from '../../../../media/images/courseBranding/book.png'
import { useNavigate } from "react-router-dom";
import { pushToDataLayer } from "../../../../utils/gtm";

export default function Kit({ path }) {

	const navigate = useNavigate();

	const handleNavigate = (path) => {
		pushToDataLayer({
			event: 'Book Your Seat',
			course_name: 'IELTS Intensive Writing',
			course_price: '999 tk',
			course_description: 'Enhance your IELTS writing in a single go. Book today, Dont let it pass you by.',
			action: 'Click',
			time: new Date()
		})
		navigate(path);
	}

	return (
		<div className="container mx-auto 2xl:px-40 px-5 py-20">
			<div className="text-center">
				<h2 className=" sm:text-4xl text-2xl font-bold text-[#001B36] mb-3">So, What are you waiting for?</h2>
				<p className=" text-[#FE8553] sm:text-3xl font-medium">Grab Your Seat Now!</p>
			</div>

			<div className="flex justify-center items-center pt-24 pb-12">
				<div>
					<h1 className="lg:text-6xl md:text-5xl sm:text-4xl text-2xl font-bold text-center sm:text-left sm:mb-6 mb-2">IELTS Intensive Writing Course</h1>
					<p className="text-xl font-medium sm:mb-7 mb-4 text-center">Introductory Price ৳999 Only</p>
					<div className="wiggle !flex !justify-center">
						<button onClick={() => handleNavigate(path)} className='flex items-center gap-2 px-5 py-1.5 rounded-md bg-[#FE8553] border border-[#FE8553] hover:text-[#FE8553] hover:bg-transparent transition-all duration-200 ease-linear font-medium text-white '>Book Your Seat<TiArrowRight className='text-xl' /></button>
					</div>
				</div>
			</div>
		</div>
	)
}

import valueimg1 from '../../../media/images/courseBranding/value1.png'
import valueimg2 from '../../../media/images/courseBranding/value2.png'
import valueimg3 from '../../../media/images/courseBranding/value3.png'

export default function Value() {

    const valueData = [
        {
            id: 1,
            name: 'Take One Leap ahead in your IELTS Writing Band Score',
            dec: 'Through  this course you will be able to increase your present Writing Band Score at least one band score higher!.',
            img: valueimg1
        },
        {
            id: 2,
            name: 'Master the techniques of IELTS Writing',
            dec: 'Instructors will provide you with advanced training in order to know the fundamentals of IELTS Writing.',
            img: valueimg2
        },
        {
            id: 3,
            name: '​​Expert Guidance',
            dec: 'Get insights and tips from experienced professionals who know the ins and outs of IELTS.',
            img: valueimg3
        }
    ]
    
    return (
        <div className=' py-10 container 2xl:px-20 px-5 mx-auto xl:mt-14 lg:mt-24 sm:mt-14 mt-10'>
            <h1 className=' sm:text-5xl text-4xl font-bold text-center mb-14'>The Value of Joining This Course </h1>

            <div className=' grid lg:grid-cols-3 sm:grid-cols-2 gap-10'>
                {
                    valueData?.map(data => <div className=' border rounded-2xl p-6 flex flex-col items-center py-8'>
                        <img src={data?.img} alt="value" />
                        <h1 className='sm:text-2xl text-xl font-semibold mb-4 text-center'>{data?.name}</h1>
                        <p>{data?.dec}</p>
                    </div>)
                }
            </div>
        </div>
    )
}

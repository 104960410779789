

export default function TitleBar({ title }) {
    return (
        <div className=" bg-[#F9FAFB] " >
            <div className=" container mx-auto 2xl:px-20 px-5 py-7 " >
                <h1 className=" md:text-3xl text-2xl font-semibold text-[#001B36] ">{title}</h1>
            </div>
        </div>
    )
}

import React from 'react'
import './notificationCard.scss'

function NotificationCard() {
  return (
	<div className='notification_card_container'>
		<h1>
			aaaa ssss dddd
		</h1>
	</div>
  )
}

export default NotificationCard
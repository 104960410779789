import { FiTrash2 } from "react-icons/fi";
import { FaPencil } from "react-icons/fa6";

export default function OrderTable({ data }) {

	const { selectedRowIds, setSelectedRowIds, orderData } = data;

	const handleCheckboxChange = (orderId) => {
		setSelectedRowIds((prevSelectedRows) => {
			if (prevSelectedRows.includes(orderId)) {
				return prevSelectedRows.filter((id) => id !== orderId);
			} else {
				return [...prevSelectedRows, orderId];
			}
		});
	};

	return (
		<div class=" overflow-x-auto shadow-md rounded-lg no-scrollbar mt-5">
			<table class="w-full min-w-[500px] text-left rtl:text-right">
				<thead class="text-lg   bg-white">
					<tr className="flex  w-full gap-2 justify-between">
						<th scope="col" class="px-6 py-3 font-medium w-16">
							#
						</th>
						<th scope="col" class="px-6 py-3 font-medium  w-16">
							Sl
						</th>
						<th scope="col" class="px-2 py-3 font-medium w-28">
							Order Id
						</th>
						<th scope="col" class="pr-2 py-3 font-medium  w-[7.5rem] ">
							Customer Id
						</th>
						<th scope="col" class="pr-2 py-3 font-medium  w-[7.5rem] ">
							Transaction Id
						</th>
						<th scope="col" class="px-6 py-3 font-medium  w-72 ">
							Product Name
						</th>
						<th scope="col" class="px-6 py-3 font-medium w-60">
							Customer Email
						</th>
						<th scope="col" class="px-6 py-3 font-medium  w-40">
							Status
						</th>
						<th scope="col" class="px-6 py-3 font-medium w-32">
							Total
						</th>
						<th scope="col" class="px-6 py-3 font-medium w-32">
							Action
						</th>
					</tr>
				</thead>
				<tbody>
					{orderData?.map(({ id, status, user, course, payment }, i) =>
						<tr key={id} class="bg-white border-b  hover:bg-gray-50 flex gap-2 justify-between">
							<td class="px-6 py-3 font-medium text-gray-900 whitespace-nowrap  w-16">
								<input
									id={`checkbox-${id}`}
									type="checkbox"
									className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:outline-none cursor-pointer"
									onChange={() => handleCheckboxChange(id)}
									checked={selectedRowIds.includes(id)}
								/>
								<label htmlFor={`checkbox-${id}`} className="sr-only">
									checkbox
								</label>
							</td>
							<td class="px-6 py-3 font-medium text-gray-900  w-16">
								{(i + 1) < 10 ? `0${i + 1}` : i + 1}
							</td>
							<td className="w-28 px-2">
								#{id}
							</td>
							<td className=" pr-2 flex w-[7.5rem]" >
								{user?.id}
							</td>
							<td className=" pr-2 flex w-[7.5rem]" >
								{payment?.transaction_id ? payment?.transaction_id : "N/A"}
							</td>
							<td class="px-6 flex py-3 w-72">
								{course?.course_title}
							</td>
							<td class="px-6 py-3 w-60">
								{user?.email}
							</td>
							<td class="px-6 py-3 w-40">
								<button
									className={`w-[6.7rem] ${status === 'pending' ? 'bg-[#FFB800] border-[#FFB800] hover:text-[#FFB800]' : status === 'completed' ? 'bg-[#25C195] border-[#25C195] hover:text-[#25C195]' : status === 'cancelled' ? 'bg-[#EC1C24] border-[#EC1C24] hover:text-[#EC1C24]' : 'bg-[#56C2FF] border-[#56C2FF] hover:text-[#56C2FF]'} text-white py-1.5 rounded-md border hover:bg-transparent`}
								>
									{status}
								</button>
							</td>
							<td class="px-6 py-3 font-medium w-32">
								{payment?.amount ? payment?.amount : "N/A"}
							</td>

							<td className="w-32">
								<div className="flex gap-2 justify-center text-[#FF6F6F]" >
									<FaPencil className="cursor-pointer" />
									<FiTrash2 className="cursor-pointer" />
								</div>
							</td>
						</tr>)}
				</tbody>
			</table>
		</div>
	)
}

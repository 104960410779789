import { IoCallSharp } from "react-icons/io5";
import TitleBar from "../components/TitleBar";
import PurchaseHeader from "../components/PurchaseHeader";
import Navbar from "../../../../components/Navbar/Navbar";
import declineImg from '../../../../media/images/purchase/dcline.png'
import Footer from "../../../QuizPages/Components/Footer";

export default function DeclinePage() {
    return (
        <div className=" bg-white">
            <Navbar />
            <div className=" py-14 text-[#001B36]">
                <PurchaseHeader />
                <TitleBar title='অর্ডার স্ট্যাটাস' />

                <div className=" flex flex-col items-center py-14 text-center">
                    <img src={declineImg} alt="thankyou" className=" lg:h-80 px-5" />
                    <p className=" text-lg font-medium text-[#001B36] mt-10 mb-2">Sorry ! Your Order is not Completed.</p>
                    <p>To Complete Your Purchase Please Reorder or Payment</p>
                </div>

                <div className=' flex md:flex-row flex-col items-center justify-center px-4 pb-6 gap-5 md:gap-0'>
                    <div className=' flex items-center gap-3'>
                        <div className=' sm:w-12 w-10 sm:h-12 h-10 rounded-full flex items-center justify-center bg-[#FFDBEE] text-[#F082BC] text-2xl'>
                            <IoCallSharp />
                        </div>
                        <h3 className=' sm:text-2xl text-xl font-medium text-[#001B36]'>যেকোনো প্রয়োজনে কল করুন: </h3>
                    </div>
                    <div className='sm:flex items-center gap-4'>
                        <h1 className=' text-4xl font-bold text-[#FE8553]'>01313615686</h1>
                        <p className=" font-medium"> (সকাল ৯ টা - রাত ১০ টা)</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

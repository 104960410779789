/* eslint-disable react-hooks/exhaustive-deps */
import pay2 from '../../../../media/images/purchase/payicon2.png'

import cashBack from '../../../../media/images/purchase/cashback.png'
import quata from '../../../../media/images/purchase//quote.png'

import { IoCallSharp } from 'react-icons/io5'

import revImg1 from '../../../../media/images/purchase/purchase1.webp'
import revImg2 from '../../../../media/images/purchase/purchase2.webp'
import PurchaseProductDetails from '../../../../components/PurchaseProductDetails/PurchaseProductDetails'

export default function PurchaseLeft({ courseId, courseImage }) {

	const reviewData = [
		{
			id: 1,
			name: 'KHALID',
			dec: 'Highly recommend Ishqool to anyone seeking. comprehensive and effective IELTS training to achieve their desired score.',
			picture: revImg1
		},
		{
			id: 2,
			name: 'SADIA',
			dec: 'highly endorse Ishqool for individuals in search of thorough and impactful IELTS training to attain their target scores.',
			picture: revImg2
		},
	]

	return (
		<>
			<div className='hidden lg:block'>
				<PurchaseProductDetails courseId={courseId} courseImage={courseImage} />
			</div>

			<div className=' bg-white rounded-2xl shadow shadow-blue-100 p-5 mt-10'>
				<h2 className='sm:text-2xl text-xl font-medium mb-5'>Payment Option</h2>
				<div className=' border rounded flex items-center justify-center sm:gap-8 gap-5 py-2'>
					<img src={pay2} alt="bkash" className='sm:h-28 h-14' />
				</div>
			</div>

			<div className='bg-black rounded-md p-5 flex sm:flex-row flex-col items-center xl:gap-5 gap-3 mt-10'>
				<img src={cashBack} alt="cashBack" className=' xl:w-60 w-52' />
				<div className=' text-white'>
					<h1 className='text-2xl font-semibold mb-2'>100% Money Back Guarantee!</h1>
					<p className=' text-gray-200'>After the end of the workshop just contact us via
						email or facebook page about it, you'll get 100%
						refunded if you are not satisfied.</p>
				</div>
			</div>

			<div className='sm:flex items-center sm:justify-between justify-center px-4 py-6 mt-16'>
				<div className=' flex items-center gap-3'>
					<div className=' xl:w-12 w-10 xl:h-12 h-10 rounded-full flex items-center justify-center bg-[#FFDBEE] text-[#F082BC] text-2xl'>
						<IoCallSharp />
					</div>
					<h3 className=' xl:text-2xl text-xl font-medium text-[#001B36]'>If You need talk with us </h3>
				</div>

				<div className='mt-4 sm:mt-0 text-center sm:text-left'>
					<h1 className=' text-4xl font-bold text-[#FE8553]'>01521520463</h1>
					<p> (9:00 Am - 10:00 PM)</p>
				</div>
			</div>
		</>
	)
}

import React from 'react'
import { IoTimerOutline } from "react-icons/io5";
import { BsCalendar2Plus } from "react-icons/bs";
import { RiTimerFlashLine } from "react-icons/ri";
import { PiExam } from "react-icons/pi";
import CourseInfoCard from './CourseInfoCard';

export default function CourseInfo() {
    
    const CourseInfoData = [
        {
            id: 1,
            title: 'Course Duration',
            info: '1 month',
            icon: <IoTimerOutline />
        },
        {
            id: 4,
            title: 'Weekly',
            info: '3 Days',
            icon: <PiExam />
        },
        {
            id: 2,
            title: 'Schedule',
            info: 'Sunday, Tuesday, Thursday',
            icon: <BsCalendar2Plus />
        },
        {
            id: 3,
            title: 'Time',
            info: '9:00 PM - 10:00 PM',
            icon: <RiTimerFlashLine />
        },
    ]
    return (
        <div className='max-w-6xl mt-20 mb-12 pb-10 rounded-3xl mx-auto p-10 px-10'>
             <h1 className=' sm:text-5xl text-4xl font-bold text-center mb-14'>Course Information</h1>
            <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-col-1 gap-7'>
                {
                    CourseInfoData?.map(course => <CourseInfoCard key={course.id} course={course} />)
                }
            </div>
        </div>
    )
}

import axios from "axios";
import {
	ACTIVATE_EXAM_URL,
	ADD_NEW_OPTION_URL,
	CREATE_EXAM_WITH_CHAPTER_AND_COURSE_URL,
	CREATE_QUESTION_URL,
	DEACTIVATE_EXAM_URL,
	DELETE_QUESTION_URL,
	GET_ALL_EXAMS_BY_USER_ID_URL,
	GET_QUIZ_ANSWERS_URL,
	GET_QUIZ_PREVIEW_URL,
	GET_QUIZ_REPORT_URL,
	GET_QUIZ_RESPONSE_BY_USER_ID_URL,
	GET_QUIZ_URL,
	LAUNCH_EXAM_URL,
	REVIEW_QUESTION_URL,
	SELECT_AS_ANSWER_URL,
	SUBMIT_RESPONSE_URL,
	UPDATE_FORM_INFO_URL,
	GET_QUIZ_PREVIEW_FOR_NONUSER_URL
} from "../utils/urls";

export const createExam = async (data) => {
	return axios({
		method: "post",
		url: CREATE_EXAM_WITH_CHAPTER_AND_COURSE_URL,
		headers: {
			"Content-Type": "application/json",
			"x-auth-token": data.token,
		},
		data: {
			title: data.title,
			description: data.description,
			chapter_id: data.chapter_id,
			course_id: data.course_id,
			exam_start_time: data.exam_start_time,
		},
	});
};

export const getAllExamsByUserId = async (data) => {
	return axios({
		method: "get",
		url: GET_ALL_EXAMS_BY_USER_ID_URL + "/" + data.userId,
		headers: {
			"Content-Type": "application/json",
			"x-auth-token": data.token,
		},
	});
};

export const getQuizByUUID = async (data) => {
	return axios({
		method: "get",
		url: GET_QUIZ_URL + "/" + data.uuid,
		headers: {
			"Content-Type": "application/json",
			"x-auth-token": data.token,
		},
		// data: data,
	});
};

export const getQuizPreviewByUUID = async (data) => {
	return axios({
		method: "get",
		url: GET_QUIZ_PREVIEW_URL + "/" + data.uuid,
		headers: {
			"Content-Type": "application/json",
			"x-auth-token": data.token,
		},
	});
};

// export const getQuizPreviewForNonUserByUUID = async (data) => {
// 	return axios({
// 		method: "get",
// 		url: GET_QUIZ_PREVIEW_URL + "/" + data.uuid,
// 		headers: {
// 			"Content-Type": "application/json",
// 			// "x-auth-token": data.token,
// 		},
// 	});
// };

export const getQuizReportByUUID = async (data) => {
	return axios({
		method: "get",
		url: GET_QUIZ_REPORT_URL + "/" + data.uuid,
		headers: {
			"Content-Type": "application/json",
			"x-auth-token": data.token,
		},
	});
};

export const getQuizResponseByUUIDAndUserID = async (data) => {
	return axios({
		method: "get",
		url:
			GET_QUIZ_RESPONSE_BY_USER_ID_URL +
			"/" +
			data.uuid +
			"/" +
			data.userId,
		headers: {
			"Content-Type": "application/json",
			"x-auth-token": data.token,
		},
	});
};

export const getQuizAnswersByUUID = async (data) => {
	return axios({
		method: "get",
		url: GET_QUIZ_ANSWERS_URL + "/" + data.uuid,
		headers: {
			"Content-Type": "application/json",
			"x-auth-token": data.token,
		},
	});
};

export const updateFormInfo = async (data) => {
	return axios({
		method: "patch",
		url: UPDATE_FORM_INFO_URL + "/" + data.uuid,
		headers: {
			"Content-Type": "application/json",
			"x-auth-token": data.token,
		},
		data: {
			title: data.title,
			description: data.description,
			dueDateTime: data.dueDateTime,
			passMarks: data.passMarks,
			shuffleQuestions: data.shuffleQuestions,
			shuffleOptions: data.shuffleOptions,
		},
	});
};

export const toggleQuestionShuffle = async (data) => {
	return axios({
		method: "patch",
		url: UPDATE_FORM_INFO_URL + "/" + data.uuid,
		headers: {
			"Content-Type": "application/json",
			"x-auth-token": data.token,
		},
		data: {
			shuffleQuestions: data.shuffleQuestions,
		},
	});
};

export const toggleOptionShuffle = async (data) => {
	console.log(data);
	return axios({
		method: "patch",
		url: UPDATE_FORM_INFO_URL + "/" + data.uuid,
		headers: {
			"Content-Type": "application/json",
			"x-auth-token": data.token,
		},
		data: {
			shuffleOptions: data.shuffleOptions,
		},
	});
};

export const activateExam = async (data) => {
	return axios({
		method: "patch",
		url: ACTIVATE_EXAM_URL + "/" + data.examId,
		headers: {
			"Content-Type": "application/json",
			"x-auth-token": data.token,
		},
	});
};

export const deactivateExam = async (data) => {
	return axios({
		method: "patch",
		url: DEACTIVATE_EXAM_URL + "/" + data.examId,
		headers: {
			"Content-Type": "application/json",
			"x-auth-token": data.token,
		},
	});
};

export const createQuestion = async (data) => {
	return axios({
		method: "post",
		url: CREATE_QUESTION_URL,
		headers: {
			"Content-Type": "application/json",
			"x-auth-token": data.token,
		},
		data: {
			examId: data.examId,
		},
	});
};

export const deleteQuestion = async (data) => {
	return axios({
		method: "patch",
		url: DELETE_QUESTION_URL + "/" + data.questionId,
		headers: {
			"Content-Type": "application/json",
			"x-auth-token": data.token,
		},
	});
};

export const reviewQuestion = async (data) => {
	return axios({
		method: "patch",
		url: REVIEW_QUESTION_URL,
		headers: {
			"Content-Type": "application/json",
			"x-auth-token": data.token,
		},
		data: data,
	});
};

export const createOption = async (data) => {
	return axios({
		method: "post",
		url: ADD_NEW_OPTION_URL,
		headers: {
			"Content-Type": "application/json",
			"x-auth-token": data.token,
		},
		data: {
			questionId: data.questionId,
		},
	});
};

export const selectAsAnswer = async (data) => {
	return axios({
		method: "patch",
		url: SELECT_AS_ANSWER_URL + "/" + data.optionId,
		headers: {
			"Content-Type": "application/json",
			"x-auth-token": data.token,
		},
		data: {
			isCorrect: data.isCorrect,
		},
	});
};

export const launchExam = async (data) => {
	return axios({
		method: "patch",
		url: LAUNCH_EXAM_URL + "/" + data.examId,
		headers: {
			"Content-Type": "application/json",
			"x-auth-token": data.token,
		},
		data: {
			totalMinutes: data.totalMinutes,
			allocatedMinutes: data.allocatedMinutes,
		},
	});
};

export const submitQuizResponse = async (data) => {
	return axios({
		method: "post",
		url: SUBMIT_RESPONSE_URL,
		headers: {
			"Content-Type": "application/json",
			"x-auth-token": data.token,
		},
		data: {
			examId: data.examId,
			courseId: data.courseId,
			response: data.response,
		},
	});
};

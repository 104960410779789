import { useNavigate } from "react-router-dom";
import "./JumpingButton.css"
import { TiArrowRight } from "react-icons/ti";
import { pushToDataLayer } from "../../../../utils/gtm";

const JumpingButton = ({ path }) => {

    const navigate = useNavigate();

    const handleNavigate = () => {
        pushToDataLayer({
            event: 'Book Your Seat',
            course_name: 'IELTS Intensive Writing',
            course_price: '999 tk',
            course_description: 'Enhance your IELTS writing in a single go. Book today, Dont let it pass you by.',
            action: 'Click',
            time: new Date()
        })
        navigate(path && path)
    }

    return (
        <div onClick={() => handleNavigate(path)} className="bounceDown bounceLeft bounceRight">
            <button className="  flex items-center gap-2 px-5 py-1.5 rounded-md bg-[#FE8553] border border-[#FE8553] hover:text-[#FE8553] hover:bg-transparent transition-all duration-200 ease-linear font-medium text-white btn-jump-right">
                Book Your Seat
                <TiArrowRight className='text-xl' />
            </button>
        </div>
    );
};

export default JumpingButton;

import "../../CourseBranding/components/UpcommingCourse/UpcommingCourse.css"
import branding from '../../../media/images/course/ielts-writing-intensive.jpg'
import { IoStar } from "react-icons/io5";
import { MdLibraryAddCheck, MdOutlineCalendarToday } from "react-icons/md";
import { TiArrowRight } from 'react-icons/ti';
import { useNavigate } from "react-router-dom";
import { pushToDataLayer } from "../../../utils/gtm";

export default function IELTSWritingIntensiveCourse() {

	const navigate = useNavigate();

	const handleNavigatePurchansePage = () => {
		pushToDataLayer({
			event: 'Book Your Seat',
			course_name: 'IELTS Intensive Writing',
			course_price: '999 tk',
			course_description: 'Enhance your IELTS writing in a single go. Book today, Dont let it pass you by.',
			action: 'Click',
			time: new Date()
		})
		navigate('/ielts-writing-intensive/ieltsWritingIntensivePurchase')
	}

	return (
		<div className='container 2xl:px-20 px-5 mx-auto pt-10 pb-20'>
			<div className='grid lg:grid-cols-2 lg:gap-10'>
				<div className='bg-[#BECCF1] lg:bg-transparent rounded-t-2xl'>
					<img src={branding} alt="branding" className='max-w-full h-auto' />
				</div>
				<div className='border h-full rounded-2xl lg:rounded-t-2xl rounded-t-none mb-5 sm:mb-0'>
					<div className=' sm:p-8 p-4'>
						<h2 className=' sm:text-2xl text-lg font-medium'>Upcoming live batch</h2>
						<h1 className=' sm:text-4xl text-2xl font-bold text-[#5169A7] my-2' >IELTS Intensive Writing Course</h1>

						<div className=' flex items-center gap-1.5'>
							<div className=' flex items-center gap-1.5 text-amber-500 -mt-[2px]'>
								<IoStar />
								<IoStar />
								<IoStar />
								<IoStar />
								<IoStar />
							</div>
							<p className=' fonrt-medium'>208 Reviews</p>
						</div>
						<div className=' flex items-start gap-1.5 mt-3'>
							<MdOutlineCalendarToday className='mt-0.5 sm:text-lg text-md' />
							<p className=' text-[#002241] font-medium text-sm sm:text-base'>Batch Starts : June 2024</p>
						</div>
					</div>

					<div className=' border-t flex items-center justify-between sm:p-8 p-4'>
						<div>
							<h3 className='sm:text-xl text-md font-semibold text-[#001B36] mb-3'>You will get :</h3>

							<div >
								<div className=' flex items-start gap-2.5'>
									<MdLibraryAddCheck className=' text-lg text-amber-500 mt-0.5' />
									<p>15 Classes</p>
								</div>
								<div className=' flex items-start gap-2.5 my-1.5'>
									<MdLibraryAddCheck className=' text-lg text-amber-500 my-0.5' />
									<p>3 IELTS Writing Mock Test</p>
								</div>
								<div className=' flex items-start gap-2.5 my-1.5'>
									<MdLibraryAddCheck className=' text-lg text-amber-500 my-0.5' />
									<p className=' text-[#001B36]'>60 minutes session</p>
								</div>
								<div className=' flex items-start gap-2.5 my-1.5'>
									<MdLibraryAddCheck className=' text-lg text-amber-500 my-0.5' />
									<p className=' text-[#001B36]'>Premium Discount</p>
								</div>

							</div>
						</div>
						<div>
							<div className=' flex flex-col-reverse sm:flex-row items-center sm:gap-3 mb-4'>
								<h1 className=' text-5xl font-bold text-[#001B36]'>৳999</h1>
								{/* <h2 className=' text-5xl font-bold text-amber-800 opacity-10 line-through'>49</h2> */}
							</div>

							<div className="wiggle">
								<button onClick={() => handleNavigatePurchansePage()} className='sm:flex items-center gap-2 px-5 py-1.5 rounded-md bg-[#FE8553] border border-[#FE8553] hover:text-[#FE8553] hover:bg-transparent transition-all duration-200 ease-linear font-medium text-white hidden'>Book Now <TiArrowRight className='text-xl' /></button>
							</div>
						</div>

					</div>
					<div className="wiggle !flex !justify-center">
						<button onClick={() => navigate('/ielts-writing-intensive/ieltsWritingIntensivePurchase')} className='flex items-center gap-2 px-5 py-1.5 rounded-md bg-[#FE8553] border border-[#FE8553] hover:text-[#FE8553] hover:bg-transparent transition-all duration-200 ease-linear font-medium text-white sm:hidden '>Book Now <TiArrowRight className='text-xl' /></button>
					</div>
				</div>
			</div>
		</div>
	)
}

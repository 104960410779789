
import { CiTimer } from "react-icons/ci";
import { IoWalletOutline } from "react-icons/io5";
import { LuTimer } from "react-icons/lu";
import { PiExam } from "react-icons/pi";
export default function QuizInfo() {

    const quizInfo = [
        {
            id: 1,
            title: 'কোর্স সময়কাল',
            content: ['৪ মাস'],
            icon: <CiTimer />
        },
        {
            id: 2,
            title: 'রেকর্ডেড ক্লাস',
            content: ['যেকোনো সময়'],
            icon: <PiExam />
        },
        {
            id: 3,
            title: 'ব্যাচটাইম ',
            content: ['এক্সাম- রাত ৯-১০ টা', 'সলভ ক্লাস - রাত ৯-১০:৩০ টা  '],
            icon: <LuTimer />
        },
        // {
        //     id: 4,
        //     title: 'এনরোলমেন্ট ফি',
        //     content: ['২৫০টাকা  মাত্র (৭৫% ছাড়ে)'],
        //     icon: <IoWalletOutline />
        // }
    ]

    return (
        <div className=" bg-[#044884] py-28">
            <div className=" text-center mb-7">
                <h1 className=" text-2xl  text-white">সুবিধা ও পছন্দ অনুযায়ী যেকোনো ব্যাচে  মডেল  টেস্ট দেয়ার সুযোগ</h1>
                <div className=" h-1 lg:w-[33%] sm:w-[50%] w-[80%] my-5 bg-white mx-auto"></div>
            </div>
            <div className="container 2xl:px-20 xl:px-10 sm:px-5 px-2 mx-auto mt-[3.8rem] grid lg:grid-cols-3 sm:grid-cols-2 gap-5 ">
                {
                    quizInfo?.map(quiz => <div className=" bg-white p-6 rounded-2xl" key={quiz?.id}>
                        <div className=" bg-[#044884] text-3xl w-10 h-10 text-white rounded-md flex items-center justify-center mx-auto mb-5 ">{quiz?.icon}</div>
                        <h1 className="text-2xl font-semibold text-center">{quiz?.title}</h1>
                        <div className=" h-[1.5px] w-40 bg-gray-400 mx-auto my-3"></div>

                        <div className="flex justify-center">
                            <div>
                                {
                                    quiz?.content.map(con => <div className="flex items-start gap-3 mb-2 font-bold">
                                        <div className=" w-5">
                                            <div className=" h-4 w-4 rounded-full bg-[#044884] mt-1"></div>
                                        </div>
                                        <p className=" text-lg font-bold ">{con}</p>
                                    </div>)
                                }
                            </div>
                        </div>
                    </div>)
                }
            </div>
        </div>
    )
}

import { useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../../utils/urls";
import { loadStorage } from "../../../../utils/persistLocalStorage";
import { useNavigate } from "react-router-dom";
import { HSC_Course_Id } from "../../../../utils/CourseCodeAndId";

const CheckEnrollment = ({ children }) => {

  const user = loadStorage("user");
  const navigate = useNavigate();

  useEffect(() => {
    const checkEnrollment = async () => {
      if (user?.token) {
        try {
          const response = await axios.post(`${BASE_URL}/course/hscQuizeCourseEnrollment/${HSC_Course_Id}`, {}, { headers: { "Content-Type": "application/json", "x-auth-token": user?.token }, });
           
          if (response?.data?.data?.enrollStatus !== "approve") {
            return navigate("/hscmodeltest");
          }
        } catch (error) {
          return navigate("/hscmodeltest");
        }
      } else {
        navigate('/auth/login', { state: { from: window.location.pathname } });
      }
    };

    checkEnrollment();
  }, [user, navigate]);

  return children;
};

export default CheckEnrollment;

import React from 'react';

const CreateCareer = () => {
    return (
        <div>
            CreateCareer
        </div>
    );
};

export default CreateCareer;
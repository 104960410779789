import "../components/Hero/Hero.css"
import logo from '../../..//media/images/logo.png'
import JumpingButton from '../components/JumpingButton/JumpingButton'

export default function IELTSWritingIntensiveHero() {
    return (
        <div className=' py-20  flex justify-center items-center flex-col text-[#001B36c] text-center'>
            <img src={logo} alt="logo" className=' md:w-72 sm:w-64 w-40 md:mb-10 mb-5' />
            <h1 className='md:text-5xl sm:text-4xl text-2xl font-bold px-4'>Getting Band Score 8+ in IELTS exam</h1>
            <p className='sm:text-3xl text-xl sm:py-5 py-2 font-medium px-4'>Do you want to know the secrets?</p>
            <p className=' sm:mb-10 mb-5 px-4'>then don't miss this opportunity to Join this IELTS Intensive Writing Course success formula</p>
            <JumpingButton path='/ielts-writing-intensive/ieltsWritingIntensivePurchase' />
        </div>
    )
}

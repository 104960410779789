/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { getCourseInfoByCourseCode } from "../../../../../api/course.api";
import QuizTable from "./QuizTable/QuizTable";
import QuizTitleHeader from "./QuizTitleHeader";
import { IoIosArrowDown } from "react-icons/io";
import { loadStorage } from "../../../../../utils/persistLocalStorage";
import { getChapterExams } from "../../../../../api/chapter.api";
import moment from "moment/moment";
import { HSC_Course_Code, HSC_Course_Id } from "../../../../../utils/CourseCodeAndId";
import LiveExam from "./LiveExam";

export default function QuizExam() {

    const courseCode = HSC_Course_Code;
    const user = loadStorage("user");
    const [chapters, setChapters] = useState([]);
    const storedChapter = localStorage.getItem("selectedChapter");
    const [selectedChapter, setSelectedChapter] = useState(storedChapter ? JSON.parse(storedChapter) : null);
    const [courseOpen, setCourseOpen] = useState(true)
    const [examList, setExamList] = useState([]);

    useEffect(() => {
        fetchCourseInfo(courseCode);
    }, [courseCode]);

    useEffect(() => {
        if (selectedChapter?.id) {
            fetchChapterExams(HSC_Course_Id, selectedChapter?.id);
        }

    }, [selectedChapter]);

    const handleSelectedChapter = (chapter) => {
        localStorage.setItem("selectedChapter", JSON.stringify(chapter));
        setSelectedChapter(chapter)
    }

    const fetchCourseInfo = (courseCode) => {
        getCourseInfoByCourseCode({
            courseCode: courseCode,
            userId: user?.id,
        })
            .then((res) => {
                setChapters(res?.data?.data?.chapters);
            })
            .catch((err) => {
            });
    };

    const fetchChapterExams = (courseId, chapterId) => {
        getChapterExams({
            courseId,
            chapterId,
            token: user?.token,
        })
            .then((res) => {
                setExamList(res?.data?.data);
                // console.log(res?.data?.data);
            })
            .catch((err) => {
                // console.log(err?.response);
            });
    };

    const today = moment().startOf("day");
    function categorizeDates(data) {
        const todayData = [];
        const pastData = [];
        const futureData = [];

        data?.forEach(function (item) {
            const itemDate = moment(
                item?.exam?.exam_start_time,
                "YYYY-MM-DD HH:mm:ss.SSSZ"
            ).startOf("day");

            if (itemDate.isSame(today, "day")) {
                todayData.push(item);
            } else if (itemDate.isBefore(today, "day")) {
                pastData.push(item);
            } else {
                futureData.push(item);
            }
        });

        return {
            today: todayData,
            past: pastData,
            future: futureData,
        };
    }

    const categorizedData = categorizeDates(examList);



    return (
        <div >
            <QuizTitleHeader text="MCQ Exam সমূহ" />
            <LiveExam />
            <div className=" py-4 flex items-end sm:gap-14 gap-10 pl-2">
                <h1 className=" text-2xl font-semibold text-[#001B36]">প্রিভিয়াস এক্সাম </h1>
            </div>
            <div className="lg:flex items-start gap-5">
                <div className={`bg-white shadow lg:w-72 rounded-lg p-3 pb-8 mb-5 lg:mb-0  ${courseOpen ? 'max-h-[60vh] overflow-scroll' : 'max-h-[7vh] overflow-hidden '}  cursor-pointer transition-all ease-linear duration-200`}>
                    <div onClick={() => setCourseOpen(!courseOpen)} className="flex gap-4 py-2 pb-3 text-xl font-medium justify-center border-b">
                        <h2>কোর্স নির্বাচন করুন</h2>

                        <IoIosArrowDown className={`mt-1 transition-all ease-linear duration-300 ${courseOpen ? 'rotate-0' : '-rotate-180'}`} />
                    </div>
                    {chapters?.map((chapter) => (
                        <div
                            key={chapter?.id}
                            onClick={() => handleSelectedChapter(chapter)}
                            className={`text-center border-b border-gray-300 py-2 text-lg  cursor-pointer ${chapter?.id === selectedChapter?.id ? "bg-[#3072AB] text-white" : "hover:bg-[#F2F9FF]"
                                }`}
                        >
                            <p>{chapter?.chapter_name}</p>
                        </div>
                    ))}

                </div>
                <div className="w-full overflow-auto no-scrollbar">
                    {categorizedData?.today?.length > 0 && (
                        <QuizTable title="আজকের চলমান Exam" data={categorizedData?.today} />
                    )}
                    {categorizedData?.past?.length > 0 && (
                        <QuizTable title="প্রিভিয়াস Exam" data={categorizedData?.past} />
                    )}
                    {categorizedData?.future?.length > 0 && (
                        <QuizTable title="আপকামিং Exam" data={categorizedData?.future} />
                    )}
                    {examList?.length === 0 && <p>This is no Quiz to attempt.</p>}
                </div>


            </div>
        </div>
    );
}

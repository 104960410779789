import React from 'react'
import Success from './components/Success/Success'
import Value from './components/Value'
import CashBack from './components/CashBack/CashBack'
import BrandMentors from './components/BrandMentors'
import Kit from './components/Kit/Kit'
import IELTSWritingIntensiveHero from './components/IELTSWritingIntensiveHero'
import IELTSWritingIntensiveCourse from './components/IELTSWritingIntensiveCourse'
import Footer from './components/Footer'
import ServiceHolder from './components/ServiceHolder'
import CourseInfo from './components/CourseInfo/CourseInfo'

export default function IELTSWritingIntensive() {
	return (
		<div className=" bg-white text-[#001B36]">
			<IELTSWritingIntensiveHero />
			<IELTSWritingIntensiveCourse />
			<CourseInfo />
			<ServiceHolder />
			<Success path='/ielts-writing-intensive/ieltsWritingIntensivePurchase' />
			<Value />
			<CashBack text='If you complete our advance course and receive band score of less than 6.00 in the IELTS main Exam, you will get 100% refund.' path='/ielts-writing-intensive/ieltsWritingIntensivePurchase' />
			<BrandMentors />
			<Kit path='/ielts-writing-intensive/ieltsWritingIntensivePurchase' />
			<Footer />
		</div>
	)
}

import "../../CourseBranding/components/UpcommingCourse/UpcommingCourse.css"
import branding from '../../../media/images//course/funnel image 3.jpg'
import { IoStar } from "react-icons/io5";
import { MdLibraryAddCheck, MdOutlineCalendarToday } from "react-icons/md";
import { TiArrowRight } from 'react-icons/ti';
import { useNavigate } from "react-router-dom";

export default function AdvanceUpcommingCourse() {
    const navigate = useNavigate()
    return (
        <div className=' container 2xl:px-20 px-5 mx-auto pt-10 pb-20'>
            <div className=' grid lg:grid-cols-2 lg:gap-10'>
                <div className='bg-[#BECCF1] lg:bg-transparent rounded-t-2xl'>
                    <img src={branding} alt="branding" className=' h-full' />
                </div>
                <div className=' border h-full rounded-2xl lg:rounded-t-2xl rounded-t-none mb-5 sm:mb-0'>
                    <div className=' sm:p-8 p-4'>
                        <h2 className=' sm:text-2xl text-lg font-medium'>Upcoming live batch</h2>
                        <h1 className=' sm:text-4xl text-2xl font-bold text-[#5169A7] my-2' >IELTS Advance Course for all module</h1>

                        <div className=' flex items-center gap-1.5'>
                            <div className=' flex items-center gap-1.5 text-amber-500 -mt-[2px]'>
                                <IoStar />
                                <IoStar />
                                <IoStar />
                                <IoStar />
                                <IoStar />
                            </div>
                            <p className=' fonrt-medium'>208 Reviews</p>
                        </div>
                        <div className=' flex items-start gap-1.5 mt-3'>
                            <MdOutlineCalendarToday className='mt-0.5 sm:text-lg text-md' />
                            <p className=' text-[#002241] font-medium text-sm sm:text-base'>Batch Starts: 9 March, Saturday - 9:00 PM</p>
                        </div>
                    </div>

                    <div className=' border-t flex items-center justify-between sm:p-8 p-4'>
                        <div>
                            <h3 className='sm:text-xl text-md font-semibold text-[#001B36] mb-3'>You Will get Extra</h3>

                            <div >
                                <div className=' flex items-start gap-2.5'>
                                    <MdLibraryAddCheck className=' text-lg text-amber-500 mt-0.5' />
                                    <p>৩৬৫ দিনের স্টাডি প্ল্যান</p>
                                </div>
                                <div className=' flex items-start gap-2.5 my-1.5'>
                                    <MdLibraryAddCheck className=' text-lg text-amber-500 my-0.5' />
                                    <p>৪০ টি লাইভ ক্লাস</p>
                                </div>
                                <div className=' flex items-start gap-2.5 my-1.5'>
                                    <MdLibraryAddCheck className=' text-lg text-amber-500 my-0.5' />
                                    <p className=' text-[#001B36]'>২ টি কিস্তিতে পেমেন্ট সুবিধা</p>
                                </div>
                                <div className=' flex items-start gap-2.5 my-1.5'>
                                    <MdLibraryAddCheck className=' text-lg text-amber-500 my-0.5' />
                                    <p className=' text-[#001B36]'>ক্লাস রেকর্ড</p>
                                </div>
                                <div className=' flex items-start gap-2.5 my-1.5'>
                                    <MdLibraryAddCheck className=' text-lg text-amber-500 my-0.5' />
                                    <p className=' text-[#001B36]'>মডিউল ভিত্তিক লেকচার শীট</p>
                                </div>
                                <div className=' flex items-start gap-2.5 my-1.5'>
                                    <MdLibraryAddCheck className=' text-lg text-amber-500 my-0.5' />
                                    <p className=' text-[#001B36]'>প্রতি সপ্তাহে ১ টি করে টেস্ট</p>
                                </div>
                                <div className=' flex items-start gap-2.5 my-1.5'>
                                    <MdLibraryAddCheck className=' text-lg text-amber-500 my-0.5' />
                                    <p className=' text-[#001B36]'>কোর্স সার্টিফিকেট</p>
                                </div>
                                <div className=' flex items-start gap-2.5 my-1.5'>
                                    <MdLibraryAddCheck className=' text-lg text-amber-500 my-0.5' />
                                    <p className=' text-[#001B36]'>কোর্স শেষে ২ টি পূর্ণাঙ্গ মক টেস্ট</p>
                                </div>
                                <div className=' flex items-start gap-2.5 my-1.5'>
                                    <MdLibraryAddCheck className=' text-lg text-amber-500 my-0.5' />
                                    <p className=' text-[#001B36]'>প্রতি শুক্রবার প্রব্লেম সল্ভিং ক্লাস</p>
                                </div>
                                <div className=' flex items-start gap-2.5 my-1.5'>
                                    <MdLibraryAddCheck className=' text-lg text-amber-500 my-0.5' />
                                    <p className=' text-[#001B36]'>১০০% মানি ব্যাক গেরান্টি</p>
                                </div>
                                <div className=' flex items-start gap-2.5 my-1.5'>
                                    <MdLibraryAddCheck className=' text-lg text-amber-500 my-0.5' />
                                    <p className=' text-[#001B36]'>কোর্স ডিউরেশন ৩ মাস</p>
                                </div>

                            </div>
                        </div>
                        <div>
                            <div className=' flex flex-col-reverse sm:flex-row items-center sm:gap-3 mb-4'>
                                <h1 className=' text-5xl font-bold text-[#001B36]'>৳6000</h1>
                                {/* <h2 className=' text-5xl font-bold text-amber-800 opacity-10 line-through'>49</h2> */}
                            </div>

                            <div className="wiggle">
                                <button onClick={() => navigate('/advanceCourse/advancePurchase')} className='sm:flex items-center gap-2 px-5 py-1.5 rounded-md bg-[#FE8553] border border-[#FE8553] hover:text-[#FE8553] hover:bg-transparent transition-all duration-200 ease-linear font-medium text-white hidden'>Book Now <TiArrowRight className='text-xl' /></button>
                            </div>
                        </div>

                    </div>
                    <div className="wiggle !flex !justify-center">
                        <button onClick={() => navigate('/advanceCourse/advancePurchase')} className='flex items-center gap-2 px-5 py-1.5 rounded-md bg-[#FE8553] border border-[#FE8553] hover:text-[#FE8553] hover:bg-transparent transition-all duration-200 ease-linear font-medium text-white sm:hidden '>Book Now <TiArrowRight className='text-xl' /></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

import "../../CourseBranding/components/UpcommingCourse/UpcommingCourse.css"
import branding from '../../../media/images/course/funnel image 1.jpg'
import { IoStar } from "react-icons/io5";
import { MdLibraryAddCheck, MdOutlineCalendarToday } from "react-icons/md";
import { TiArrowRight } from 'react-icons/ti';
import { useNavigate } from "react-router-dom";

export default function CrashUpcommingCourse() {
    const navigate = useNavigate()
    return (
        <div className=' container 2xl:px-20 px-5 mx-auto pt-10 pb-20'>
            <div className=' grid lg:grid-cols-2 lg:gap-10'>
                <div className='bg-[#BECCF1] lg:bg-transparent rounded-t-2xl'>
                    <img src={branding} alt="branding" className=' h-full' />
                </div>
                <div className=' border h-full rounded-2xl lg:rounded-t-2xl rounded-t-none mb-5 sm:mb-0'>
                    <div className=' sm:p-8 p-4'>
                        <h2 className=' sm:text-2xl text-lg font-medium'>Upcoming live batch</h2>
                        <h1 className=' sm:text-4xl text-2xl font-bold text-[#5169A7] my-2' >IELTS Advance Module</h1>

                        <div className=' flex items-center gap-1.5'>
                            <div className=' flex items-center gap-1.5 text-amber-500 -mt-[2px]'>
                                <IoStar />
                                <IoStar />
                                <IoStar />
                                <IoStar />
                                <IoStar />
                            </div>
                            <p className=' fonrt-medium'>208 Reviews</p>
                        </div>
                        <div className=' flex items-start gap-1.5 mt-3'>
                            <MdOutlineCalendarToday className='mt-0.5 sm:text-lg text-md' />
                            <p className=' text-[#002241] font-medium text-sm sm:text-base'>Workshop Starts: 10 March, Sunday - 9:00 PM</p>
                        </div>
                    </div>

                    <div className=' border-t flex items-center justify-between sm:p-8 p-4'>
                        <div>
                            <h3 className='sm:text-xl text-md font-semibold text-[#001B36] mb-3'>You Will get Extra</h3>

                            <div >
                                <div className=' flex items-start gap-2.5'>
                                    <MdLibraryAddCheck className=' text-lg text-amber-500 mt-0.5' />
                                    <p>Total 12 LIVE Class + 3 Practice Session</p>
                                </div>
                                <div className=' flex items-start gap-2.5 my-1.5'>
                                    <MdLibraryAddCheck className=' text-lg text-amber-500 mt-0.5' />
                                    <p>3 Real Speaking Live Mock Tests</p>
                                </div>
                                <div className=' flex items-start gap-2.5 my-1.5'>
                                    <MdLibraryAddCheck className=' text-lg text-amber-500 mt-0.5' />
                                    <p>2 instructors with 3 years experience</p>
                                </div>
                                <div className=' flex items-start gap-2.5 my-1.5'>
                                    <MdLibraryAddCheck className=' text-lg text-amber-500 mt-0.5' />
                                    <p>Total 12 LIVE Class + 3 Practice Session</p>
                                </div>
                                <div className=' flex items-start gap-2.5 my-1.5'>
                                    <MdLibraryAddCheck className=' text-lg text-amber-500 mt-0.5' />
                                    <p>Master in 300+ Q-cards by learning just 5 Q-CARD formats</p>
                                </div>
                                <div className=' flex items-start gap-2.5 my-1.5'>
                                    <MdLibraryAddCheck className=' text-lg text-amber-500 mt-0.5' />
                                    <p>Lecture sheets, class records, and course materials</p>
                                </div>
                                <div className=' flex items-start gap-2.5 my-1.5'>
                                    <MdLibraryAddCheck className=' text-lg text-amber-500 mt-0.5' />
                                    <p>Regular Home Work & One-to-One feedback</p>
                                </div>
                                <div className=' flex items-start gap-2.5 my-1.5'>
                                    <MdLibraryAddCheck className=' text-lg text-amber-500 mt-0.5' />
                                    <p>100% Money Back Guarantee</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className=' flex flex-col-reverse sm:flex-row items-center sm:gap-3 mb-4'>
                                <h1 className=' text-5xl font-bold text-[#001B36]'>৳৯৯০</h1>
                                {/* <h2 className=' text-5xl font-bold text-amber-800 opacity-10 line-through'>49</h2> */}
                            </div>

                            <div className="wiggle">
                                <button onClick={() => navigate('/speakingCourse/speakingPurchase')} className='sm:flex items-center gap-2 px-5 py-1.5 rounded-md bg-[#FE8553] border border-[#FE8553] hover:text-[#FE8553] hover:bg-transparent transition-all duration-200 ease-linear font-medium text-white hidden'>Book Now <TiArrowRight className='text-xl' /></button>
                            </div>
                        </div>

                    </div>
                    <div className="wiggle !flex !justify-center">
                        <button onClick={() => navigate('/speakingCourse/speakingPurchase')} className='flex items-center gap-2 px-5 py-1.5 rounded-md bg-[#FE8553] border border-[#FE8553] hover:text-[#FE8553] hover:bg-transparent transition-all duration-200 ease-linear font-medium text-white sm:hidden '>Book Now <TiArrowRight className='text-xl' /></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

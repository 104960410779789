import axios from "axios";
import { BASE_URL } from "../utils/urls";

export const getDashboardStates = ({ dateRange, token, startDate, endDate }) => {
  return axios({
    method: "get",
    url: `${BASE_URL}/order/get-states?dateRange=${dateRange}&startDate=${startDate}&endDate=${endDate}`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  });
};

export const downloadOrdersCSV = ({ dateRange, token, startDate, endDate }) => {
  return axios({
    method: "get",
    url: `${BASE_URL}/csv/orders?dateRange=${dateRange}&startDate=${startDate}&endDate=${endDate}`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    responseType: 'blob'
  });
};

export const getOrdersList = ({ page, limit, searchBy, token, status }) => {
  let queryParams = `page=${page}&limit=${limit}`;

  if (searchBy) {
    Object.keys(searchBy).forEach(key => {
      queryParams += `&${key}=${searchBy[key]}`;
    });
  }

  if (status) {
    queryParams += `&order_status=${status}`;
  }

  return axios({
    method: "get",
    url: `${BASE_URL}/order/get-orders-list?${queryParams}`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  });
};

export const bulkOrdresUpdate = ({ data, token }) => {
  return axios({
    method: "put",
    url: `${BASE_URL}/order/bulk-orders-update`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data
  });
};

export const getUserOrderList = ({ page, limit, token, userId }) => {

  let queryParams = `page=${page}&limit=${limit}`;

  return axios({
    method: "get",
    url: `${BASE_URL}/order/get-orders/${userId}?${queryParams}`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  });
};


export const getUserCoursePurchaseList = ({ userId }) => {
  return axios({
    method: "get",
    url: `${BASE_URL}/userCourse/get-userCourse-list/${userId}`,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'

import AdminMenu from './AdminMenu'

export default function AdminSideNav({ openMenu, setOpenMenu }) {
    return (
        <div>
            <div onClick={() => setOpenMenu(false)} className={`w-screen h-[90vh]  bg-black bg-opacity-25 sm:hidden bottom-0  ${openMenu ? 'fixed' : 'hidden'}`}></div>
            <div className={`h-[90vh] fixed Z-[1000]  bottom-0 bg-white Shadow transition-all duration-300 ease-linear z-[1000] border-r  ${openMenu ? 'sm:w-80 w-64 left-0' : 'sm:w-24 -left-40 sm:left-0 '}`}>

                <div className=" flex flex-col justify-between relative Z-[100]  h-full">
                    <div className=" relative">
                        {/* conrolar */}
                        <button type="button" onClick={() => setOpenMenu(!openMenu)} className={`h-10 w-10 rounded-md border border-gray-200 flex items-center justify-center text-xl bg-[#FFFFFF] Shadow absolute top-1 right-1 cursor-pointer transition-all duration-200 ease-linear hover:bg-gray-200 `}>
                            <IoIosArrowBack className={`transition-all duration-300 ease-linear ${openMenu ? 'rotate-0' : 'rotate-180'}`} />
                        </button>
                        <AdminMenu openMenu={openMenu} />
                    </div>
                </div>
            </div>
        </div>
    )
}

import React from "react";
import Lottie from "lottie-react";
import loading from "../../media/lottie-anims/loading.json";
import loading1 from "../../media/lottie-anims/loading1.json";

function Loading(props) {
	return (
		<div className="loading">
			<Lottie
				style={{ height: 200 }}
				animationData={loading}
				loop={true}
				autoplay={true}
			/>
		</div>
	);
}

export default Loading;

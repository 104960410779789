import React, { useEffect, useState } from 'react'
import AdminSideNav from './components/AdminSideNav';
import AdminTopNav from './components/AdminTopNav';

export default function AdminLayout({ children }) {
    const [openMenu, setOpenMenu] = useState(true)
    useEffect(() => {
        const handleResize = () => setOpenMenu(window.innerWidth >= 1280);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className="bg-white">
            <AdminTopNav openMenu={openMenu} setOpenMenu={setOpenMenu} />

            <div className={`transition-all duration-300 ease-linear w-full flex ${openMenu ? 'xl:pl-80' : 'sm:pl-24 pl-0'}`}>
                <AdminSideNav openMenu={openMenu} setOpenMenu={setOpenMenu} />
                <div className="mt-[10vh] w-full">
                    {children}
                </div>
            </div>
        </div>
    )
}

/* eslint-disable react-hooks/exhaustive-deps */
import liveIcon from '../../../../../media/images/quiz/liveIcon.png'
import { getLiveCourse } from '../../../../../api/course.api';
import { loadStorage } from '../../../../../utils/persistLocalStorage';
import { useEffect, useState } from 'react';
import { HSC_Course_Id } from '../../../../../utils/CourseCodeAndId';
import SingleLiveExam from './SingleLiveExam';

export default function LiveExam() {

  const user = loadStorage("user");
  const [liveExam, setLiveExam] = useState([]);

  useEffect(() => {
    fetchCourseInfo();
  }, [])

  const fetchCourseInfo = () => {
    getLiveCourse({
      courseId: HSC_Course_Id,
      data: user?.token,
    })
      .then((res) => {
        setLiveExam(res?.data?.data)
      })
      .catch((err) => {
        console.log(err)
      });
  };
  
  return (
    <>
      {
        liveExam?.length !== 0 &&
        <div className=' mb-10'>
          <div className=" py-4 flex items-end sm:gap-14 gap-10  pl-2">
            <h1 className=" text-2xl font-semibold text-[#001B36]">লাইভ এক্সাম</h1>
            <img src={liveIcon} alt="liveIcon" />
          </div>
          <div className="overflow-x-auto no-scrollbar min-w-full">
            {
              liveExam?.map(live => <SingleLiveExam key={live?.uuid} live={live} />)
            }
          </div>
        </div>
      }
    </>
  )
}


import { MdLibraryAddCheck } from 'react-icons/md'
import resonImg from '../../../media/images/quiz/web hero 2.jpg'
export default function Reson() {
    const resonList = [
        '৪০+ বোর্ড স্ট্যান্ডার্ড MCQ এক্সাম এর সুযোগ', 'অল্প সময়ে নিজের দুর্বলতা কে চিহ্নিত করতে পারা।', 'দেশের যেকোনো প্রান্ত থেকে, যেকোনো সময় পরীক্ষায় অংশগ্রহণ করার সুযোগ।', '৪ মাসব্যাপী পরিকল্পিত একটি কোর্স এর মাধ্যমে পরীক্ষার পরিবেশে থাকা।', 'পরীক্ষার পর Effective Solve Class করে সমস্যা সমাধানের সুযোগ।', 'প্রত্যেক মাস শেষে সেরা পরীক্ষার্থী হিসেবে আকর্ষণীয় পুরষ্কার লাভের সুযোগ।', 'Solve Class মিস হলে রেকর্ডেড ক্লাস   করার সুযোগ।', 'কোর্সে এনরোল করে আমাদের সাথে পুরো কোর্স শেষ করার পর যদি HSC 24 এক্সামে তোমার উপকার না হয়,তবে ১০০% মানিব্যাক গ্যারান্টি থাকবে।'
    ]
    return (
        <div className="container 2xl:px-20 xl:px-10 sm:px-5 px-2 mx-auto mt-[3.8rem] lg:flex pb-20 pt-10 xl:gap-16 gap-5">
            <div className=" lg:w-1/2">
                <img src={resonImg} alt="resonImg" className=' rounded-xl' />
            </div>
            <div className=" lg:w-1/2 mt-12 lg:mt-0">
                <h1 className=' text-[001B36] sm:text-5xl text-4xl font-semibold xl:mb-12 mb-5'>কেন করবে এই কোর্সটি?</h1>

                <div className=' grid sm:grid-cols-2 xl:gap-x-8 gap-y-4'>
                    {
                        resonList?.map((list, index) => <div key={index} className=' flex gap-2'> <MdLibraryAddCheck className=' text-[#FE8553] text-[24px] w-[10%] mt-1' /> <p className='w-[90%] text-lg font-medium text-[#001B36;]'>{list}</p></div>)
                    }
                </div>
            </div>
        </div>
    )
}

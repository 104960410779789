import CourseCard from "./CourseCard";
import { IoSearch } from "react-icons/io5";
import Navbar from "../../components/Navbar/Navbar";
import SeeMoreBtn from "../FreeLearning/components/SeeMoreBtn";
import Footer from "../QuizPages/Components/Footer";

export default function Course() {
    const card = [1, 2, 3, 4, 5, 6]
    return (
        <div className=" bg-white min-h-[100vh]">
            <Navbar />
            <div className=" container 2xl:px-20 xl:px-10 sm:px-5 px-2 mx-auto pt-10 relative mt-[3.8rem] pb-14">
                {/* <div className=" flex  sm:flex-row flex-col-reverse justify-between bg-white sm:shadow mb-10 p-3 rounded-md">
                    <div className=" flex items-center gap-8 justify-center sm:justify-normal ">
                        <button className=" py-1.5 px-6 rounded-full border border-black font-medium hover:bg-transparent hover:text-black bg-black text-white ">Courses</button>
                        <button className=" py-1.5 px-6 rounded-full border border-black font-medium hover:bg-transparent hover:text-black ">Free Class</button>
                    </div>
                    <div className='flex sm:w-[250px] w-full  mb-5 sm:mb-0'>
                        <input type="text" className='p-2 flex-1 focus:outline-none border border-black rounded-l-full ' />
                        <button className='bg-black px-4 text-white font-medium rounded-r-full text-2xl'><IoSearch /></button>
                    </div>
                </div> */}
                <div className=' pb-10 grid xl:grid-cols-3 md:grid-cols-2 2xl:gap-10 gap-8  2xl:px-20'>
                    {
                        card?.map(ca => <CourseCard key={ca} ca={ca} />)
                    }
                </div>
                <SeeMoreBtn />
            </div>
            <Footer />
        </div>
    )
}

export const orgCoverPlaceholder = require("../media/images/org_cover_placeholder.jpg");
export const defaultProfile = require("../media/images/profile/default_profile.jpg");

export const TSCDemoLogo = require("../media/images/TSCDemoLogo.png");
export const WorkingOnImg = require("../media/images/working-on.png");
export const folderImg = require("../media/images/folder.png");
export const badgeImg = require("../media/images/badge.png");
export const subjectPlaceholderImg = require("../media/images/placeholder_img.png");
export const examImg = require("../media/images/exam_img.png");

export const fbIcon = require("../media/images/icons/facebook.png");
export const twitterIcon = require("../media/images/icons/twitter.png");
export const youtubeIcon = require("../media/images/icons/youtube.png");
export const instagramIcon = require("../media/images/icons/instagram.png");

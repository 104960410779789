import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import Loading from "./components/loading/Loading";
import JobApplyPage from "./pages/CommonSection/CareerPage/JobApplyPage";
import AllCareer from "./pages/ModeratorSection/pages/AllCareer";
import CreateCareer from "./pages/ModeratorSection/pages/CreateCareer";
import ModeratorCareerPage from "./pages/ModeratorSection/pages/ModeratorCareerPage";
import UpdateCareer from "./pages/ModeratorSection/pages/UpdateCareer";
import QuizLandingPage from "./pages/QuizPages/QuizLandingPage.jsx";
import QuizExam from "./pages/StudentSection/Dashboard/pages/quizExam/QuizExam.jsx";
import CheckEnrollment from "./pages/QuizPages/Components/ProtectedRoute/CheckEnrollment.jsx";
import Course from "./pages/CoursePage/Course.jsx";
import CourseDetails from "./pages/CoursePage/CourseDetails.jsx";
import PurchasePage from "./pages/CommonSection/PurchasePage/PurchasePage.jsx";
import ThankYouPage from "./pages/CommonSection/PurchasePage/ThankYouPage/ThankYouPage.jsx";
import AdminDashboard from "./pages/Admin/AdminDashboard.jsx";
import Orders from "./pages/Admin/Orders/Orders.jsx";
import Dashboard from "./pages/Admin/Dashboard/Dashboard.jsx";
import DeclinePage from "./pages/CommonSection/PurchasePage/declinePage/DeclinePage.jsx";
import OrderHistory from "./pages/CommonSection/PurchasePage/orderHistory/OrderHistory.jsx";
import CheckPayment from "./pages/CommonSection/PurchasePage/components/ProtectedRoutes/CheckPayment.jsx";
import RequireAdmin from "./pages/Admin/components/ProtectedRoute/RequireAdmin.jsx";
import AdvanceCourse from "./pages/advanceCourse/AdvanceCourse.jsx";
import CrashCourse from "./pages/crashCourse/CrashCourse.jsx";
import SpeakingPurchasePage from "./pages/speakingPurchasePage/SpeakingPurchasePage.jsx";
import SpeakingWorkshopPurchase from "./pages/speakingWorkshopPurchase/SpeakingWorkshopPurchase.jsx";
import AdvancePurchase from "./pages/advancePurchase/AdvancePurchase.jsx";
import CourseContentShow from "./pages/ielts/courseContent/CourseContentShow.jsx";
import CheckCourseAccess from "./pages/ielts/courseContent/CheckCourseAccess.js";
import FreeIeltsPurchase from "./pages/freeIeltsPurchase/FreeIeltsPurchase.jsx";
import PhysicsSmartHSC24 from "./pages/PhysicsSmartHSC24/PhysicsSmartHSC24.jsx";
import HSC24PhysicsPurchasePage from "./pages/HSC24PhysicsPurchase/HSC24PhysicsPurchasePage.jsx";
import IELTSWritingIntensive from "./pages/IELTSWritingIntensive/IELTSWritingIntensive.jsx";
import IELTSWritingIntensivePurchasePage from "./pages/IELTSWritingIntensivePurchase/IELTSWritingIntensivePurchasePage.jsx";
import TrackPageView from "./utils/TrackPageView.js";







const LandingPage = lazy(() =>
  import("./pages/CommonSection/LandingPage/LandingPage")
);
const RegistrationPage = lazy(() =>
  import("./pages/CommonSection/Authentication/pages/RegistrationPage")
);
const LoginPage = lazy(() =>
  import("./pages/CommonSection/Authentication/pages/LoginPage")
);
const ResetPassword = lazy(() =>
  import("./pages/CommonSection/Authentication/pages/ResetPassword")
);
const DemoPage = lazy(() => import("./pages/CommonSection/Demo/DemoPage"));
const CoursesPage = lazy(() =>
  import("./pages/CommonSection/CoursesPage/CoursesPage")
);
const TeamPage = lazy(() => import("./pages/CommonSection/TeamPage/TeamPage"));
const CareerPage = lazy(() =>
  import("./pages/CommonSection/CareerPage/CareerPage")
);
const JobDetailsPage = lazy(() =>
  import("./pages/CommonSection/CareerPage/JobDetailsPage")
);
const PrivacyPolicy = lazy(() =>
  import("./pages/CommonSection/PrivacyPolicy/PrivacyPolicy")
);
const Terms = lazy(() => import("./pages/CommonSection/Terms/Terms"));
const FeedbackPage = lazy(() =>
  import("./pages/CommonSection/Feedback/Feedback.jsx")
);
// User
const DashboardPage = lazy(() =>
  import("./pages/CommonSection/DashboardPage/DashboardPage")
);
const MyCoursesPage = lazy(() =>
  import("./pages/CommonSection/MyCoursesPage/MyCoursesPage")
);
const ProfilePage = lazy(() =>
  import("./pages/CommonSection/Profile/ProfilePage")
);
const SettingsPage = lazy(() =>
  import("./pages/CommonSection/SettingsPage/SettingsPage")
);
const CourseDetailsPage = lazy(() =>
  import("./pages/CommonSection/CourseDetailsPage/CourseDetailsPage")
);
const ChapterDetails = lazy(() =>
  import("./pages/CommonSection/ChapterDetailsPage/ChapterDetails")
);
// Organization
const AllUsers = lazy(() =>
  import("./pages/OrganizationSection/pages/AccountPage/AllUsers")
);
const AdminPage = lazy(() =>
  import("./pages/OrganizationSection/pages/AdminPage/AdminPage")
);
const GuardianPage = lazy(() =>
  import("./pages/OrganizationSection/pages/GuardianPage/GuardianPage")
);
const NoticeBoard = lazy(() =>
  import("./pages/OrganizationSection/pages/Noticeboard/NoticeBoard")
);
const AllStudents = lazy(() =>
  import(
    "./pages/OrganizationSection/pages/StudentDetails/AllStudents/AllStudents"
  )
);
const StudentPage = lazy(() =>
  import("./pages/OrganizationSection/pages/StudentPage/StudentPage")
);
const AllTeachers = lazy(() =>
  import(
    "./pages/OrganizationSection/pages/TeacherPage/AllTeachers/AllTeachers"
  )
);
const TeacherPage = lazy(() =>
  import("./pages/OrganizationSection/pages/TeacherPage/TeacherPage")
);
const AllSubjects = lazy(() =>
  import("./pages/OrganizationSection/pages/OrgLanding/Classes/AllSubjects")
);
const OrgSettings = lazy(() =>
  import("./pages/OrganizationSection/pages/Settings/OrgSettings")
);
// Forms;
const FormDashboard = lazy(() =>
  import("./pages/FormPages/Dashboard/FormDashboard")
);
const FormsLayout = lazy(() => import("./pages/FormPages/FormsLayout"));
const FormEditPage = lazy(() =>
  import("./pages/FormPages/FormEditPage/FormEditPage")
);
const FormPreviewPage = lazy(() =>
  import("./pages/FormPages/FormPreviewPage/FormPreviewPage")
);
const TeachersResponse = lazy(() =>
  import("./pages/FormPages/ReportPage/TeachersResponse")
);
const UserResponsePage = lazy(() =>
  import("./pages/FormPages/UserResponsePage/UserResponsePage")
);
const FormViewPage = lazy(() =>
  import("./pages/FormPages/FormViewPage/FormViewPage")
);
const FormAnswerPage = lazy(() =>
  import("./pages/FormPages/FormAnswerPage/FormAnswerPage")
);
const FormSettingsPage = lazy(() =>
  import("./pages/FormPages/FormSettingsPage/FormSettingsPage")
);
// Moderator
const OverviewPage = lazy(() =>
  import("./pages/ModeratorSection/pages/OverviewPage")
);
const ModeratorClassPage = lazy(() =>
  import("./pages/ModeratorSection/pages/ModeratorClassPage")
);
const ModeratorSubjectPage = lazy(() =>
  import("./pages/ModeratorSection/pages/ModeratorSubjectPage")
);
const ModeratorChapterPage = lazy(() =>
  import("./pages/ModeratorSection/pages/ModeratorChapterPage")
);
const ModeratorMaterialPage = lazy(() =>
  import("./pages/ModeratorSection/pages/ModeratorMaterialPage")
);
const ModeratorBookPage = lazy(() =>
  import("./pages/ModeratorSection/pages/ModeratorBookPage")
);
const ModeratorUsersPage = lazy(() =>
  import("./pages/ModeratorSection/pages/ModeratorUsersPage")
);
const ModeratorCoursesPage = lazy(() =>
  import("./pages/ModeratorSection/pages/ModeratorCoursesPage")
);
const ModeratorQuizPage = lazy(() =>
  import("./pages/ModeratorSection/pages/ModeratorQuizPage")
);
const ModeratorScholarshipPage = lazy(() =>
  import("./pages/ModeratorSection/pages/ModeratorScholarshipPage")
);
const ModeratorDonorPage = lazy(() =>
  import("./pages/ModeratorSection/pages/ModeratorDonorPage")
);
const ModeratorSettingsPage = lazy(() =>
  import("./pages/ModeratorSection/pages/ModeratorSettingsPage")
);
const ModeratorFeedbacksPage = lazy(() =>
  import("./pages/ModeratorSection/pages/ModeratorFeedbacksPage")
);
const ScrollToTop = lazy(() =>
  import("./pages/CommonSection/ScrellToTop/ScrollToTop")
);
const FormReviewPage = lazy(() =>
  import("./pages/FormPages/FormReview/FormReviewPage")
);
const ScholarshipLandingPage = lazy(() =>
  import("./pages/CommonSection/ScholarshipLandingPage/ScholarshipLandingPage")
);
const ScholarshipCreatePage = lazy(() =>
  import(
    "./pages/DonorSection/pages/ScholarshipCreateUpdatePage/ScholarshipCreatePage"
  )
);
const ScholarshipUpdatePage = lazy(() =>
  import(
    "./pages/DonorSection/pages/ScholarshipCreateUpdatePage/ScholarshipUpdatePage"
  )
);
const ApplyToScholarship = lazy(() =>
  import("./pages/CommonSection/ApplyToScholarship/ApplyToScholarship")
);
const ScholarshipDetailsPage = lazy(() =>
  import(
    "./pages/DonorSection/pages/ScholarshipDetailsPage/ScholarshipDetailsPage"
  )
);
const ScholarshipPaymentPage = lazy(() =>
  import(
    "./pages/DonorSection/pages/ScholarshipPaymentPage/ScholarshipPaymentPage"
  )
);
const DonorAbout = lazy(() =>
  import("./pages/DonorSection/pages/DonorAboutPage/DonorAbout")
);
const DonorSetting = lazy(() =>
  import("./pages/DonorSection/pages/DonorSettings/DonorSetting")
);
const OpenScholarshipPage = lazy(() =>
  import("./pages/CommonSection/OpenScholarshipPage/OpenScholarshipPage")
);

// OpenScholarshipPage
const ScholarshipStudentDetails = lazy(() =>
  import(
    "./pages/DonorSection/pages/ScholarshipDetailsPage/sc_component/Details/ScholarshipStudentDetails"
  )
);

// Page
const IeltsLandingPage = lazy(() => import("./pages/ielts/IeltsLandingPage.js"));
const FreeLearning = lazy(() => import("./pages/FreeLearning/FreeLearning"));
const FreeWebiner = lazy(() => import("./pages/FreeWebiner/FreeWebiner"));
const StudentDashboard = lazy(() => import("./pages/StudentSection/Dashboard/StudentDashboard.jsx"));
const Exam = lazy(() => import("./pages/StudentSection/Dashboard/pages/Exam/Exam.jsx"));
const LederBoard = lazy(() => import("./pages/StudentSection/Dashboard/pages/lederBoard/LederBoard.jsx"));
const ExamResult = lazy(() => import("./pages/StudentSection/Dashboard/pages/ExamResult/ExamResult.jsx"));
const MyClass = lazy(() => import("./pages/StudentSection/Dashboard/pages/MyClass/MyClass.jsx"));
const CourseBranding = lazy(() => import("./pages/CourseBranding/CourseBranding.jsx"));


function App() {
  return (
    <div className="App">
      <Router>
        <Suspense fallback={<Loading />}>
          <TrackPageView />
          <ScrollToTop />
          <Routes>
            {/* Common Routes */}
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/courses" element={<Course />} />
            {/* <Route exact path="/course/:id" element={<CourseDetails />} /> */}
            <Route exact path="/about" element={<LandingPage />} />
            <Route exact path="/faq" element={<LandingPage />} />
            <Route exact path="/hscmodeltest" element={<QuizLandingPage />} />

            {/* course branding and & purchase page*/}
            <Route exact path="/courseBranding" element={<CourseBranding />} />
            <Route exact path="/courseBranding/workshopPurchase" element={<SpeakingWorkshopPurchase />} />

            <Route exact path="/advanceCourse" element={<AdvanceCourse />} />
            <Route exact path="/advanceCourse/advancePurchase" element={<AdvancePurchase />} />

            <Route exact path="/speakingCourse" element={<CrashCourse />} />
            <Route exact path="/speakingCourse/speakingPurchase" element={<SpeakingPurchasePage />} />

            <Route exact path="/freeIeltsPurchase" element={<FreeIeltsPurchase />} />

            <Route exact path="/hsc24-physics-suggestion-p1" element={<PhysicsSmartHSC24 />} />
            <Route exact path="/hsc24-physics-suggestion-p1/hsc24Physics1stPurchase" element={<HSC24PhysicsPurchasePage />} />

            <Route exact path="/ielts-writing-intensive" element={<IELTSWritingIntensive />} />
            <Route exact path="/ielts-writing-intensive/ieltsWritingIntensivePurchase" element={<IELTSWritingIntensivePurchasePage />} />



            <Route exact path="/purchase" element={<PurchasePage />} />
            <Route exact path="/thankyou/:transactionId" element={<CheckPayment>
              <ThankYouPage />
            </CheckPayment>} />
            <Route exact path="/decline" element={<DeclinePage />} />
            <Route exact path="/orderHistory" element={<OrderHistory />} />

            {/* Admin */}
            <Route exact path="/admin" element={<RequireAdmin><AdminDashboard />
            </RequireAdmin>} >
              <Route exact path="/admin" element={<Dashboard />} />
              <Route exact path="/admin/orders" element={<Orders />} />
            </Route>

            <Route exact path="/studentDash" element={<CheckEnrollment>
              <StudentDashboard />
            </CheckEnrollment>}>
              <Route exact path="/studentDash/quizexam" element={<QuizExam />} />
              <Route exact path="/studentDash/myClass" element={<MyClass />} />
              <Route exact path="/studentDash/leaderBoard" element={<LederBoard />} />
              <Route exact path="/studentDash/exam/:uuid" element={<Exam />} />
              <Route exact path="/studentDash/exam-result/:uuid" element={<ExamResult />} />
            </Route>

            <Route exact path="/contact" element={<LandingPage />} />

            <Route path="/auth/registration" element={<RegistrationPage />} />
            <Route
              path="/auth/registration/:role"
              element={<RegistrationPage />}
            />
            <Route path="/auth/login" element={<LoginPage />} />
            <Route path="/auth/reset-password" element={<ResetPassword />} />
            <Route path="/demo" element={<DemoPage />} />
            {/* <Route path="/courses/:subject" element={<CoursesPage />} /> */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/feedback" element={<FeedbackPage />} />
            <Route path="/team" element={<TeamPage />} />
            <Route path="/career" element={<CareerPage />} />
            <Route path="/career/:job_slug" element={<JobDetailsPage />} />
            <Route path="/career/:job_slug/apply" element={<JobApplyPage />} />

            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/my-courses" element={<MyCoursesPage />} />
            <Route path="/course/:courseId" element={<CourseDetailsPage />} />
            <Route
              path="/course/:courseId/:chapterId"
              element={<ChapterDetails />}
            />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/:username" element={<ProfilePage />} />

            {/* Form Routes */}
            <Route path="/forms" element={<FormDashboard />} />
            <Route path="/forms/view/:formId" element={<FormViewPage />} />
            <Route
              path="/forms/review/:formId/:userId"
              element={<FormReviewPage />}
            />
            <Route
              path="/forms/response/:formId/:userId"
              element={<UserResponsePage />}
            />
            <Route
              path="/forms/response/:formId"
              element={<TeachersResponse />}
            />
            <Route path="/forms/answer/:formId" element={<FormAnswerPage />} />
            <Route path="/forms/action" element={<FormsLayout />}>
              <Route path="edit/:formId" element={<FormEditPage />} />
              <Route path="preview/:formId" element={<FormPreviewPage />} />
              <Route path="settings/:formId" element={<FormSettingsPage />} />
            </Route>

            {/* Moderator Routes */}
            <Route path="/moderator" element={<DashboardPage />}>
              <Route index element={<OverviewPage />} />
              <Route path="classes" element={<ModeratorClassPage />} />
              <Route path="subjects" element={<ModeratorSubjectPage />} />

              <Route path="chapters" element={<ModeratorChapterPage />} />
              <Route path="materials" element={<ModeratorMaterialPage />} />
              <Route path="books" element={<ModeratorBookPage />} />
              <Route path="quizzes" element={<ModeratorQuizPage />} />
              <Route path="users" element={<ModeratorUsersPage />} />
              <Route path="courses" element={<ModeratorCoursesPage />} />
              <Route
                path="scholarships"
                element={<ModeratorScholarshipPage />}
              />
              <Route path="career" element={<ModeratorCareerPage />} />
              <Route path="career/all" element={<AllCareer />} />
              <Route path="career/update/:id" element={<UpdateCareer />} />
              <Route path="career/create" element={<CreateCareer />} />
              <Route path="donors" element={<ModeratorDonorPage />} />
              <Route path="settings" element={<ModeratorSettingsPage />} />
              <Route path="feedbacks" element={<ModeratorFeedbacksPage />} />
            </Route>









            {/* Organization Routes */}
            <Route path="/organization" element={<DashboardPage />}>
              <Route index element={<AdminPage />} />
              {/* Dashboard */}
              <Route path="dashboard/teachers" element={<TeacherPage />} />
              <Route path="dashboard/students" element={<StudentPage />} />
              <Route path="dashboard/guardians" element={<GuardianPage />} />
              {/* all Student just table */}
              <Route path="student/all-students" element={<AllStudents />} />
              {/* all Teacher jsut table list of teachers */}
              <Route path="teacher/all-teachers" element={<AllTeachers />} />
              {/* Guardian jsut table list of gurdian */}
              <Route path="guardian/all-guardians" element={<GuardianPage />} />
              {/* Notice */}
              <Route path="notice/all-notices" element={<NoticeBoard />} />
              {/* Account just table and list*/}
              <Route path="account/all-users" element={<AllUsers />} />
              {/* Account just table and list*/}
              <Route path="dashboard/allsubjects" element={<AllSubjects />} />
              {/* settings for org */}
              <Route path="dashboard/settings" element={<OrgSettings />} />
            </Route>

            {/* Scholarship Routes */}
            <Route path="/scholarship" element={<ScholarshipLandingPage />} />
            <Route path="/ielts" element={<IeltsLandingPage />} />
            <Route path="/ielts/freelearning" element={<FreeLearning />} />
            <Route path="/ielts/freewebiner/:id" element={<FreeWebiner />} />
            <Route path="/ielts/:chapter_name" element={<IeltsLandingPage />} />
            {/* <Route path="/dashboard" element={<DonorDashboardPage />} /> */}
            <Route
              path="/scholarship/:scholarshipId/apply"
              element={<ApplyToScholarship />}
            />
            <Route
              path="/scholarship/create"
              element={<ScholarshipCreatePage />}
            />
            <Route path="/scholarship/open" element={<OpenScholarshipPage />} />
            <Route
              path="/scholarship/:scholarshipId"
              element={<ScholarshipDetailsPage />}
            />
            <Route
              path="/scholarship/:scholarshipId/update"
              element={<ScholarshipUpdatePage />}
            />
            <Route
              path="/scholarship/:scholarshipId/apply"
              element={<ApplyToScholarship />}
            />
            <Route
              path="/scholarship/payment/details/:scholarshipStudentId"
              element={<ScholarshipPaymentPage />}
            />
            <Route
              path="/scholarship/apply/details/:studentId"
              element={<ScholarshipStudentDetails />}
            />
            <Route path="/scholarship/about" element={<DonorAbout />} />
            <Route path="/scholarship/settings" element={<DonorSetting />} />
            <Route path="/course-content/:courseId" element={<CheckCourseAccess>
              <CourseContentShow /></CheckCourseAccess>} />
          </Routes>
        </Suspense>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;

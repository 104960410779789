import "./Hero.css"
import logo from '../../../../media/images/logo.png'
import JumpingButton from "../JumpingButton/JumpingButton";

export default function Hero() {
    return (
        <div className='py-10 flex justify-center items-center flex-col text-[#001B36c] text-center'>
            <img src={logo} alt="logo" className=' md:w-72 sm:w-64 w-40 md:mb-10 mb-5' />
            <h1 className=' md:text-5xl sm:text-4xl text-2xl font-bold'>পদার্থবিজ্ঞান ১ম পত্রে বোর্ডে সর্বোচ্চ নম্বর নিশ্চিত করতে চান!</h1>
            <p className=' sm:text-3xl text-xl sm:py-5 py-2 font-medium'>আপনি কি জানতে চান কিভাবে?</p>
            <p className=' sm:mb-10 mb-5 px-5'>এইচ এস সি পরীক্ষার এক মাস পূর্বে পরিপূর্ণ প্রস্তুতির সঠিক গাইডলাইন পেতে বুক করুন আমাদের স্মার্ট সাজেশন ক্লাস এবং নিচিন্তভাবে A+ পাওয়ার পথ সুগম করুন।</p>
            <JumpingButton path='/hsc24-physics-suggestion-p1/hsc24Physics1stPurchase' />
        </div>
    )
}

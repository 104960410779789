import QuizTableRow from "./QuizTableRow";
import TableHeader from "./TableHeader";


export default function QuizTable({ title, data }) {
    return (
        <div className="mb-6 ">
            <TableHeader text={title} />
            <div className="overflow-x-auto no-scrollbar min-w-full">
                <div className=" min-w-[900px] flex items-center gap-2 my-2">
                    <div className=" w-[13%] bg-white py-3 text-black font-semibold flex items-center justify-center">
                        মডেল টেস্ট
                    </div>
                    <div className=" w-[30%] bg-white py-3 text-black font-semibold flex items-center justify-center">
                        সিলেবাস
                    </div>
                    <div className=" w-[17%] bg-white py-3 text-black font-semibold flex items-center justify-center">
                        তারিখ
                    </div>
                    <div className=" w-[17%] bg-white py-3 text-black font-semibold flex items-center justify-center">
                        সময়
                    </div>
                    <div className=" w-[20%] bg-white py-3 text-black font-semibold flex items-center justify-center">
                        একশন
                    </div>
                </div>
                <div className=" min-w-[900px] ">
                    {data?.map(singleData => <QuizTableRow data={singleData} />)}
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import PurchaseHeader from '../CommonSection/PurchasePage/components/PurchaseHeader'
import TitleBar from '../CommonSection/PurchasePage/components/TitleBar'
import Footer from '../QuizPages/Components/Footer'
import newlogo from '../../media/images/purchase/newlogo.png'
import IELTSWritingIntensivePurchaseBody from './IELTSWritingIntensivePurchaseBody'
import Navbar from '../../components/Navbar/Navbar'

export default function IELTSWritingIntensivePurchasePage() {
	return (
		<div className=" bg-white">
			<div className="sm:block hidden">
				<Navbar />
			</div>
			<div className="border-b py-10 sm:hidden">
				<img src={newlogo} alt="logo" className="w-1/3 mx-auto" />
			</div>
			<div className=" sm:pt-14 pt-3 text-[#001B36]">
				<PurchaseHeader />
				<div className="sm:block hidden">
					<TitleBar title='Complete Payment' />
				</div>
				<IELTSWritingIntensivePurchaseBody />
			</div>
			<Footer />
		</div>
	)
}

import axios from "axios";
import { BASE_URL } from "../utils/urls";

// export const getAllCourses = (data) => {
//   return axios({
//     method: "get",
//     url: GET_ALL_COURSES,
//     headers: {
//       "Content-Type": "application/json",
//       "x-auth-token": data.token,
//     },
//   });
// };

export const paymentRequest = ({ data }) => {
  return axios({
    method: "post",
    url: `${BASE_URL}/payment/payment-request`,
    headers: {
      "Content-Type": "application/json"
    },
    data
  });
};

export const freeCoursePurchase = ({ data }) => {
  return axios({
    method: "post",
    url: `${BASE_URL}/payment/free-course-purchase`,
    headers: {
      "Content-Type": "application/json"
    },
    data
  });
};

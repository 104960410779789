/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player';
import Navbar from '../../../components/Navbar/Navbar';
import { addWatchVideoSerial, getCourseContentByCourseId, getLastWatchVideo } from '../../../api/course.api';
import { loadStorage } from '../../../utils/persistLocalStorage';
import { useParams } from 'react-router-dom';
import Loading from '../../../components/loading/Loading';
import { FaCircleCheck, FaLock } from 'react-icons/fa6';
import ProgressBar from './ProgressBar';

export default function CourseContent() {

  const { courseId } = useParams();
  var user = loadStorage("user");
  const [course, setCourse] = useState({});
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(true);

  // course access state & api call
  const [lastWatch, setLastWatch] = useState(1);
  const [progress, setProgress] = useState(0);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    courseContentData();
  }, [])

  const courseContentData = () => {
    setLoading(true);
    getCourseContentByCourseId({
      courseId: courseId,
      token: user?.token
    })
      .then((res) => {
        setCourse(res?.data?.data?.course);
        setChapters(res?.data?.data?.chapters);
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    lastWatchVideoData();
  }, [refetch]);

  const lastWatchVideoData = () => {
    getLastWatchVideo({
      courseId,
      token: user?.token
    })
      .then((res) => {
        const serialNumber = res?.data?.data?.last_watch_video?.chapter_x_material?.material_serial;
        const progress = res?.data?.data?.progress;
        if (serialNumber) {
          setLastWatch(serialNumber);
        }
        if (progress) {
          setProgress(progress);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const navRef = useRef(null);
  const [selectedChapterIndex, setSelectedChapterIndex] = useState(null);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);

  useEffect(() => {
    courseContentData();
  }, []);

  const selectChapter = (chapterIndex) => {
    if (chapters[chapterIndex]?.videos?.length === 0) return;
    if (chapters[chapterIndex]?.videos[0]?.serial > lastWatch) {
      setSelectedChapterIndex(selectedChapterIndex === chapterIndex ? null : chapterIndex);
      setSelectedVideoIndex(null);
      return
    };
    if (chapters[chapterIndex]?.videos[0]?.serial === 1 && lastWatch === 1) {
      addWatchVideoSerial({
        token: user?.token,
        chapter_x_material_id: chapters[0]?.videos[0]?.id
      })
        .then(res => {
          setRefetch(!refetch);
        })
        .catch(err => {
          console.log(err)
        })
    }
    if (selectedChapterIndex === chapterIndex) {
      setSelectedChapterIndex(null);
      setSelectedVideoIndex(null);
    } else {
      setSelectedChapterIndex(chapterIndex);
      setSelectedVideoIndex(0);
    }
  };

  const selectVideo = (videoIndex, serial) => {
    if (lastWatch >= serial) {
      setSelectedVideoIndex(videoIndex);
    }
  };

  const handlePrevious = () => {
    if (selectedVideoIndex > 0) {
      setSelectedVideoIndex(selectedVideoIndex - 1);
    } else if (selectedChapterIndex > 0) {
      if (chapters[selectedChapterIndex - 1]?.videos?.length === 0) return;
      setSelectedChapterIndex(selectedChapterIndex - 1);
      setSelectedVideoIndex(chapters[selectedChapterIndex - 1].videos.length - 1);
    }
  };

  const handleNext = () => {
    const currentChapter = chapters[selectedChapterIndex];
    if (selectedVideoIndex < currentChapter.videos.length - 1) {

      const nextWatchSerial = chapters[selectedChapterIndex]?.videos[selectedVideoIndex + 1]?.serial;

      if (nextWatchSerial < lastWatch) {
        return setSelectedVideoIndex(selectedVideoIndex + 1);
      }

      addWatchVideoSerial({
        token: user?.token,
        chapter_x_material_id: chapters[selectedChapterIndex]?.videos[selectedVideoIndex + 1]?.id
      })
        .then(res => {
          setRefetch(!refetch);
          setSelectedVideoIndex(selectedVideoIndex + 1);
        })
        .catch(err => {
          console.log(err)
        })
    } else if (selectedChapterIndex < chapters.length - 1) {
      if (chapters[selectedChapterIndex + 1]?.videos?.length === 0) return;

      const nextWatchSerial = chapters[selectedChapterIndex + 1]?.videos[0]?.serial;

      if (nextWatchSerial < lastWatch) {
        setSelectedChapterIndex(selectedChapterIndex + 1);
        setSelectedVideoIndex(0);
        return;
      }

      addWatchVideoSerial({
        token: user?.token,
        chapter_x_material_id: chapters[selectedChapterIndex + 1]?.videos[0]?.id
      })
        .then(res => {
          setRefetch(!refetch);
          setSelectedChapterIndex(selectedChapterIndex + 1);
          setSelectedVideoIndex(0);
        })
        .catch(err => {
          console.log(err)
        })
    }
  };

  return (loading ? <Loading /> :
    <div>
      <Navbar />
      <div className="lg:flex px-5 sm:mt-0 mt-20">
        <div className="xl:w-96 lg:w-72 w-full lg:h-[92vh] h-96 overflow-y-auto pb-5 lg:pb-0 px-5 lg:px-0 lg:fixed lg:bottom-0  left-0 bg-white border overflow-auto lg:rounded-tr-2xl rounded-b-xl">
          <div className='px-4 pb-2'>
            <ProgressBar percentage={progress} />
            <p className='mt-2 text-lg font-medium text-center'>Completed {progress}%</p>
          </div>
          <hr />
          <h1 className="text-xl font-medium text-center mt-7 px-2">{course?.course_title}</h1>
          <div className="mt-5">
            {chapters?.length !== 0 ?
              chapters?.map((singleChapter, chapterIndex) => (
                <div className='px-3 py-1' key={chapterIndex}>
                  <div
                    className={`bg-gray-100 p-4 transition-all ease-in-out duration-500 cursor-pointer text-lg font-medium rounded-lg ${selectedChapterIndex === chapterIndex && '!bg-blue-400 text-white'}`}
                    onClick={() => selectChapter(chapterIndex)}>
                    {singleChapter?.chapter?.chapter_name}
                  </div>
                  <div
                    className={`overflow-hidden transition-all ease-in-out duration-500`}
                    style={{
                      height: selectedChapterIndex === chapterIndex ? 'auto' : '0',
                      transition: 'height 0.5s ease-in-out'
                    }}
                  >
                    <ul ref={navRef} className="pl-2">
                      {singleChapter?.videos.map((video, videoIndex) => (
                        <li
                          key={videoIndex}
                          className={`bg-gray-100 p-3 my-1 cursor-pointer rounded-md ${selectedVideoIndex === videoIndex && '!bg-blue-300 text-white'
                            }`}
                          onClick={() => selectVideo(videoIndex, video?.serial)}
                        >
                          <div className='flex items-center gap-2'>
                            {lastWatch < video?.serial ?
                              <FaLock className='text-lg text-red-500' />
                              :
                              <FaCircleCheck className='text-lg text-green-500' />}
                            <p>{video?.title}</p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))
              :
              <p className='text-center text-red-500'>course content N/A</p>
            }
          </div>
        </div>
        <div className="lg:flex-1 h-screen xl:pl-[26rem] lg:pl-80 pt-[8vh] w-full">
          {selectedChapterIndex !== null && selectedVideoIndex !== null ? (
            <div className="bg-white border lg:rounded-tl-2xl rounded-xl w-full h-full flex flex-col items-center justify-center">
              <ReactPlayer url={chapters[selectedChapterIndex]?.videos[selectedVideoIndex]?.url} controls width="80%" height="75%" />
              <div className="flex gap-5 mt-8">
                <button onClick={handlePrevious} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-l">
                  Previous
                </button>
                <button onClick={handleNext} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r">
                  Next
                </button>
              </div>
            </div>
          ) : (
            <div className="bg-white border lg:rounded-tl-2xl rounded-xl w-full h-full flex items-center justify-center">
              <p className="text-lg font-medium">Please select and start your class</p>{' '}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

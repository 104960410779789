import { FaMinus, FaPlus } from "react-icons/fa6";
import QuizTitle from "./QuizTitle";
import { useState } from "react";


export default function QuizFaq() {
    const [open, setOpen] = useState(1)
    const faqsData = [
        {
            id: 1,
            title: 'এই কোর্সে কী কী আছে?',
            text: 'HSC পরীক্ষার্থীদের জন্য বোর্ড স্ট্যান্ডার্ড MCQ প্রশ্নে পরীক্ষা দেওয়ার সুযোগ।অধ্যায়ভিত্তিক সকল ধরনের MCQ প্রশ্নের ধারনা এবং তা উত্তরের কার্যকারী দিকনির্দেশনা।সল্ভ ক্লাসের মাধ্যমে HSC 2024 এ  MCQ তে সর্বোচ্চ মার্ক প্রাপ্তির প্রচেষ্টা।'
        },
        {
            id: 2,
            title: 'কোন কোন বিষয় পড়ানো হবে?',
            text: 'HSC 2024 সিলেবাস অনুযায়ী পদার্থবিজ্ঞান,রসায়ন,জীববিজ্ঞান ও উচ্চতর গনিত। '
        },
        {
            id: 3,
            title: 'অফলাইন কিংবা ভিডিও ডাউনলোড করার কোনো অপশন আছে কিনা?',
            text: 'সল্ভ ক্লাস এর রেকর্ড শিক্ষার্থীদের দেয়া হবে এবং তারা ডাউনলোড করার  অপশন পাবেন।'
        },
        {
            id: 4,
            title: 'কিভাবে এই কোর্সের যাবতীয় তথ্য ও ক্লাস লিংক পাব?',
            text: 'আমাদের হোয়াটসঅ্যাপ গ্রুপে আপনারা জয়েন করলে সেখানে কোর্সের যাবতীয় নোটিশ ও ক্লাস লিংক পাবেন।'
        },
        {
            id: 5,
            title: 'ক্লাস ও পরীক্ষা সংক্রান্ত প্রশ্নসমূহ',
            text: 'ক্লাস গুগোল মিটের মাধ্যমে হবে এবং এর লিংক হোয়াটসঅ্যাপ গ্রুপে পাবেন এবং পরীক্ষা ওয়েবসাইটে নেওয়া হবে।'
        }
    ]
    return (
        <div className=' pb-5'>
            <QuizTitle title='সচরাচর জিজ্ঞাসা' text='আমাদের কোর্স সম্পর্কে আপনার যেকোনো জিজ্ঞাসার উত্তর এখানে দেখুন' />
            <div className="container 2xl:px-20 xl:px-10 sm:px-5 px-2 mx-auto py-6 ">
                <div className=" mx-auto 2xl:w-6/12 xl:w-7/12 lg:w-8/12 sm:w-10/12 w-full relative ">
                    {
                        faqsData?.map(faq => <div key={faq?.id}>
                            <div onClick={() => setOpen(open === faq?.id ? null : faq?.id)} className={`flex items-start justify-between p-4 pb-3 cursor-pointer border border-[#044884]  ${open === faq?.id ? 'rounded rounded-b-none' : 'rounded'}`}>
                                <div className=" flex gap-2 items-start">
                                    <div className=" w-3 h-3 rounded-full bg-[#044884] mt-1"></div>
                                    <p className=" text-[#001B36] font-lg font-medium">{faq?.title}</p>
                                </div>
                                <div>
                                    <FaPlus />
                                </div>
                            </div>

                            <div className={`${open === faq?.id ? 'visible relative h-auto mt-0 opacity-100' : 'invisible  h-0 w-full left-0 -mt-[34px] opacity-0 z-[-100]'} flex items-center gap-3 border border-[#044884]  border-t-0 p-4 transition-all duration-200 ease-linear rounded-b`}>
                                <FaMinus className=" text-[#044884] text-2xl" />
                                <p className={`text-[#001B36] ${open === faq?.id ? 'block' : 'hidden'}`}>{faq?.text}</p>
                            </div>
                        </div>)
                    }
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import { CiDiscount1 } from 'react-icons/ci';
import { HiOutlineShoppingBag } from 'react-icons/hi';
import { IoHelpCircleOutline, IoSettingsOutline, IoWalletOutline } from 'react-icons/io5';
import { LiaUser } from 'react-icons/lia';
import { PiPackageLight } from 'react-icons/pi';
import { RiDashboardFill } from 'react-icons/ri';
import { Link, useLocation } from 'react-router-dom';

export default function AdminMenu({ openMenu }) {
    const location = useLocation();
    const currentPathname = location.pathname;
    const menuList = [
        {
            name: 'GENERAL',
            item: [
                {
                    id: 1,
                    name: 'Dashboard',
                    link: '/admin',
                    icon: <RiDashboardFill />,
                    activeUrl: ['/admin']
                },
                {
                    id: 2,
                    name: 'Products',
                    link: '/admin/product',
                    icon: <PiPackageLight />,
                    activeUrl: ['/admin/product']
                },
                {
                    id: 3,
                    name: 'Orders',
                    link: '/admin/orders',
                    icon: <HiOutlineShoppingBag />,
                    activeUrl: ['/admin/orders']
                },
                {
                    id: 4,
                    name: 'Customers',
                    link: '/admin/customers',
                    icon: <LiaUser />,
                    activeUrl: ['/admin/customers']
                },
                {
                    id: 5,
                    name: 'Payment',
                    link: '/admin/payment',
                    icon: <IoWalletOutline />,
                    activeUrl: ['/admin/payment']
                },
                {
                    id: 6,
                    name: 'Coupon',
                    link: '/admin/coupon',
                    icon: <CiDiscount1 />,
                    activeUrl: ['/admin/coupon']
                },
            ]
        },
        {
            name: 'SUPPORT',
            item: [
                {
                    id: 1,
                    name: 'Settings',
                    link: '/admin/settings',
                    icon: <IoSettingsOutline />,
                    activeUrl: ['/admin/settings']
                },
                {
                    id: 2,
                    name: 'Help',
                    link: '/admin/help',
                    icon: <IoHelpCircleOutline />,
                    activeUrl: ['/admin/help']
                }
            ]
        }

    ]

    return (
        <div className="p-5 pt-8">
            {menuList?.map((menuSection) => (
                <div key={menuSection.name} className='mt-5'>
                    <div className={`text-sm mb-2 text-[#838990] font-semibold transition-all ease-linear duration-500 ${openMenu ? 'pl-7' : 'pl-0'}`}>{menuSection.name}</div>
                    {menuSection.item.map((menuItem) => (
                        <Link
                            to={menuItem.link}
                            key={menuItem.id}
                            className={`rounded-lg py-1.5 text-md font-medium flex items-center gap-3 justify-start mt-3  overflow-hidden cursor-pointer ${menuItem.activeUrl.includes(currentPathname) ? ' text-black  hover:text-[#001B36]' : 'text-[#575757] hover:text-black '}`}>
                            <div className={`text-xl ${openMenu ? 'w-1/4 flex justify-end' : 'mx-auto'}`}>
                                {menuItem.icon}
                            </div>
                            <div className={`pl-3 absolute transition-all ease-linear duration-300 ${openMenu ? 'left-24 visible opacity-100' : 'invisible -left-32 opacity-0'}`}>
                                {menuItem.name}
                            </div>
                        </Link>
                    ))}
                </div>
            ))}
        </div>
    );

}

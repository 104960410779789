import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { defaultProfile, TSCDemoLogo } from "../../utils/images";
import { delStorage, loadStorage } from "../../utils/persistLocalStorage";
import NotificationCard from "../notificaion_card/NotificationCard";
import "./header.scss";

const DrawerContents = ({ user, logoutUser, isOpen, setIsOpen }) => (
  <div className="DrawerContents__Container">
    <div className="DrawerContents__Container__Header">
      {user ? (
        <>
          <Link
            to={"/" + user?.username}
            className="DrawerContents__Container__Header__Logo"
          >
            <img
              src={
                user?.profile_picture ? user?.profile_picture : defaultProfile
              }
              alt="profile"
            />

            <div className="name dropdown">
              <div className="name_text">
                {user ? user.first_name + " " + user.last_name : ""}
              </div>
              <div className="role_text">
                {user?.role?.is_admin
                  ? "Admin"
                  : user?.role?.is_teacher
                    ? "Teacher"
                    : user?.role?.is_student
                      ? "Student"
                      : "Unknown"}
              </div>
            </div>
          </Link>
        </>
      ) : null}
    </div>

    {user ? (
      <>
        <Link className="menu_drawer" to="/dashboard">
          <i className="fa-solid fa-house"></i>
          <div>Dashboard</div>
        </Link>
        {user?.role?.role_name === "student" ||
          user?.role?.role_name === "teacher" ? (
          <Link className="menu_drawer" to="/my-courses">
            <i className="fa-solid fa-bookmark"></i>
            <div>Courses</div>
          </Link>
        ) : null}

        {/* {user?.role.role_name === "student" ? (
          <Link className="menu_drawer" to="/scholarship/open">
            <i className="fa-solid fa-bookmark"></i>
            <div>Scholarship</div>
          </Link>
        ) : null} */}
        {/* {user?.role.role_name === "teacher" ? (
                    <Link className="menu_drawer" to="/forms">
                        <i className="fa-solid fa-bookmark"></i>
                        <div>Forms</div>
                    </Link>
                ) : null} */}
        <Link className="menu_drawer" to={"/ielts"}>
          <i className="fa-solid fa-bookmark"></i>
          <div>IELTS</div>
        </Link>
        <Link className="menu_drawer" to={"/" + user?.username}>
          <i className="fa-solid fa-user"></i>
          <div>Profile</div>
        </Link>
        <Link className="menu_drawer" to="/settings">
          <i className="fa-solid fa-gear"></i>
          <div>Settings</div>
        </Link>
        <div className="menu_drawer" onClick={logoutUser}>
          <i className="fa-solid fa-arrow-right-from-bracket"></i>
          <div>Logout</div>
        </div>
      </>
    ) : (
      <>
        <Link className="menu_drawer" to="/auth/login">
          <i className="fa-solid fa-user"></i>
          <div>Login</div>
        </Link>
        <Link className="menu_drawer" to="/auth/registration">
          <i className="fa-solid fa-gear"></i>
          <div>Sign Up</div>
        </Link>
      </>
    )}
  </div>
);

export const Drawer = ({ isOpen, toggleDrawer, user, logoutUser, setIsOpen }) => (
  <div className={`Drawer__Container ${isOpen && "Drawer__Container--isOpen"}`}>
    <DrawerContents
      user={user}
      logoutUser={logoutUser}
      setIsOpen={setIsOpen}
      isOpen={isOpen}
    />
  </div>
);

const Header = () => {
  var user = loadStorage("user");
  const navigate = useNavigate();
  const name = user ? `${user.first_name} ${user.last_name}` : "";
  const [open, setOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  const [openOption, setOpenOption] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  // const toggleDrawer = () => setIsOpen(!isOpen);

  useEffect(() => {
    user = loadStorage("user");
  }, []);

  //prevent scrolling when drawer is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "no-scroll";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  const logoutUser = () => {
    delStorage("user");
    navigate("/");
  };

  return (
    <div className="header">
      <div className="header_bg">
        <div className="header_container">
          <div className="header_left">
            <Link to="/">
              <img className="logo" src={TSCDemoLogo} alt="Logo" />
            </Link>

            {user?.role.role_name === "student" ||
              user?.role.role_name === "teacher" ? (
              <>
                <Link to="/dashboard" className="navLink">
                  <div className="navLink">Dashboard</div>
                </Link>
                <Link to="/my-courses" className="navLink">
                  <div className="navLink">Courses</div>
                </Link>
              </>
            ) : null}

            {user?.role.role_name === "student" ? (
              <>
                {/* <Link to="/scholarship/open" className="navLink">
                  <div className="navLink">Scholarship</div>
                </Link> */}
                <Link to="/ielts" className="navLink">
                  <div className="navLink">IELTS</div>
                </Link>
              </>
            ) : null}

            {/* {user?.role.role_name === "teacher" ? (
                            <Link to="/forms" className="navLink">
                                <div className="navLink">Forms</div>
                            </Link>
                        ) : null} */}
          </div>
          <div className="header_right">
            {user ? (
              <>
                {/* <div
									className="navLink notification"
									onClick={() =>
										setIsNotificationsOpen(
											!isNotificationsOpen
										)
									}
								>
									<i className="fa-solid fa-bell icon"></i>
								</div> */}
                <div className="header_profile_tile">
                  <div
                    className="header_profile_tile_Content"
                  // onClick={() => setOpenOption(!openOption)}
                  >
                    <div className="profile_pic">
                      <img
                        src={
                          user?.profile_picture
                            ? user?.profile_picture
                            : defaultProfile
                        }
                        alt="profile"
                        height={35}
                        width={35}
                      />
                    </div>
                    <div className="name dropdown">
                      <div className="name_text">
                        {user
                          ? name.length > 12
                            ? `${name.substring(0, 12)}...`
                            : name
                          : ""}
                      </div>
                      <div className="role_text">
                        {user?.role.is_admin
                          ? "Admin"
                          : user?.role.is_teacher
                            ? "Teacher"
                            : user?.role.is_student
                              ? "Student"
                              : "Unknown"}
                      </div>
                    </div>
                    <div
                      className="dropdown"
                      onClick={() => setOpenOption(!openOption)}
                    >
                      <div className="headerIcon">
                        {open ? (
                          <i
                            onClick={() => setOpen(false)}
                            className="fa-solid fa-chevron-up"
                          ></i>
                        ) : (
                          <i
                            onClick={() => setOpen(true)}
                            className="fa-solid fa-chevron-down"
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                  {openOption && (
                    <div className="header_profile_tile_options">
                      <div className="dropdown-content">
                        <Link className="menu_item" to={"/" + user.username}>
                          <i className="fa-solid fa-user"></i>
                          <div>Profile</div>
                        </Link>

                        <Link className="menu_item" to="/settings">
                          <i className="fa-solid fa-gear"></i>
                          <div>Settings</div>
                        </Link>

                        <div className="menu_item" onClick={logoutUser}>
                          <i className="fa-solid fa-arrow-right-from-bracket"></i>
                          <div>Logout</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Link to="/auth/login">
                    <button className="btn login_btn">Login</button>
                  </Link>
                </div>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Link to="/auth/registration">
                    <button className="btn signup_btn">Sign Up</button>
                  </Link>
                </div>
              </>
            )}
          </div>
          <div
            className="header_Burgar "
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <i className="fa-solid fa-bars"></i>
          </div>
        </div>
        <Drawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          user={user}
          logoutUser={logoutUser}
        />
        {/* {isNotificationsOpen && <NotificationCard />} */}
      </div>
    </div>
  );
};

export default Header;

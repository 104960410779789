import PurchaseImg from '../../../../media/images/purchase/start.png'
import PurchaseImg2 from '../../../../media/images/purchase/start2.png'

export default function PurchaseHeader() {
    return (
        <div className=" bg-white shadow mb-5 hidden sm:block" >
            <div className=" container mx-auto 2xl:px-20 px-5 py-7 md:flex items-center justify-between" >
                <h1 className=" text-2xl font-medium text-[#001B36]">Order Overview</h1>
                <div className=' sm:flex  items-center gap-1 flex-1 md:justify-end justify-start mt-5 md:mt-0 '>
                    <div className='flex items-center gap-1 w-fit'>
                        <img src={PurchaseImg} alt="PurchaseImg" className=' w-5 -mt-1' />
                        <p >Course Details</p>
                        <div className=' border border-dashed w-[40px]  sm:block hidden'></div>
                    </div>
                    <div className='flex items-center gap-1 py-3 sm:py-0'>
                        <img src={PurchaseImg} alt="PurchaseImg" className=' w-5 -mt-1' />
                        <p>Payment Details</p>
                        <div className=' border border-dashed w-[40px]  sm:block hidden'></div>
                    </div>
                    <div className='flex items-center gap-1'>
                        <img src={PurchaseImg2} alt="PurchaseImg2" className=' w-5 -mt-1' />
                        <p>Complete Order</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { LiaEdit } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import { getQuizResponseByUUIDAndUserID } from "../../../../../../api/exam.api";
import { loadStorage } from "../../../../../../utils/persistLocalStorage";
import { MdQuestionAnswer } from "react-icons/md";
import { GoEyeClosed } from "react-icons/go";
import live_exam from "../../../../../../media/images/red-dot.gif"
import { FaHourglassEnd } from "react-icons/fa";

export default function QuizTableRow({ data }) {

	const user = loadStorage("user");
	const navigate = useNavigate()
	const { title, description, exam_start_time } = data?.exam;
	const [examResponse, setExamResponse] = useState([]);
	
	const date = moment(exam_start_time).format(' Do MMMM YYYY')
	const time = moment(exam_start_time).format('h:mm: a');

	// Check infinity date or not
	const today = new Date();
	const examEndTime = new Date(data?.due_date_time);
	// Calculate the difference in days
	const daysDifference = Math.floor((examEndTime - today) / (24 * 60 * 60 * 1000));

	useEffect(() => {
		fetchQuizeResponse()
	}, [])

	const fetchQuizeResponse = async (uuid, token, id) => {

		await getQuizResponseByUUIDAndUserID({
			uuid: data?.uuid,
			userId: user?.id,
			token: user?.token
		})
			.then((res) => {
				setExamResponse(res?.data?.data?.exam?.responses);
			})
			.catch((err) => {
				// console.log(err?.response?.data?.error);
			});
	};

	return (
		<div className="flex items-center font-medium justify-between bg-white py-3 px-4  hover:bg-blue-50 mb-2 border border-gray-100 rounded">
			<h1 className="relative font-bold z-[0] w-[13%]">
				{daysDifference < 7 && new Date(data?.due_date_time) > new Date() && <img className="absolute -top-3 w-4" src={live_exam} alt="" />}
				{title}
			</h1>
			<div className="w-[30%] ">
				{description}
			</div>
			<h3 className="w-[17%] flex items-center justify-center">{date}</h3>
			<h3 className="w-[17%] flex items-center justify-center">{time}</h3>

			<div className=" w-[20%] flex items-center justify-end">
				{/* Exam Not Start */}
				{!data?.due_date_time &&
					<button className="flex items-center justify-center gap-1.5 bg-[#fe8553] border border-[#fe8553] hover:text-[#fe8553] hover:bg-transparent px-4 py-2 rounded-md text-white transition-all duration-200 ease-linear"><GoEyeClosed className=" text-lg" />Waiting</button>
				}

				{/* Normal Exam */}
				{data?.due_date_time && daysDifference > 7 &&
					(
						examResponse?.length === 0
							?
							<button onClick={() => navigate(`/studentDash/exam/${data?.uuid}`)} className="flex items-center justify-center gap-2 bg-[#044884] border border-[#044884] hover:text-[#044884] hover:bg-transparent px-4 py-2 rounded-md text-white transition-all duration-200 ease-linear"><LiaEdit className=" text-xl -mt-1" />Start Exam</button>
							:
							<button onClick={() => navigate(`/studentDash/exam-result/${data?.uuid}`)} className="flex items-center justify-center gap-1.5 bg-green-500 border border-green-500 hover:text-green-500 hover:bg-transparent px-4 py-2 rounded-md text-white transition-all duration-200 ease-linear"><MdQuestionAnswer className=" text-lg" />Result</button>
					)
				}

				{/* Live Exam Result*/}
				{data?.due_date_time && daysDifference < 7 &&
					(
						examResponse?.length === 0
							?
							<button className="flex items-center justify-center gap-1.5 bg-yellow-500 border border-yellow-500 hover:text-yellow-500 hover:bg-transparent px-4 py-2 rounded-md text-white transition-all duration-200 ease-linear"><FaHourglassEnd className="text-sm" />Exam End</button>
							:
							<button onClick={() => navigate(`/studentDash/exam-result/${data?.uuid}`)} className="flex items-center justify-center gap-1.5 bg-green-500 border border-green-500 hover:text-green-500 hover:bg-transparent px-4 py-2 rounded-md text-white transition-all duration-200 ease-linear"><MdQuestionAnswer className=" text-lg" />Result</button>
					)
				}
			</div>
		</div>
	)
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { HiArrowNarrowRight, HiDotsHorizontal } from "react-icons/hi";
import { Link } from "react-router-dom";
import { getDashboardStates } from "../../../api/order.api";
import { loadStorage } from "../../../utils/persistLocalStorage";

export default function OrderCard({ data }) {

    const user = loadStorage("user");
    const [states, setStates] = useState({});

    const { dateRange, startDate, endDate } = data;

    useEffect(() => {
        fetchDashboardStates()
    }, [dateRange, startDate, endDate])

    const fetchDashboardStates = async () => {

        await getDashboardStates({
            dateRange,
            token: user.token,
            startDate: new Date(startDate),
            endDate: new Date(endDate)
        })
            .then((res) => {
                setStates(res?.data?.data)
            })
            .catch((err) => {
                console.log(err)
            });
    };

    const orderData = [
        {
            id: 1,
            name: 'Total sales',
            amount: `${states?.totalSales ? states?.totalSales : '৳ 0'}`,
        },
        {
            id: 2,
            name: 'Total Orders',
            amount: `${states?.totalOrders ? states?.totalOrders : 0}`,
        },
        {
            id: 3,
            name: 'Total Customers',
            amount: `${states?.totalCustomers ? states?.totalCustomers : 0}`,
        },
        {
            id: 4,
            name: 'Total Products',
            amount: `${states?.totalProducts ? states?.totalProducts : 0}`,
        }
    ]

    return (
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-5 p-5 bg-[#F9FAFB] rounded mt-7">
            {
                orderData?.map(data => <div key={data?.id} className="bg-white hover:bg-[#F6FAFF] rounded-lg">
                    <div className=" px-5 pt-5 pb-12 text-[#001B36]">
                        <div className="flex items-center justify-between">
                            <h2 className="text-lg font-medium">{data?.name}</h2>
                            <HiDotsHorizontal />
                        </div>
                        <h1 className="text-4xl font-semibold mt-5">{data?.amount}</h1>
                    </div>
                    <div className="border-t border-b flex items-center justify-between px-5 py-3 rounded-b-lg">
                        <Link to={'/'} className="text-sm font-medium text-gray-700">View Report</Link>
                        <HiArrowNarrowRight />
                    </div>
                </div>)
            }
        </div>
    )
}

import { TiArrowRight } from 'react-icons/ti'
import successImg1 from '../../../../media/images/courseBranding/success1.png'
import successImg2 from '../../../../media/images/courseBranding/success2.png'
import { useNavigate } from 'react-router-dom'

export default function Success({ path }) {
    const navigate = useNavigate()
    return (
        <div className='conatiner 2xl:px-20 px-5 mx-auto sm:pt-14 pt-7'>
            <div className=" flex flex-col items-center justify-center gap-2.5 my-14 text-center">
                <h1 className=" lg:text-5xl text-4xl font-bold text-[#001B36]">Unlock Your Success </h1>
                <p className=" text-lg font-medium text-[#001B36] px-5">Here's What You Get:</p>
            </div>
            <div className='grid sm:grid-cols-2 gap-10'>

                <div className='flex justify-end'>
                    <div className='bg-white shadow-md lg:w-[27rem] rounded-2xl overflow-hidden border '>
                        <img src={successImg1} alt="successImg1" />
                        <div className='p-5'>
                            <p className='text-lg font-medium'>One-on-One</p>
                            <h2 className='text-[26px] font-bold text-[#044884] my-1'>Live Mock Test !</h2>
                            <p>Experience real exam conditions in live sessions. where expert teachers assess your IELTS speaking skills, Provide customized guidelines, and furnish you with a score. This awesome experience will help you do 2x better in your actual IELTS test.</p>
                        </div>
                    </div>
                </div>
                <div className='bg-white shadow-md lg:w-[27rem] rounded-2xl overflow-hidden border '>
                    <img src={successImg2} alt="successImg1" />
                    <div className='p-5'>
                        <p className='text-lg font-medium'>For IELTS Practice</p>
                        <h2 className='text-[26px] font-bold text-[#044884] my-1'>Get VIP Access !Z</h2>
                        <p>Join our workshop and get into an exclusive club! Practice IELTS speaking, reading, writing, and listening with a friendly community. This community will help you get high scores in the IELTS real exam.</p>
                    </div>
                </div>
            </div>
            <div className='flex justify-center pt-10 w-full'>
                <div className='wiggle'>
                    <button onClick={() => navigate(path)} className='flex items-center gap-2 px-5 py-1.5 rounded-md bg-[#FE8553] border border-[#FE8553] hover:text-[#FE8553] hover:bg-transparent transition-all duration-200 ease-linear font-medium text-white'>Book Your Seat <TiArrowRight className='text-xl' /></button>
                </div>
            </div>
        </div>

    )
}

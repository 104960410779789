import { useNavigate } from "react-router-dom";
import "./JumpingButton.css"
import { TiArrowRight } from "react-icons/ti";

const JumpingButton = ({ path }) => {
    const navigate = useNavigate()
    return (
        <div onClick={() => navigate(path && path)} className="bounceDown bounceLeft bounceRight">
            <button className="  flex items-center gap-2 px-5 py-1.5 rounded-md bg-[#FE8553] border border-[#FE8553] hover:text-[#FE8553] hover:bg-transparent transition-all duration-200 ease-linear font-medium text-white btn-jump-right">
                Book Your Seat
                <TiArrowRight className='text-xl' />
            </button>
        </div>
    );
};

export default JumpingButton;

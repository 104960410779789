import { useState } from 'react';
export default function Button({ text, icon, isFill, ...res }) {

    const [relY, setRelY] = useState(0);
    const [pageX, setPageX] = useState(0);
    const [isExploding, setIsExploding] = useState(false);

    const handleMouseEnter = (e) => {
        setIsExploding(true);
        setPageX(e.target.offsetLeft + 20);
        setRelY(e.target.offsetTop + 30);
    };

    const handleMouseLeave = (e) => {
        setIsExploding(false);
        setPageX(e.target.offsetLeft + 70);
        setRelY(e.target.offsetTop - 20);
    };

    const circleStyles = {
        position: 'absolute',
        left: `${pageX}px`,
        top: `${relY}px`,
        animation: isExploding ? 'explode 0.5s forwards' : 'desplode 0.5s forwards',
    };

    return (
        <div className=" overflow-hidden relative inline-block rounded-full ">
            <span
                style={circleStyles}
                className={`  bg-green-500 rounded-full  absolute top-0 left-0 w-0 h-0 pointer-events-none `}
            />
            <button
                className={` ${isFill ? "bg-orange-500 text-white" : "border border-orange-500  text-orange-500 hover:text-white"}      text-center inline-block  transition-all ease-linear duration-100 py-[6px] px-5 z-[100] rounded-full`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                {...res}
            >
                <span className="relative z-[10] font-medium flex items-center gap-1.5">
                    {icon}
                    {text}
                </span>
            </button>
        </div>
    );
}
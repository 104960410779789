

export default function CourseInfoCard({ course }) {
    const { icon, title, info } = course
    return (
        <div className=' border shadow-md p-3  rounded-lg hover:bg-gray-700 group  hover:text-white transition-all duration-300 ease-in-out cursor-pointer' >
            <div className=' h-10 w-10 rounded-lg bg-gray-200 flex items-center justify-center mb-3 mx-auto text-black'>
                {icon}
            </div>
            <div className='  font-medium text-center'> <span className=' '>{title}</span> <hr className=' mt-2' /> <h1 className=' mt-2 text-gray-500 group-hover:text-orange-400'>{info}</h1></div>
        </div>
    )
}

import { useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../../../utils/urls";

const CheckPayment = ({ children }) => {

  const { transactionId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const checkPayment = async () => {
      try {

        await axios.get(`${BASE_URL}/payment/payment-details/${transactionId}`);

      } catch (error) {
        return navigate("/decline");
      }
    };

    checkPayment();
  }, [transactionId, navigate]);

  return children;
};

export default CheckPayment;

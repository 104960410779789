import { TiArrowRight } from 'react-icons/ti'
import successImg1 from '../../../../media/images/hsc24PhysicsSmartPreparation/রেকর্ড ক্লাস .jpg'
import successImg2 from '../../../../media/images/hsc24PhysicsSmartPreparation/গাইড লাইন .jpg'
import successImg3 from '../../../../media/images/hsc24PhysicsSmartPreparation/smart পড়াশোনা .jpg'
import successImg4 from '../../../../media/images/hsc24PhysicsSmartPreparation/এনালাইসিস .jpg'

import { useNavigate } from 'react-router-dom'

export default function Success({ path }) {
    const navigate = useNavigate()
    return (
        <div className='conatiner 2xl:px-20 px-5 mx-auto sm:pt-14 pt-7'>
            <div className=" flex flex-col items-center justify-center gap-2.5 my-14 text-center">
                <h1 className=" lg:text-5xl text-4xl font-bold text-[#001B36]">মাত্র ৩ ঘণ্টা সময়ে হয়ে যাও পদার্থবিজ্ঞান ১ম পত্রে  এইচ এস সি রেডি</h1>
                <p className=" text-lg font-medium text-[#001B36] px-5">আপনি যা পাবেন তা এখানে</p>
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8'>

                {/* <div className='flex justify-end'> */}
                <div className='bg-white shadow-md rounded-2xl overflow-hidden border '>
                    <img className='w-full max-h-60 object-cover' src={successImg1} alt="successImg1" />
                    <div className='p-5'>
                        <h2 className='text-[26px] font-bold text-[#044884] my-1'>রেকর্ডেড সাজেশন ক্লাস</h2>
                        <p>তিন ঘণ্টার রেকর্ডেড ক্লাসের মাধ্যমে নিজের  রুটিনমত সকল সাজেশন রিভিউ করতে পারবেন</p>
                    </div>
                </div>
                {/* </div> */}
                <div className='bg-white shadow-md rounded-2xl overflow-hidden border '>
                    <img className='w-full max-h-60 object-cover' src={successImg2} alt="successImg1" />
                    <div className='p-5'>
                        <h2 className='text-[26px] font-bold text-[#044884] my-1'>গাইডলাইন</h2>
                        <p>শুধু সাজেশন ক্লাস পাবে তা নয় বরং কিভাবে এই সাজেশনকে সঠিক উপায়ে কাজে লাগাতে পারবে তার একটি আইডিয়া পাবে আমাদের সাজেশন ক্লাসে।</p>
                    </div>
                </div>
                <div className='bg-white shadow-md rounded-2xl overflow-hidden border '>
                    <img className='w-full max-h-60 object-contain' src={successImg3} alt="successImg1" />
                    <div className='p-5'>
                        <h2 className='text-[26px] font-bold text-[#044884] my-1'>SMART পড়াশোনা</h2>
                        <p>অল্প সময়ে গুরুত্বপূর্ণ  টপিক ভালোভাবে আয়ত্ত্বে আনা এবং অধ্যায়ের টাইপ ভিত্তিক পড়াশোনা করা</p>
                    </div>
                </div>
                <div className='bg-white shadow-md rounded-2xl overflow-hidden border '>
                    <img className='w-full max-h-60 object-cover' src={successImg4} alt="successImg1" />
                    <div className='p-5'>
                        <h2 className='text-[26px] font-bold text-[#044884] my-1'>অ্যানালাইসিস </h2>
                        <p>চ্যাপ্টার ভিত্তিক বোর্ড প্রশ্ন অ্যানালাইসিস এবং এক্সামে সবচেয়ে বেশি আসে এমন টপিক গুলো খুজে আপনাদের দেওয়া।</p>
                    </div>
                </div>
            </div>
            <div className='flex justify-center pt-10 w-full'>
                <div className='wiggle'>
                    <button onClick={() => navigate(path)} className='flex items-center gap-2 px-5 py-1.5 rounded-md bg-[#FE8553] border border-[#FE8553] hover:text-[#FE8553] hover:bg-transparent transition-all duration-200 ease-linear font-medium text-white'>Enroll Now <TiArrowRight className='text-xl' /></button>
                </div>
            </div>
        </div>

    )
}

import PurchaseLeft from "./PurchaseLeft";
import PurchaseRight from "./PurchaseRight";


export default function PurchaseBody() {
    return (
        <div className="bg-white py-10">
            <div className="container mx-auto 2xl:px-20 lg:flex gap-10 ">
                <div className="sm:bg-[#F9FAFB] sm:px-5  py-8 lg:w-[60%] rounded-xl">
                    <PurchaseLeft />
                </div>
                <div className="bg-[#F9FAFB] sm:px-5 py-8 lg:w-[40%] rounded-xl h-fit">
                    <PurchaseRight />
                </div>
            </div>
        </div>
    )
}

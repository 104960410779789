var BASE_URL, MAT_URL;

// if (process.env.NODE_ENV === "development") {
//   BASE_URL = "http://localhost:8000/api";
//   MAT_URL = "http://localhost:8000/";
// } else if (process.env.NODE_ENV === "production") {
//   BASE_URL = "https://www.ishqool.com/backend/api";
//   MAT_URL = "https://www.ishqool.com/backend/static/";
// }

BASE_URL = "https://www.ishqool.com/backend/api";
MAT_URL = "https://www.ishqool.com/backend/static/";

export { BASE_URL, MAT_URL };

// AUTH
export const AUTH_URL = BASE_URL + "/auth";
export const LOGIN_URL = AUTH_URL + "/login";
export const SIGNUP_USER_URL = AUTH_URL + "/signup_user";
export const SIGNUP_ORG_URL = AUTH_URL + "/signup_org";
export const SIGNUP_DONOR_URL = AUTH_URL + "/signup_donor";
export const VERIFICATION_URL = AUTH_URL + "/verification";
export const RESEND_OTP_URL = AUTH_URL + "/resend_otp";
export const SOCIAL_SIGNUP_URL = AUTH_URL + "/social_signup";
export const SOCIAL_SIGNIN_URL = AUTH_URL + "/social_signin";
export const GET_RESET_PASSWORD_CODE_URL =
  AUTH_URL + "/get_reset_password_code";
export const VERIFY_RESET_PASSWORD_URL =
  AUTH_URL + "/verify_reset_password_code";
export const RESET_PASSWORD_URL = AUTH_URL + "/reset_password";
export const CHANGE_PASSWORD_URL = AUTH_URL + "/change_password";

// CLASS
export const CLASS_URL = BASE_URL + "/class";
export const GET_ALL_CLASSES = CLASS_URL + "/list";
export const CREATE_CLASS = CLASS_URL + "/create";
export const UPDATE_CLASS = CLASS_URL + "/update";
export const DELETE_CLASS = CLASS_URL + "/delete";
export const ACTIVATE_CLASS = CLASS_URL + "/activate";
export const DEACTIVATE_CLASS = CLASS_URL + "/deactivate";

// SUBJECT
export const SUBJECT_URL = BASE_URL + "/subject";
export const CREATE_SUBJECT = SUBJECT_URL + "/create";
export const GET_UNIQUE_SUBJECTS_URL = SUBJECT_URL + "/list_unique";
export const GET_ALL_SUBJECTS_BY_CLASS_ID = SUBJECT_URL + "/list_by_class_id";
export const UPDATE_SUBJECT = SUBJECT_URL + "/update";
export const DELETE_SUBJECT = SUBJECT_URL + "/delete";
export const ACTIVATE_SUBJECT = SUBJECT_URL + "/activate";
export const DEACTIVATE_SUBJECT = SUBJECT_URL + "/deactivate";

// COURSE
export const COURSE_URL = BASE_URL + "/course";
export const CREATE_COURSE_URL = COURSE_URL + "/create";
export const GET_ALL_COURSES = COURSE_URL + "/list";
export const GET_ALL_COURSES_BY_TEACHER_ID = COURSE_URL + "/list_by_teacher_id";
export const GET_COURSES_BY_SUBJECT_NAME = COURSE_URL + "/list_by_subject_name";
export const GET_COURSES_BY_SUBJECT_ID = COURSE_URL + "/list_by_subject_id";
export const GET_COURSE_INFO_BY_COURSE_CODE = COURSE_URL + "/info_by_code";
export const GET_MODERATOR_COURSE_INFO_BY_SUBJECT_ID =
  COURSE_URL + "/info_by_subject_id";
export const GET_LEADERBOARD_URL = COURSE_URL + "/leaderboard";
export const UPDATE_COURSE = COURSE_URL + "/update";
export const DELETE_COURSE = COURSE_URL + "/delete";
export const ACTIVATE_COURSE = COURSE_URL + "/activate";
export const DEACTIVATE_COURSE = COURSE_URL + "/deactivate";
export const GET_ENROLLED_COURSE_LIST_URL =
  COURSE_URL + "/getEnrolledCourseList";
export const POST_ENROLLMENT_REQUEST_URL =
  COURSE_URL + "/postEnrollmentRequest";
export const GET_ENROLLMENT_REQUEST_DATA_URL =
  COURSE_URL + "/getEnrollmentRequest";
export const GET_ENROLLMENT_DATA_URL =
  COURSE_URL + "/getEnrollmentDataByTeacherId";
export const RESPONSE_TO_ENROLL_REQUEST_URL =
  COURSE_URL + "/responseToEnrollmentRequest";
export const REVIEW_TO_ENROLL_REQUEST_URL =
  COURSE_URL + "/reviewToEnrollmentRequest";
export const REMOVE_STUDENT_FROM_COURSE_URL =
  COURSE_URL + "/removeStudentFromCourse";
export const SEARCH_COURSE_URL = COURSE_URL + "/search";
export const GET_ALL_STUDENTS_BY_COURSE_ID =
  COURSE_URL + "/getAllStudentsByCourseId";

// CHAPTER
export const CHAPTER_URL = BASE_URL + "/chapter";
export const CREATE_CHAPTER = CHAPTER_URL + "/create";
export const UPDATE_CHAPTER_URL = CHAPTER_URL + "/update";
export const DELETE_CHAPTER_URL = CHAPTER_URL + "/delete";
export const GET_CHAPTER_INFO_URL = CHAPTER_URL;
export const ACTIVATE_CHAPTER_URL = CHAPTER_URL + "/activate";
export const DEACTIVATE_CHAPTER_URL = CHAPTER_URL + "/deactivate";

// EXAM
export const EXAM_URL = BASE_URL + "/exam";
export const CREATE_EXAM_WITH_CHAPTER_AND_COURSE_URL =
  EXAM_URL + "/create_with_chapter_and_course";
export const GET_ALL_EXAMS_BY_USER_ID_URL = EXAM_URL + "/list_by_user_id";
export const UPDATE_EXAM_URL = EXAM_URL + "/update";
export const ACTIVATE_EXAM_URL = EXAM_URL + "/activate";
export const DEACTIVATE_EXAM_URL = EXAM_URL + "/deactivate";
export const GET_QUIZ_URL = EXAM_URL + "/get_quiz";
export const GET_QUIZ_PREVIEW_URL = EXAM_URL + "/get_quiz_preview";
export const GET_QUIZ_REPORT_URL = EXAM_URL + "/get_quiz_report";
export const GET_QUIZ_RESPONSE_BY_USER_ID_URL =
  EXAM_URL + "/get_quiz_report_by_user";
export const GET_QUIZ_ANSWERS_URL = EXAM_URL + "/get_quiz_answers";
export const UPDATE_FORM_INFO_URL = EXAM_URL + "/update_form_info";
export const TOGGLE_QUESTION_SHUFFLE = EXAM_URL + "/toggle_question_shuffle";
export const TOGGLE_OPTION_SHUFFLE = EXAM_URL + "/toggle_option_shuffle";
export const QUESTION_URL = EXAM_URL + "/question";
export const CREATE_QUESTION_URL = QUESTION_URL + "/create";
export const UPDATE_QUESTION_URL = QUESTION_URL + "/update";
export const DELETE_QUESTION_URL = QUESTION_URL + "/delete";
export const REVIEW_QUESTION_URL = QUESTION_URL + "/review";
export const OPTION_URL = QUESTION_URL + "/option";
export const ADD_NEW_OPTION_URL = OPTION_URL + "/create";
export const SELECT_AS_ANSWER_URL = OPTION_URL + "/selectAsAnswer";
export const DELETE_OPTION_URL = OPTION_URL + "/delete";
export const LAUNCH_EXAM_URL = EXAM_URL + "/launch";
export const SUBMIT_RESPONSE_URL = EXAM_URL + "/submit_response";

// ROUTINE
export const ROUTINE_URL = BASE_URL + "/routine";
export const GET_CLASS_SCHEDULE = ROUTINE_URL + "/get_class_schedule";
export const GET_FULL_ROUTINE_URL = ROUTINE_URL + "/get_full_routine";
export const CREATE_ROUTINE_ITEM_URL = ROUTINE_URL + "/create_routine_item";
export const UPDATE_ROUTINE_ITEM_URL = ROUTINE_URL + "/update_routine_item";
export const DELETE_ROUTINE_ITEM_URL = ROUTINE_URL + "/delete_routine_item";
export const GET_WORKLOAD_BY_DATE_URL = ROUTINE_URL + "/get_workload_by_date";

// Materials
export const MATERIALS_URL = BASE_URL + "/material";
export const CREATE_MATERIALS_URL = MATERIALS_URL + "/create";
export const CREATE_MATERIALS_BY_TEACHER_URL =
  MATERIALS_URL + "/create_by_teacher";
export const CREATE_LINK_MATERIALS_BY_TEACHER_URL =
  MATERIALS_URL + "/create_link_material_by_teacher";
export const UPDATE_CHAPTER_MATERIAL_URL = MATERIALS_URL + "/update";
export const DELETE_CHAPTER_MATERIALS_URL = MATERIALS_URL + "/delete";
export const RESTORE_MATERIALS_URL = MATERIALS_URL + "/restore";
export const ACTIVATE_MATERIAL_URL = MATERIALS_URL + "/activate";
export const DEACTIVATE_MATERIAL_URL = MATERIALS_URL + "/deactivate";

// BOOKS
export const BOOKS_URL = BASE_URL + "/book";
export const CREATE_BOOKS_URL = BOOKS_URL + "/create";
export const UPDATE_BOOKS_URL = BOOKS_URL + "/update";
export const DELETE_BOOKS_URL = BOOKS_URL + "/delete";
export const RESTORE_BOOKS_URL = BOOKS_URL + "/restore";
export const ACTIVATE_BOOKS_URL = BOOKS_URL + "/activate";
export const DEACTIVATE_BOOKS_URL = BOOKS_URL + "/deactivate";

// NOTICES
export const NOTICE_URL = BASE_URL + "/notice";
export const CREATE_NOTICE_URL = NOTICE_URL + "/create";
export const UPDATE_NOTICE_URL = NOTICE_URL + "/update";
export const DELETE_NOTICE_URL = NOTICE_URL + "/delete";
export const RESTORE_NOTICE_URL = NOTICE_URL + "/restore";
export const ACTIVATE_NOTICE_URL = NOTICE_URL + "/activate";
export const DEACTIVATE_NOTICE_URL = NOTICE_URL + "/deactivate";
export const GET_ALL_NOTICES_BY_TEACHER_ID = NOTICE_URL + "/list_by_teacher_id";
export const GET_ALL_NOTICES_BY_COURSE_ID = NOTICE_URL + "/list_by_course_id";

// User
export const USER_URL = BASE_URL + "/user";
export const GET_ALL_USERS_URL = USER_URL + "/list";
export const GET_USER_DETAILS_BY_USERNAME_URL = USER_URL + "/username";
export const GET_USER_DETAILS_BY_USER_ID_URL = USER_URL + "/userId";
export const UPDATE_USER_URL = USER_URL + "/update_user";
export const UPDATE_USERNAME_URL = USER_URL + "/update_username";
export const UPDATE_PROFILE_PICTURE_URL = USER_URL + "/update_profile_picture";
export const VERIFY_EMAIL_URL = USER_URL + "/verify_email";
export const UPDATE_EMAIL_URL = USER_URL + "/update_email";
export const UPDATE_PASSWORD_URL = USER_URL + "/update_password";

// Donor
export const DONOR_URL = BASE_URL + "/donor";
export const GET_ALL_DONORS_URL = DONOR_URL + "/list";
export const UPDATE_DONOR_USERNAME_URL = DONOR_URL + "/update/username";
export const UPDATE_DONOR_EMAIL_URL = DONOR_URL + "/update/email";
export const UPDATE_DONOR_NAME = DONOR_URL + "/update/name";
export const UPDATE_DONOR_PASSWORD_URL = DONOR_URL + "/change_password";

// DEMO
export const DEMO_URL = BASE_URL + "/demo";
export const GET_DEMO_CLASSES_URL = DEMO_URL + "/get_all_public_classes";
export const GET_DEMO_SUBJECTS_URL = DEMO_URL + "/get_all_public_subjects";
export const GET_DEMO_CHAPTERS_URL = DEMO_URL + "/get_all_public_chapters";
export const GET_DEMO_MATERIALS_URL = DEMO_URL + "/get_all_public_materials";

// 	NOTIFICATION
export const NOTIFICATION_URL = BASE_URL + "/notification";
export const GET_GENERAL_NOTIFICATIONS_BY_USER_ID_URL =
  NOTIFICATION_URL + "/list_gen_by_user_id";
export const GET_EXAM_NOTIFICATIONS_BY_USER_ID_URL =
  NOTIFICATION_URL + "/list_exam_by_user_id";

// 	FEEDBACK
export const FEEDBACK_URL = BASE_URL + "/feedback";
export const CREATE_FEEDBACK_URL = FEEDBACK_URL + "/create";
export const GET_ALL_FEEDBACKS_URL = FEEDBACK_URL + "/list";

//  SCHOLARSHIP
export const SCHOLARSHIP_URL = BASE_URL + "/scholarship";
export const PAYMENT_URL = SCHOLARSHIP_URL + "/payment";
export const CREATE_SCHOLARSHIP_URL = SCHOLARSHIP_URL + "/create";
export const GET_ALL_SCHOLARSHIPS_URL = SCHOLARSHIP_URL + "/list";
export const GET_ALL_OPEN_SCHOLARSHIPS_URL = SCHOLARSHIP_URL + "/list/open";
export const GET_ALL_OPEN_SCHOLARSHIPS_FOR_STUDENTS_URL =
  SCHOLARSHIP_URL + "/list/open_for_student";
export const GET_ALL_SCHOLARSHIPS_BY_USER_ID_URL =
  SCHOLARSHIP_URL + "/list_by_donor_id";
export const GET_SCHOLARSHIP_BY_ID_URL = SCHOLARSHIP_URL + "/get";
export const UPDATE_SCHOLARSHIP_URL = SCHOLARSHIP_URL + "/update";
export const DELETE_SCHOLARSHIP_URL = SCHOLARSHIP_URL + "/delete";
export const DEACTIVATE_SCHOLARSHIP_URL = SCHOLARSHIP_URL + "/deactivate";
export const ACTIVATE_SCHOLARSHIP_URL = SCHOLARSHIP_URL + "/activate";
export const GET_STUDENT_IN_SCHOLARSHIP_URL = SCHOLARSHIP_URL + "/get-student";
export const SEARCH_STUDENT_IN_SCHOLARSHIP_URL =
  SCHOLARSHIP_URL + "/search-student";
export const ADD_STUDENT_IN_SCHOLARSHIP_URL =
  SCHOLARSHIP_URL + "/addStudentToScholarship";
export const GET_ALL_ADDEED_STUDENT_IN_SCHOLARSHIP_URL =
  SCHOLARSHIP_URL + "/getAllAddedStudents";
export const APPLY_TO_SCHOLARSHIP_URL =
  SCHOLARSHIP_URL + "/postApplicationRequest";
export const GET_ALL_APPLICATION_BY_SCHOLARSHIP_URL =
  SCHOLARSHIP_URL + "/getAllRequests";
export const GET_APPLICATION_DATA_BY_STUDENT_ID_URL =
  SCHOLARSHIP_URL + "/getApplicationData";
export const GET_PAGE_ONE_DATA_BY_USER_URL =
  SCHOLARSHIP_URL + "/getPageOneData";
export const GET_PAGE_TWO_DATA_BY_USER_URL =
  SCHOLARSHIP_URL + "/getPageTwoData";
export const GET_PAGE_THREE_DATA_BY_USER_URL =
  SCHOLARSHIP_URL + "/getPageThreeData";
export const GET_PAGE_FOUR_DATA_BY_USER_URL =
  SCHOLARSHIP_URL + "/getPageFourData";
export const SAVE_PAGE_ONE_DATA_BY_USER_URL =
  SCHOLARSHIP_URL + "/savePageOneData";
export const SAVE_PAGE_TWO_DATA_BY_USER_URL =
  SCHOLARSHIP_URL + "/savePageTwoData";
export const SAVE_PAGE_THREE_DATA_BY_USER_URL =
  SCHOLARSHIP_URL + "/savePageThreeData";
export const SAVE_PAGE_FOUR_DATA_BY_USER_URL =
  SCHOLARSHIP_URL + "/savePageFourData";
export const RESPONSE_TO_SCHOLARSHIP_APPLICATION_URL =
  SCHOLARSHIP_URL + "/response";
export const CREATE_SCHOLARSHIP_PAYMENT_URL = PAYMENT_URL + "/create";
export const GET_SCHOLARSHIP_AND_STUDENT_INFO_URL =
  SCHOLARSHIP_URL + "/get/payment/get";
export const RESPONSE_TO_SCHOLARSHIP_PAYMENT_URL = PAYMENT_URL;

//Ielts
export const CREATE_IELTS_COURSE_URL = BASE_URL + "/ielts/create/course";
export const UPDATE_IELTS_COURSE_URL = BASE_URL + "/ielts/update/course";
export const DELETE_IELTS_COURSE_URL = BASE_URL + "/ielts/delete/course";

export const CREATE_IELTS_CLASS_URL = BASE_URL + "/ielts/create/class";
export const UPDATE_IELTS_CLASS_URL = BASE_URL + "/ielts/update/class";
export const DELETE_IELTS_CLASS_URL = BASE_URL + "/ielts/delete/class";

export const CREATE_IELTS_LECTURE_URL = BASE_URL + "/ielts/create/lecture";
export const UPDATE_IELTS_LECTURE_URL = BASE_URL + "/ielts/update/lecture";
export const DELETE_IELTS_LECTURE_URL = BASE_URL + "/ielts/delete/lecture";

export const GET_ALL_IELTS_COURSES_URL = BASE_URL + "/ielts/get/all";



// course id
export const SPEARING_WORKSHOP_COURSE_ID = 476
export const SPEARING_COURSE_ID = 477
export const ADVANCE_COURSE_ID = 478
export const HSC_PHYSICS_1st_SMART_SUGGESTION_2024 = 480

// ielts free course
export const IELTS_FREE_COURSE_ID = 479
export const IELTS_WRITING_INTENSIVE_COURSE_ID = 481
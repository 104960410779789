import React from "react";
import Header from "../../../components/header/Header";
import "./jobdetails.scss";

const JobApplyPage = () => {
  return (
    <div>
      {/* <Header /> */}
      <div className="job_apply_form_wraper">
        <div className="title">
          Software Engineer - Full Stack (Remote)
          <div className="sub_details">
            DUBLIN, LEINSTER, IRELAND /ENGINEERING AND PRODUCT – ENGINEERING
            /FULL TIME/ REMOTE
          </div>
        </div>

        <div className="job_apply_form_chield_container">
          <section className="section_one">
            <div className="form_title">SUBMIT YOUR APPLICATION</div>
            <form action="">
              {/* <div className="form_input" htmlFor="">
								<div className="label"> LinkedIn profile</div>
								<input type="text" />
							</div> */}
              <div className="form_input" htmlFor="">
                <div className="label">Resume/CV</div>
                <input type="file" id="myFile" name="filename" />
              </div>

              <div className="form_input" htmlFor="">
                <div className="label">Full name</div>
                <input type="text" />{" "}
              </div>
              <div className="form_input" htmlFor="">
                <div className="label">Email</div>
                <input type="text" />{" "}
              </div>
              <div className="form_input" htmlFor="">
                <div className="label">Phone</div>
                <input type="text" />{" "}
              </div>
              <div className="form_input" htmlFor="">
                <div className="label">Current company</div>
                <input type="text" />{" "}
              </div>
              <div className="form_tittle_two">LINKS</div>
              <div className="form_input" htmlFor="">
                <div className="label"> LinkedIn URL</div>
                <input type="text" />
              </div>
              <div className="form_input" htmlFor="">
                <div className="label">Twitter URL</div>
                <input type="text" />
              </div>

              <div className="form_input" htmlFor="">
                <div className="label">Github URL</div>
                <input type="text" />{" "}
              </div>
              <div className="form_input" htmlFor="">
                <div className="label">Portfolio URL</div>
                <input type="text" />{" "}
              </div>
              <div className="form_input" htmlFor="">
                <div className="label">Other website</div>
                <input type="text" />{" "}
              </div>
              <div className="form_tittle_two">ADDITIONAL INFORMATION</div>
              <textarea name="" id="" cols="84" rows="8"></textarea>
              <button className="submit_button">Submit Application</button>
            </form>
          </section>
        </div>
      </div>
    </div>
  );
};

export default JobApplyPage;

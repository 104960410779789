import React, { useState } from 'react'
import { loadStorage } from '../../../utils/persistLocalStorage';
import { useNavigate } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import UserSetting from '../../../components/Navbar/UserSetting';
import Dropdown from '../../../components/Navbar/Dropdown';
import Button from '../../OrganizationSection/components/Buttons/Button';
import { FiLogIn } from 'react-icons/fi';
import logo from '../../../media/images/quiz/logo.png'
import shortlogo from '../../../media/images/quiz/shortlogo.png'

export default function AdminTopNav({ setOpenMenu, openMenu }) {
    const [open, setOpen] = useState(false);
    const user = loadStorage("user");
    const navigate = useNavigate();
    return (
        <div className='h-[10vh] fixed top-0 w-screen !z-[100000]'>
            <div className=" flex bg-white justify-between border-b  z-[0] relative items-center sm:pr-10 pr-6 pl-5 sm:pl-0 h-full ">
                <button onClick={() => setOpenMenu(!openMenu)} className=" text-balck sm:hidden"><FaBars /></button>
                <div className='lg:w-80 sm:w-60 '>
                    <img onClick={() => navigate('/')} src={logo} alt="logo" className=" mb-2 mx-auto sm:block hidden w-40 cursor-pointer" />
                    <img onClick={() => navigate('/')} src={shortlogo} alt="logo" className="  mx-auto h-10 sm:hidden" />
                </div>
                <div className='flex items-center sm:justify-between justify-end sm:flex-1 lg:pl-10 '>
                    <input type="text" className=' p-2 rounded-full bg-[#F4F4F4] lg:w-80 hidden sm:block' />
                    <div className=' relative'>
                        {
                            user ?
                                <div onClick={() => setOpen(!open)} className=' cursor-pointer' >
                                    <UserSetting open={open} setOpen={setOpen} user={user} />
                                    <Dropdown user={user} open={open} />
                                </div> : <Button type='button' text='Login' isFill={true} icon={<FiLogIn />} onClick={() => navigate("/auth/login")} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
import "./CashBack.css"
import { TiArrowRight } from 'react-icons/ti'
import cashimg from '../../../../media/images/courseBranding/cashback.png'
import { useNavigate } from "react-router-dom"
import { pushToDataLayer } from "../../../../utils/gtm";

export default function CashBack({ text, path }) {

    const navigete = useNavigate();

    const handleNavigate = (path) => {
        navigete(path)
        pushToDataLayer({
            event: 'Book Your Seat',
            course_name: 'IELTS Intensive Writing',
            course_price: '999 tk',
            course_description: 'Enhance your IELTS writing in a single go. Book today, Dont let it pass you by.',
            action: 'Click',
            time: new Date()
        })
    }

    return (
        <div className=" bg-black py-10 mb-12">
            <div className=" container mx-auto 2xl:px-20 px-5 flex flex-col lg:flex-row items-center lg:justify-between justify-center">
                <img src={cashimg} alt="cashimg" />
                <div className=' text-white lg:w-[50%] text-center lg:text-left  mb-4 lg:mb-0'>
                    <h1 className=' text-white text-4xl font-bold mb-2'>100% Money Back Guarantee!</h1>
                    <p className=' text-gray-200'>{text}</p>
                </div>
                <div className='wiggle'>
                    <button onClick={() => handleNavigate(path)} className='flex items-center gap-2 px-5 py-1.5 rounded-md bg-[#FE8553] border border-[#FE8553] hover:text-[#FE8553] hover:bg-transparent transition-all duration-200 ease-linear font-medium text-white'>Book Now <TiArrowRight className='text-xl' /></button>
                </div>
            </div>
        </div>
    )
}

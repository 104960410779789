import QuizTitle from "./QuizTitle";
import mentorImg from '../../../media/images/quiz/mentor2.png'
import mentorImg2 from '../../../media/images/quiz/kawser.png'
import mentorImg3 from '../../../media/images/quiz/moon.png'
import mentorImg4 from '../../../media/images/quiz/teacher1.png'
import mentorImg5 from '../../../media/images/quiz/teacher2.png'
import { AiOutlineTrophy } from "react-icons/ai";
import { PiSuitcaseSimple } from "react-icons/pi";

export default function Mentors({ isLineNone }) {
    const mentorsData = [
        {
            id: 1,
            imgSrc: mentorImg,
            name: 'শাহতাজ রহমান',
            batch: 'বুয়েট - ২০১৪-১৫',
            expertise: 'বস্তু ও ধাতব কৌশল, বুয়েট',
            experience: 'এক্সপেরিয়েন্স - ৫ বছর',
        },
        {
            id: 2,
            imgSrc: mentorImg2,
            name: 'কাউছার আহম্মেদ',
            batch: 'ঢাবি - ২০১৮-১৯',
            expertise: 'রসায়ন, ঢাকা বিশ্ববিদ্যালয়',
            experience: 'এক্সপেরিয়েন্স - ৪ বছর',
        },
        {
            id: 3,
            imgSrc: mentorImg3,
            name: 'সারোয়ার পাঠান মুন',
            batch: 'নোবিপ্রবি - ২০১৮-১৯',
            expertise: 'এগ্রিকালচার, নোবিপ্রবি',
            experience: 'এক্সপেরিয়েন্স - ৪ বছর',
        },
        // {
        //     id: 1,
        //     imgSrc: mentorImg4,
        //     name: 'Aditya Farhan Hossain',
        //     expertise: 'Instructor at IshQool',
        //     experience: 'IELTS Score- 7.5',
        // },
        // {
        //     id: 2,
        //     imgSrc: mentorImg5,
        //     name: 'Fateema Aziza',
        //     expertise: 'Instructor at IshQool',
        //     experience: 'IELTS Score-  8.0',
        // }
    ];
    return (
        <div className='py-20'>
            <QuizTitle title='আমাদের এক্সপার্ট মেন্টরগণ ' text='সব বিষয়ের সেরা প্রস্তুতি হবে সবচেয়ে সহজে দেশের সেরা ইন্সট্রাক্টরদের সাথে' isLineNone={isLineNone} />
            <div className="container 2xl:px-20 xl:px-10 sm:px-5 px-2 mx-auto py-6 flex flex-wrap justify-center gap-10">
                {
                    mentorsData?.map(mentor => <div key={mentor?.id} className=" bg-[#FAFBFF] rounded-xl py-6 px-4 mb-7 sm:mb-0">
                        <div className=" h-48 w-52 ">
                            <img className="mx-auto" src={mentor?.imgSrc} alt="mentorImg" />
                        </div>
                        <h1 className=" text-2xl font-semibold mt-5 text-center sm:text-left">{mentor?.name}</h1>
                        <p className=" text-lg font-bold text-center sm:text-left">{mentor?.batch}</p>
                        <div className=" my-5">
                            <div className=" flex gap-2 text-[#001B36] font-medium">
                                <PiSuitcaseSimple className=" mt-1" />
                                <p>{mentor?.expertise}</p>
                            </div>
                            <div className=" flex gap-2 text-[#001B36] font-medium">
                                <AiOutlineTrophy className=" mt-1" />
                                <p>{mentor?.experience}</p>
                            </div>
                        </div>
                        <button className=" w-full bg-[#001B36] text-md font-medium text-white py-1.5 rounded-md border border-[#001B36] hover:bg-transparent hover:text-[#001B36]">বিস্তারিত জানুন</button>
                    </div>)
                }
            </div>
        </div>
    )
}

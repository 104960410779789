import { defaultProfile } from "../../utils/images";

export default function UserSetting({ user, setOpen, open }) {
    const name = user ? `${user?.first_name} ${user?.last_name}` : "";
    return (
        <div className="flex items-center gap-2" >
            <img
                src={
                    user?.profile_picture
                        ? user?.profile_picture
                        : defaultProfile
                }
                alt="profile"
                className=' h-10 w-10 rounded-full'
            />
            <div >
                <div className="font-medium">
                    {user
                        ? name.length > 12
                            ? `${name.substring(0, 12)}...`
                            : name
                        : ""}
                </div>
                <div className="text-xs font-medium text-gray-600">
                    {user?.role?.is_admin
                        ? "Admin"
                        : user?.role?.is_teacher
                            ? "Teacher"
                            : user?.role?.is_student
                                ? "Student"
                                : "Unknown"}
                </div>
            </div>

            <div
                className="text-sm ml-2 cursor-pointer"
            >
                <i
                    onClick={() => setOpen(false)}
                    className={`fa-solid fa-chevron-up transition-all ease-linear duration-200 ${open ? "rotate-[0deg] " : "rotate-180"}`}
                ></i>
            </div>
        </div>
    )
}

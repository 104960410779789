import React from 'react'
import PurchaseLeft from '../CommonSection/PurchasePage/components/PurchaseLeft'
import { HSC_PHYSICS_1st_SMART_SUGGESTION_2024 } from '../../utils/urls'
import PurchaseProductDetails from '../../components/PurchaseProductDetails/PurchaseProductDetails'
import couseImg2 from '../../media/images/courseBranding/funnel image 1.jpg'
import HSC24PhysicsPurchaseRight from './HSC24PhysicsPurchaseRight'

export default function HSC24PhysicsPurchaseBody() {
  return (
    <div className="bg-white py-10">
      <div className="container mx-auto 2xl:px-20 flex flex-col-reverse lg:flex-row gap-10 ">
        <div className="sm:bg-[#F9FAFB] sm:px-5  py-8 lg:w-[60%] rounded-xl">
          <PurchaseLeft courseId={HSC_PHYSICS_1st_SMART_SUGGESTION_2024} courseImage={couseImg2} />
        </div>
        <div className="bg-[#F9FAFB] sm:px-5 py-8 lg:w-[40%] rounded-xl h-fit">
          <HSC24PhysicsPurchaseRight />
        </div>
        <div className='lg:hidden'>
          <PurchaseProductDetails courseId={HSC_PHYSICS_1st_SMART_SUGGESTION_2024} courseImage={couseImg2} />
        </div>
      </div>
    </div>
  )
}

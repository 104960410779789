
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import ReactPaginate from 'react-paginate'


const NewPagination = ({ setPage, totalCount, limit }) => {

    const handlePageChange = (e) => {
        setPage(e.selected + 1)
    }

    return (
        <div className="">
            <ReactPaginate
                breakLabel="..."
                previousLabel={<div className='flex items-center gap-2 p-2'><BsArrowLeft className='text-sm' /></div>}
                previousClassName="border-gray-300 border-2 rounded-lg text-center mr-auto hover:bg-blue-50 hover:text-blue-500 hover:border-blue-300 transition duration-200 select-none"
                nextClassName="border-gray-300 border-2 rounded-lg text-center ml-auto hover:bg-blue-50 hover:text-blue-500 hover:border-blue-300 transition duration-200 select-none"
                activeLinkClassName="h-7 w-7 grid place-items-center"
                nextLabel={<div className='flex items-center gap-2 p-2'><BsArrowRight className='text-sm' /></div>}
                onPageChange={(e) => handlePageChange(e)}
                pageRangeDisplayed={9}
                marginPagesDisplayed={1}
                pageCount={Math.ceil(totalCount / limit)}
                renderOnZeroPageCount={null}
                pageLinkClassName='h-8 w-8 grid place-items-center text-base '
                className="!flex items-center justify-center gap-3 select-none "
                pageClassName="rounded-lg"
                activeClassName="bg-[#E5F0FF] box-content text-[#006AFF] border border-[#006AFF] font-bold h-8 w-8"
            />
        </div>
    )
}

export default NewPagination;

import Navbar from "../../../../components/Navbar/Navbar";
import PurchaseHeader from "../components/PurchaseHeader";
import TitleBar from "../components/TitleBar";
import thankyou from '../../../../media/images/purchase/thankyou.png'
import workshop1 from '../../../../media/images/purchase/workshop1.png'
import workshop2 from '../../../../media/images/purchase/workshop2.png'
import { BsExclamationTriangle } from "react-icons/bs";
import { IoCallSharp } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../../utils/urls";
import { pushToDataLayer } from "../../../../utils/gtm";

export default function ThankYouPage() {

	const { transactionId } = useParams();

	useEffect(() => {
		const paymentData = async () => {
			try {
				const res = await axios.get(`${BASE_URL}/payment/payment-details/${transactionId}`);
				const data = res?.data?.data;

				pushToDataLayer({
					event: 'Payment Complete',
					username: data?.order?.user?.username,
					email: data?.order?.user?.email,
					course_name: data?.order?.course?.course_title,
					course_price: data?.order?.course?.course_price,
					order_id: data?.order?.id,
					transaction_id: data?.transaction_id,
					payment_method: data?.payment_method,
					payment_time: data?.payment_date,
					time: new Date()
				})

			} catch (error) {
				return;
			}
		};
		paymentData();
	}, [transactionId]);

	return (
		<div className=" bg-white">
			<Navbar />
			<div className=" pt-14 text-[#001B36]">
				<PurchaseHeader />
				<TitleBar title='অর্ডার স্ট্যাটাস' />
				https://chat.whatsapp.com/FVPOzbRdCo72fxs6no9AVd
				<div className=" flex flex-col items-center  py-14 ">
					<img src={thankyou} alt="thankyou" className=" lg:h-96 px-5" />
					<p className=" text-lg font-medium mt-5 text-[#001B36]">Thanks for Workshop Registration!</p>
					<div className=" bg-[#F9FAFB] py-3 px-5 rounded flex gap-3 2xl:w-[27%] xl:w-[37%] sm:w-[50%] w-[95%] my-8">
						<BsExclamationTriangle className=" text-[#FF0000] w-10 mt-1" />
						<p className="texxt-[#001B36]">Ensure that you have joindeur our <span className=" text-[#087BEA]">Whatsapp Channel </span>
							to participate in the workshop and stay tuned. Otherwise You may miss Out.</p>
					</div>
					<a href="https://www.facebook.com/ishqool.org" target="_blank" className="bg-[#0BB78C] border border-[#0BB78C] hover:text-[#0BB78C] hover:bg-transparent text-white font-medium py-2 px-4 rounded-md" rel="noreferrer">Join WhatsApp</a>
				</div>
				<div className=" bg-[#F9FAFB] py-7 mb-10">
					<h1 className=" text-2xl font-semibold text-center ">Workshop Information</h1>
					<div className=" container 2xl:px-20 px-5 mx-auto mt-5">
						<div className=" bg-white rounded-xl shadow flex sm:flex-row flex-col items-center justify-center gap-10 p-7">
							<div className="bg-[#EFFFFB] flex flex-col items-center justify-center py-5 lg:w-1/3 sm:w-1/2 w-full rounded-lg">
								<div className="flex items-center gap-2 mb-3">
									<img src={workshop1} alt="workshop1" />
									<h2 className="text-xl font-medium">Workshop Starts</h2>
								</div>
								<p className=" mb-1"><span className=" font-medium">Date</span>  - 25 Feb,2024- Sunday</p>
								<p><span className=" font-medium">Time</span>  - (9:00 PM-10:30 PM)</p>
							</div>
							<div className="bg-[#FFF2F9] flex flex-col items-center justify-center py-5 lg:w-1/3 sm:w-1/2 w-full rounded-lg">
								<div className="flex items-center gap-2 mb-3">
									<img src={workshop2} alt="workshop1" />
									<h2 className="text-xl font-medium">Platform </h2>
								</div>
								<p>Join With <span className=" font-medium">Google Meet</span> <br />
									From Anywhere Online</p>
							</div>
						</div>
					</div>
				</div>
				<div className=' flex md:flex-row flex-col items-center justify-center px-4 pb-6 gap-5 md:gap-0'>
					<div className=' flex items-center gap-3'>
						<div className=' sm:w-12 w-10 sm:h-12 h-10 rounded-full flex items-center justify-center bg-[#FFDBEE] text-[#F082BC] text-2xl'>
							<IoCallSharp />
						</div>
						<h3 className=' sm:text-2xl text-xl font-medium text-[#001B36]'>যেকোনো প্রয়োজনে কল করুন: </h3>
					</div>

					<div className='sm:flex items-center gap-4'>
						<h1 className=' text-4xl font-bold text-[#FE8553]'>01313615686</h1>
						<p className=" font-medium"> (সকাল ৯ টা - রাত ১০ টা)</p>
					</div>
				</div>
			</div>
		</div>
	)
}

import "./UpcommingCourse.css"
import branding from '../../../../media/images/courseBranding/funnel image 1.jpg';
import { MdLibraryAddCheck } from "react-icons/md";
import { TiArrowRight } from 'react-icons/ti';
import { useNavigate } from "react-router-dom";

export default function UpcommingCourse() {

	const navigate = useNavigate()

	return (
		<div className=' container 2xl:px-20 px-5 mx-auto pt-2 sm:pt-10 pb-10 sm:pb-20'>
			<div className=' grid lg:grid-cols-2 lg:gap-10'>
				<div className='bg-[#BECCF1] lg:bg-transparent rounded-t-2xl'>
					<img src={branding} alt="branding" className='max-w-full h-auto' />
				</div>
				<div className='border h-full rounded-2xl lg:rounded-t-2xl rounded-t-none mb-5 sm:mb-0'>
					<div className=' sm:p-8 p-4'>
						<h2 className=' sm:text-2xl text-lg font-medium'>শুধুমাত্র  HSC 2024 এর জন্য রেকর্ডেড স্পেশাল সাজেশন ক্লাস</h2>
						<h1 className=' sm:text-4xl text-2xl font-bold text-[#5169A7] my-2' >পদার্থবিজ্ঞান ১ম পত্র SMART Suggestions Class for Hsc'24</h1>
					</div>

					<div className=' border-t flex items-center justify-between sm:p-8 p-4'>
						<div>
							<h3 className='sm:text-xl text-md font-semibold text-[#001B36] mb-3'>আপনি এক্সট্রা যা যা পাচ্ছেন</h3>

							<div >
								<div className=' flex items-start gap-2.5'>
									<MdLibraryAddCheck className=' text-lg text-amber-500 mt-0.5' />
									<p>টাইপভিত্তিক সাজেশন</p>
								</div>
								<div className=' flex items-start gap-2.5 my-1.5'>
									<MdLibraryAddCheck className=' text-lg text-amber-500 mt-0.5' />
									<p>এক্সাম গাইডলাইন</p>
								</div>
								<div className=' flex items-start gap-2.5'>
									<MdLibraryAddCheck className=' text-lg text-amber-500 mt-0.5' />
									<p className=' text-[#001B36]'>পরীক্ষার আগে ফাইনাল আপডেটেড সাজেশন ক্লাস অ্যাকসেস</p>
								</div>
							</div>
						</div>
						<div>
							<div className=' flex flex-col-reverse sm:flex-row items-center sm:gap-3 mb-4'>
								<h1 className=' text-5xl font-bold text-[#001B36]'>৳199</h1>
								{/* <h2 className=' text-5xl font-bold text-amber-800 opacity-10 line-through'>49</h2> */}
							</div>

							<div className="wiggle">
								<button onClick={() => navigate('/hsc24-physics-suggestion-p1/hsc24Physics1stPurchase')} className='sm:flex items-center gap-2 px-5 py-1.5 rounded-md bg-[#FE8553] border border-[#FE8553] hover:text-[#FE8553] hover:bg-transparent transition-all duration-200 ease-linear font-medium text-white hidden'>Enroll Now <TiArrowRight className='text-xl' /></button>
							</div>
						</div>

					</div>
					<div className="wiggle !flex !justify-center">
						<button onClick={() => navigate('/courseBranding/workshopPurchase')} className='flex items-center gap-2 px-5 py-1.5 rounded-md bg-[#FE8553] border border-[#FE8553] hover:text-[#FE8553] hover:bg-transparent transition-all duration-200 ease-linear font-medium text-white sm:hidden '>Enroll Now <TiArrowRight className='text-xl' /></button>
					</div>
				</div>
			</div>
		</div>
	)
}

import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import { delStorage } from "../../utils/persistLocalStorage";
import { MdManageHistory } from "react-icons/md";
export default function Dropdown({ user, open }) {
    const navigate = useNavigate();
    const logoutUser = () => {
        delStorage("user");
        navigate("/");
    };
    return (
        <div className={`absolute  right-0 bg-white border border-gray-100 shadow-lg w-52  rounded-md z-[10] transition-all ease-linear duration-200 ${open ? "visible top-12 opacity-100" : " invisible top-32 opacity-0"}`}>
            <div className="dropdown-content">
                <Link className="flex items-center gap-3 py-2 px-3 border-b hover:bg-gray-100 " to={"/" + user.username}>
                    <i className="fa-solid fa-user"></i>
                    <div>Profile</div>
                </Link>

                <Link className="flex items-center gap-3 py-2 px-3 border-b  hover:bg-gray-100" to="/settings">
                    <i className="fa-solid fa-gear"></i>
                    <div>Settings</div>
                </Link>

                <Link className="flex items-center gap-3 py-2 px-3 border-b  hover:bg-gray-100" to="/orderHistory">
                    <MdManageHistory />
                    <div>Order History</div>
                </Link>

                <div className="flex items-center gap-3 py-2 px-3  hover:bg-gray-100" onClick={logoutUser}>
                    <i className="fa-solid fa-arrow-right-from-bracket"></i>
                    <div>Logout</div>
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import { IoMdTimer } from 'react-icons/io'
import { WiTime2 } from "react-icons/wi";

export default function ServiceHolder() {
  const valueData = [
    {
      id: 1,
      name: 'Morning Batch',
      dec: '9 AM to 10 AM',
      icon: <IoMdTimer />
    },
    {
      id: 2,
      name: 'Night Batch',
      dec: '9 PM to 10 PM',
      icon: <WiTime2 />
    },
  ]

  return (
    <div className='py-10 container 2xl:px-20 px-5 mx-auto xl:mt-20 lg:mt-24 sm:mt-14 mt-10'>
      <h1 className=' sm:text-5xl text-4xl font-bold text-center mb-14'>Separate Batch for Job Holders</h1>

      <div className='sm:flex-row flex-col flex justify-center gap-10'>
        {
          valueData?.map(data => <div className='2xl:w-[28%] xl:w-[34%] lg:w-[40%] sm:w-[45%] w-full border rounded-2xl p-6 flex flex-col items-center py-8'>
            <div className='text-7xl mb-2'>
              {data?.icon}
            </div>
            <h1 className='sm:text-2xl text-xl font-semibold mb-3 text-center'>{data?.name}</h1>
            <h2 className='sm:text-base text-sm font-medium text-center'>Fridays and Saturdays</h2>
            <p className='sm:text-base text-sm font-medium text-center'>{data?.dec}</p>
          </div>)
        }
      </div>
    </div>
  )
}

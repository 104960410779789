import React from 'react'
import Navbar from '../../components/Navbar/Navbar'
import QuizHero from './Components/QuizHero'
import QuizInfo from './Components/QuizInfo'
import Reson from './Components/Reson'
import QuizRoutine from './Components/QuizRoutine'
import Cariculam from './Components/Cariculam'
import Mentors from './Components/Mentors'
import QuizFaq from './Components/QuizFaq'
import Support from './Components/Support'
import Footer from './Components/Footer'

export default function QuizLandingPage() {
    return (
        <div className=' bg-white'>
            <Navbar />
            <QuizHero />
            <QuizInfo />
            <Reson />
            <QuizRoutine />
            {/* <Cariculam /> */}
            <Mentors />
            <QuizFaq />
            <Support />
            <Footer />
        </div>
    )
}

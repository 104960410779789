import logo from '../../../media/images/quiz/HSCLogo.png'
import Logo from '../../../components/Navbar/Logo'
import { useLocation } from 'react-router-dom';

export default function Footer() {

  const location = useLocation();
  const pathname = location.pathname;

  return (
    <div className=' bg-[#FFF5EC] pt-20 pb-24'>
      <div className="container mx-auto">
        <div className='flex flex-col items-center justify-center'>
          <div>
            {pathname === '/hscmodeltest' ? <img src={logo} alt="logo" className='lg:w-[70%] w-[90%]' /> : <Logo />}
          </div>
          <p className='text-lg font-semibold mt-4 text-[001B36]'>Your IELTS Success Partner</p>
        </div>
      </div>
    </div>
  )
}

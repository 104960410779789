import axios from "axios";
import {
  ACTIVATE_COURSE,
  BASE_URL,
  CREATE_COURSE_URL,
  DEACTIVATE_COURSE,
  EXAM_URL,
  GET_ALL_COURSES,
  GET_ALL_COURSES_BY_TEACHER_ID,
  GET_ALL_STUDENTS_BY_COURSE_ID,
  GET_COURSES_BY_SUBJECT_ID,
  GET_COURSES_BY_SUBJECT_NAME,
  GET_COURSE_INFO_BY_COURSE_CODE,
  GET_ENROLLED_COURSE_LIST_URL,
  GET_ENROLLMENT_DATA_URL,
  GET_ENROLLMENT_REQUEST_DATA_URL,
  GET_LEADERBOARD_URL,
  GET_MODERATOR_COURSE_INFO_BY_SUBJECT_ID,
  POST_ENROLLMENT_REQUEST_URL,
  REMOVE_STUDENT_FROM_COURSE_URL,
  RESPONSE_TO_ENROLL_REQUEST_URL,
  REVIEW_TO_ENROLL_REQUEST_URL,
  SEARCH_COURSE_URL,
  UPDATE_COURSE,
} from "../utils/urls";
import { HSC_Course_Id } from "../utils/CourseCodeAndId";

export const createCourse = (data) => {
  return axios({
    method: "post",
    url: CREATE_COURSE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
    data: {
      subject_id: data.subject_id,
      course_price: data.course_price,
      section: data.section,
      courseTitle: data.courseTitle,
      useStandardMaterial: data.useStandardMaterial,
      whats_app_link: data.whats_app_link
    },
  });
};

export const getAllCourses = (data) => {
  return axios({
    method: "get",
    url: GET_ALL_COURSES,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
  });
};
export const getAllCoursesByTeacherId = (data) => {
  return axios({
    method: "get",
    url: GET_ALL_COURSES_BY_TEACHER_ID + "/" + data.teacherId,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
  });
};

export const getAllCoursesBySubjectName = (data) => {
  return axios({
    method: "get",
    url: GET_COURSES_BY_SUBJECT_NAME + "/" + data.subjectName,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getAllCoursesBySubjectId = (data) => {
  return axios({
    method: "get",
    url: GET_COURSES_BY_SUBJECT_ID + "/" + data.subjectId,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getCourseInfoByCourseCode = (data) => {
  return axios({
    method: "post",
    url: GET_COURSE_INFO_BY_COURSE_CODE,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      courseCode: data.courseCode,
      userId: data.userId,
    },
  });
};
export const getLiveCourse = ({ courseId, data }) => {
  return axios({
    method: "get",
    url: `${EXAM_URL}/live_exam/${courseId}`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data,
    }
  });
};

export const getModeratorCourseInfo = (data) => {
  return axios({
    method: "get",
    url: `${GET_MODERATOR_COURSE_INFO_BY_SUBJECT_ID}/${data.subjectId}`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
  });
};

export const getLeaderboard = (data) => {
  return axios({
    method: "get",
    url: `${GET_LEADERBOARD_URL}/${data.courseId}`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
  });
};

export const updateCourse = (data) => {
  return axios({
    method: "patch",
    url: UPDATE_COURSE,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
    data: {
      courseId: data.courseId,
      courseTitle: data.courseTitle,
      section: data.courseSection,
      subject_id: data.subject_id,
      course_price: data.course_price,
      whats_app_link: data.whats_app_link
    },
  });
};

export const activateCourse = (data) => {
  return axios({
    method: "patch",
    url: ACTIVATE_COURSE + "/" + data.courseId,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
  });
};

export const deactivateCourse = (data) => {
  return axios({
    method: "patch",
    url: DEACTIVATE_COURSE + "/" + data.courseId,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
  });
};

//token
export const getEnrolledCourseList = (data) => {
  return axios({
    method: "get",
    url: GET_ENROLLED_COURSE_LIST_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
  });
};

export const postEnrollmentRequest = (data) => {
  return axios({
    method: "post",
    url: POST_ENROLLMENT_REQUEST_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
    data: data,
  });
};

export const getEnrollmentRequestData = (data) => {
  return axios({
    method: "get",
    url: GET_ENROLLMENT_REQUEST_DATA_URL + "/" + data.courseId,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
  });
};

export const getEnrollmentData = (data) => {
  return axios({
    method: "get",
    url: GET_ENROLLMENT_DATA_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
  });
};

export const responseToEnrollmentRequest = (data) => {
  return axios({
    method: "post",
    url: RESPONSE_TO_ENROLL_REQUEST_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
    data: {
      enrollmentRequestId: data.enrollmentRequestId,
      status: data.status,
    },
  });
};

export const reviewToEnrollmentRequest = (data) => {
  return axios({
    method: "patch",
    url: REVIEW_TO_ENROLL_REQUEST_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
    data: {
      enrollmentRequestId: data.enrollmentRequestId,
      paymentAckMsg: data.paymentAckMsg,
    },
  });
};

export const removeStudentFromCourse = (data) => {
  return axios({
    method: "post",
    url: REMOVE_STUDENT_FROM_COURSE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
    data: {
      courseId: data.courseId,
      studentId: data.studentId,
      studentRemovalReason: data.studentRemovalReason,
    },
  });
};

export const searchCourse = (data) => {
  return axios({
    method: "post",
    url: SEARCH_COURSE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
    data: data,
  });
};

export const getAllStudentsByCourseId = (data) => {
  return axios({
    method: "get",
    url: GET_ALL_STUDENTS_BY_COURSE_ID + "/" + data.courseId,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": data.token,
    },
  });
};

export const hscCourseEnrollmentStatus = ({ token }) => {
  return axios({
    method: "post",
    url: `${BASE_URL}/course/hscCourseEnrollStatus/${HSC_Course_Id}`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    }
  });
};

export const getCourseContentByCourseId = ({ token, courseId }) => {
  return axios({
    method: "get",
    url: `${BASE_URL}/course/course-content/${courseId}`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    }
  });
};

export const getLastWatchVideo = ({ token, courseId }) => {
  return axios({
    method: "get",
    url: `${BASE_URL}/course/last-watched/${courseId}`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    }
  });
};

export const addWatchVideoSerial = ({ token, chapter_x_material_id }) => {
  return axios({
    method: "post",
    url: `${BASE_URL}/course/add-watch-video/${chapter_x_material_id}`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    }
  });
};

export const courseUsersProgress = ({ token, courseId }) => {
  return axios({
    method: "get",
    url: `${BASE_URL}/course/course-progress/${courseId}`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    }
  });
};

export const courseDetailsById = ({ courseId }) => {
  return axios({
    method: "get",
    url: `${BASE_URL}/course/course-details/${courseId}`,
    headers: {
      "Content-Type": "application/json",
    }
  });
};
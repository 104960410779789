import React from 'react';
import { useNavigate } from 'react-router-dom';

const ModeratorCareerPage = () => {
    const navigate = useNavigate();
    return (
        <div>
            <button onClick={() => navigate('/moderator/career/all')}>All Career</button>
            <button onClick={() => navigate('/moderator/career/create')}>Create Career</button>
            <button onClick={() => navigate('/moderator/career/update/1')}>Update Career</button>
        </div>
    );
};

export default ModeratorCareerPage;
import Menu from './Menu'
import Logo from './Logo'
import { useState } from 'react'
import Dropdown from './Dropdown'
import UserSetting from './UserSetting'
import { FiLogIn } from 'react-icons/fi'
import Button from '../dev/button/Button'
import { CgMenuLeft } from "react-icons/cg"
import { useNavigate } from 'react-router-dom'
import { loadStorage } from '../../utils/persistLocalStorage'

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const user = loadStorage("user");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  return (
    <div className='fixed top-0 left-0 right-0  z-[100000] '>
      <div className='border-b py-2 bg-white z-[100000] relative'>
        <div className='container mx-auto flex items-center justify-between 2xl:px-20 xl:px-10  px-2'>
          <CgMenuLeft onClick={() => setMenuOpen(!menuOpen)} className=' text-xl hover:text-orange-500 cursor-pointer lg:hidden' />
          <Logo pad={true} />
          <Menu setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
          <div className=' relative'>
            {
              user ?
                <div onClick={() => setOpen(!open)} className=' cursor-pointer' >
                  <UserSetting open={open} setOpen={setOpen} user={user} />
                  <Dropdown user={user} open={open} />
                </div> : <Button type='button' text='Login' isFill={true} icon={<FiLogIn />} onClick={() => navigate("/auth/login")} />
            }
          </div>
        </div>
      </div>
      {
        open && <div className=" fixed h-screen w-screen  z-[1000]" onClick={() => setOpen(false)} ></div>
      }
    </div>
  )
}

export default Navbar

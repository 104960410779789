import React from 'react'
import Navbar from '../../components/Navbar/Navbar'
import newlogo from '../../media/images/purchase/newlogo.png'
import PurchaseHeader from '../CommonSection/PurchasePage/components/PurchaseHeader'
import TitleBar from '../CommonSection/PurchasePage/components/TitleBar'
import HSC24PhysicsPurchaseBody from './HSC24PhysicsPurchaseBody'

export default function HSC24PhysicsPurchasePage() {
  return (
    <div className="bg-white">
      <div className="sm:block hidden">
        <Navbar />
      </div>
      <div className="border-b py-10 sm:hidden">
        <img src={newlogo} alt="logo" className="w-1/3 mx-auto" />
      </div>
      <div className="sm:pt-14 pt-3 text-[#001B36]">
        <PurchaseHeader />
        <div className="sm:block hidden">
          <TitleBar title='Complete Payment' />
        </div>
        <HSC24PhysicsPurchaseBody />
      </div>
    </div>
  )
}

import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { loadStorage } from "../../../../utils/persistLocalStorage";
import { BASE_URL } from "../../../../utils/urls";

const RequireAdmin = ({ children }) => {

  const user = loadStorage('user');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAdmin = async () => {

      if (loading) {
        try {
          setLoading(true)
          const result = await axios.get(`${BASE_URL}/user/username/${user?.username}`, {
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": user?.token,
            }
          });

          const isAdmin = result?.data?.data?.user?.role?.is_admin

          if (!isAdmin) {
            return navigate("/");
          }

        } catch (error) {
          return navigate("/");
        } finally {
          setLoading(false)
        }
      }

    };

    checkAdmin();
  }, [user, loading, navigate]);

  return loading ? <div>Loading...</div> : children;
};

export default RequireAdmin;



export default function QuizTitle({ title, text, isLineNone }) {
    return (
        <div className=" text-center mt-10">
            <h1 className="text-[#001B36] md:text-5xl text-4xl font-bold px-4">{title}</h1>
            <p className="text-lg font-medium text-[#001B3] my-3 px-4">{text}</p>
            {
                !isLineNone && <div className=" h-[3px] xl:w-1/3 lg:w-1/2 w-2/3 mx-auto  bg-[#044884]  mb-16"></div>
            }
        </div>
    )
}

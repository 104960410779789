import React from 'react'
import QuizTitle from '../../QuizPages/Components/QuizTitle';

import mentorImg from '../../../media/images/quiz/mentor2.png'
import { AiOutlineTrophy } from "react-icons/ai";
import { PiSuitcaseSimple } from "react-icons/pi";
import { MdLibraryAddCheck } from 'react-icons/md';
export default function BrandMentors() {
    const mentorsData = [
        {
            id: 1,
            imgSrc: mentorImg,
            name: 'শাহতাজ রহমান',
            batch: 'বুয়েট - ২০১৪-১৫',
            expertise: 'Instructor at IshQool',
            experience: '৫ বছর',
        }

    ];
    return (
        <div className=' py-5 bg-[#FFFBF8] '>
            <QuizTitle title='আমাদের অভিজ্ঞ ইন্সট্রাক্টর' text='আমাদের মেধাবী ও অভিজ্ঞ ইন্সট্রাক্টর শাহতাজ রহমান (বুয়েট - ২০১৪-১৫), দীর্ঘ সময় ধরে ইন্সট্রাক্টর হিসেবে কাজ করে আসছেন এইচ এস সি স্টুডেন্টদের জন্য।' isLineNone={true} />
            <div className=' xl:w-[45%] lg:w-[60%] sm:w-[90%] mx-auto my-5 cotainer px-5'>
                <div className=' flex items-start gap-2 text-[#001B36]'>
                    <MdLibraryAddCheck className=' text-[#FE8553] w-6 mt-0.5' />
                    <p> ইন্সট্রাক্টরের প্রায় ৪ বছরের অভিজ্ঞতা যা শিক্ষার্থীদের এইচ এস সি এক্সামে ভাল মার্ক পেতে সাহায্য করবে।</p>
                </div>
                <div className=' flex items-start gap-2 mt-1 text-[#001B36]'  >
                    <MdLibraryAddCheck className=' text-[#FE8553] w-6 mt-0.5' />
                    <p>সে শিক্ষার্থীদের চাহিদার উপর ভিত্তি করে সহায়তা এবং নির্দেশনা প্রদান করে।</p>
                </div>
                <div className=' flex items-start gap-2 mt-1 text-[#001B36]'  >
                    <MdLibraryAddCheck className=' text-[#FE8553] w-6 mt-0.5' />
                    <p>তার এইচএসসি পরীক্ষা এবং পরীক্ষার প্রশ্নের ধরণ সম্পর্কে বিস্তর ধারণা রয়েছে।</p>
                </div>
                <div className=' flex items-start gap-2 mt-1 text-[#001B36]'  >
                    <MdLibraryAddCheck className=' text-[#FE8553] w-6 mt-0.5' />
                    <p>তার এইচএসসি পরীক্ষায় শিক্ষার্থীদের A+ অর্জনে সাহায্য করার উচ্চ সাফল্যের হার রয়েছে।</p>
                </div>
            </div>
            <div className="container 2xl:px-20 xl:px-10 sm:px-5 px-2 mx-auto py-6 flex flex-wrap justify-center gap-10">
                {
                    mentorsData?.map(mentor => <div key={mentor?.id} className="bg-[#f2f4fb] rounded-xl p-8 mb-7 sm:mb-0">
                        <div className="h-48 w-52 ">
                            <img className="mx-auto" src={mentor?.imgSrc} alt="mentorImg" />
                        </div>
                        <h1 className=" text-2xl font-semibold mt-5 text-center sm:text-left">{mentor?.name}</h1>
                        <p className=" text-lg font-bold text-center sm:text-left">{mentor?.batch}</p>
                        <div className="my-5">
                            <div className=" flex gap-2 text-[#001B36] font-medium">
                                <PiSuitcaseSimple className=" mt-1" />
                                <p>{mentor?.expertise}</p>
                            </div>
                            <div className=" flex gap-2 text-[#001B36] font-medium">
                                <AiOutlineTrophy className=" mt-1" />
                                <p>এক্সপেরিয়েন্স - {mentor?.experience}</p>
                            </div>
                        </div>
                        <button className=" w-full bg-[#001B36] text-md font-medium text-white py-1.5 rounded-md border border-[#001B36] hover:bg-transparent hover:text-[#001B36]">বিস্তারিত জানুন</button>
                    </div>)
                }
            </div>
        </div>
    )
}

import React from 'react'
import Navbar from '../../components/Navbar/Navbar'
import newlogo from '../../media/images/purchase/newlogo.png'
import TitleBar from '../CommonSection/PurchasePage/components/TitleBar'
import PurchaseHeader from '../CommonSection/PurchasePage/components/PurchaseHeader'
import Footer from '../QuizPages/Components/Footer'
import FreeIeltsPurchaseBody from './FreeIeltsPurchaseBody'

export default function FreeIeltsPurchase() {
  return (
    <div className=" bg-white">
        <div className="sm:block hidden">
            <Navbar />
        </div>
        <div className="border-b py-10 sm:hidden">
            <img src={newlogo} alt="logo" className="w-1/3 mx-auto" />
        </div>
        <div className=" sm:pt-14 pt-3 text-[#001B36]">
            <PurchaseHeader />
            <div className="sm:block hidden">
                <TitleBar title='Complete Payment' />
            </div>
            <FreeIeltsPurchaseBody />
        </div>
        <Footer />
    </div>
)
}
